import React, { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
// import api from '../../services/api';
import "chartjs-plugin-datalabels";
import Title from "../../components/titleNew";
import "react-data-table-component-extensions/dist/index.css";
import {
  arrayBases,
  converterData,
  converterDataFormatoPadrao,
  converterLoja,
  converterMes,
  converterNomeLoja,
  fAnosDisponiveis,
  fAnosPorLoja,
} from "../../util/loja";

import {
  colunasInputMesAnos2,
  colunasTabelasGlobaisNext,
  colunasTabelasGlobaisV2,
} from "../../util/tables";
import TabelaNext from "../../components/componentteste/TabelaNext";
import { LegendaTabela } from "../../components/LegendaTabela/LegendaTabela";
import { TituloTabela } from "../../components/TituloTabelaNew/TituloTabela";
import { SubTituloTabela } from "../../components/SubTituloTabela/TituloTabela";
import ButtonVoltar from "../../components/ButtonVoltarNew";

import toast from "react-hot-toast";

import { WrapperTable } from "../../styles/global";

import { PermissionsPage } from "../../util/permissionsPage";
import { vendasService } from "../../services/vendas";
import { useUserData } from "../../hooks/useUserData";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import { MultiSelectFilterBases } from "../../components/FilterLojaMetas/MultiSelectFilterBases";
import Select from "react-select";
import { FiltroPeriodo } from "../../components/FiltroPeriodo/FiltroPeriodo";
import {
  ButtonFilter,
  ButtonToday,
} from "../../components/DatePikerFilter/styles";

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};

function VendasGlobal() {
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;
  const [isLoading, setIsLoading] = useState(true);
  const [periodoInputInicio, setPeriodoInputInicio] = useState("");
  const [periodoInputFim, setPeriodoInputFim] = useState("");
  const [periodoInputInicioAux, setPeriodoInputInicioAux] = useState("");
  const [periodoInputFimAux, setPeriodoInputFimAux] = useState("");
  const [periodoAno, setPeriodoAno] = useState(false);
  const [mesesDisponiveis, setMesesDisponiveis] = useState(false);
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [dadosGraficoAgrupado, setDadosGraficoAgrupado] = useState(false);
  const [dadosGraficoDesempenho, setDadosGraficoDesempenho] = useState({
    labels: [],
    datasets: [],
  });
  const [dadosGerais, setDadosGerais] = useState(0);
  const [dadosTabelasGlobais, setDadosTabelaGlobal] = useState([]);
  const [dadosGlobaisDesempenho, setDadosGlobaisDesempenho] = useState(0);
  const [filtrosAtivosPeriodoTexto, setFiltrosAtivosPeriodoTexto] =
    useState("");
  const [filtrosMesesSelecionados, setFiltrosMesesSelecionados] = useState([]);
  const [filtrosAnosSelecionados, setFiltrosAnosSelecionados] = useState([]);
  const [filtrosLojasSelecionadas, setFiltrosLojasSelecionadas] = useState([]);
  const [codLojaAtiva, setCodLojaAtiva] = useState(false);
  const [dadosParaTabela, setDadosParaTabela] = useState([]);
  const [dadosParaTabelaGlobalAnoAtual, setDadosParaTabelaGlobalAnoAtual] =
    useState([]);
  const [
    dadosParaTabelaGlobalAnoAnterior,
    setDadosParaTabelaGlobalAnoAnterior,
  ] = useState([]);
  const [dadosParaTabelaGlobalDesempenho, setDadosParaTabelaGlobalDesempenho] =
    useState([]);
  const [dadosUltimoPeriodo, setDadosUltimoPeriodo] = useState();
  const [exibeTabela, setExibeTabela] = useState(false);
  const [exibeTabelaGlobal, setExibeTabelaGlobal] = useState(false);
  const [tipoPeriodo, setTipoPeriodo] = useState("");
  const [filtradoPorLoja, setFiltradoPorLoja] = useState(false);
  const [nivelAtivoDoGraficoTotalVendas, setNivelAtivoDoGraficoTotalVendas] =
    useState(0);
  const [nivelAtivoDoGraficoDesempenho, setNivelAtivoDoGraficoDesempenho] =
    useState(0);
  const [auxAnoAtual, setAuxAnoAtual] = useState(0);
  const [auxAnoAnterior, setAuxAnoAnterior] = useState(0);
  const [dadosDisponiveis, setDadosDisponiveis] = useState(true);
  const [carregando, setCarregando] = useState(false);
  const [anosDisponiveisAuxiliar, setAnosDisponiveisAuxiliar] = useState([]);
  const [anosDisponiveis, setAnosDisponiveis] = useState(false);
  const [nivelAcesso, setNivelAcesso] = useState([]);
  const [base, setBase] = useState();
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);
  const [resetGrafico, setResetGrafico] = useState(true);
  useEffect(() => {
    if (nivelAcesso.length === 0) {
      buscarNivelAcesso();
      setIsLoading(true);
      setCarregando(true);
      return;
    }
  }, [userData]);

  useMemo(() => {
    if (periodoAno) {
      preencherDados("ano");
    } else if (
      periodoInputInicio &&
      periodoInputInicio !== "" &&
      periodoInputFim &&
      periodoInputFim !== ""
    ) {
      preencherDados("input");
    }
  }, [periodoInputInicio, periodoInputFim, periodoAno, userData, base]);
  useEffect(() => {
    preencherInputPeriodo();
  }, []);
  useEffect(() => {
    if (codLojaAtiva) {
      preencherDadosVendasPorMes(codLojaAtiva, dadosUltimoPeriodo);
    }
  }, [filtrosMesesSelecionados, codLojaAtiva]);

  useEffect(() => {
    if (filtrosAnosSelecionados.length > 0) {
      iniciaFiltroPorAno();
    }
  }, [filtrosAnosSelecionados]);

  useEffect(() => {
    if (filtradoPorLoja) {
      iniciaFiltroPorLoja();
    }
  }, [filtrosLojasSelecionadas]);
  const buscarNivelAcesso = () => {
    if (accessLevel) {
      setNivelAcesso(accessLevel);

      const bases = arrayBases(acessBase);
      setBasesDisponiveis(bases);
      setBase(bases?.map((item) => item.codBase));
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  };

  function preencherInputPeriodo() {
    var dataHoje = new Date();
    var [inicioAno, hoje] = converterDataFormatoPadrao(dataHoje);
    var { inicioAno: inicioAnoTexto, hoje: hojeTexto } =
      converterData(dataHoje);
    setPeriodoInputInicioAux(inicioAno);
    setPeriodoInputFimAux(hoje);
    setPeriodoInputInicio(inicioAno);
    setPeriodoInputFim(hoje);
    setFiltrosAtivosPeriodoTexto("-" + inicioAnoTexto + " à " + hojeTexto);
  }

  async function preencherDados(tipo) {
    setTipoPeriodo(tipo);
    setFiltradoPorLoja(false);
    var lojasDisponiveisAux = [];
    let auxLojasSelecionadas = [];
    lojasDisponiveis.map((item) => {
      lojasDisponiveisAux.push(item);
    });
    setLojasDisponiveis(lojasDisponiveisAux);
    if (tipo === "input") {
      let inicio = periodoInputInicio.split("-");
      inicio = inicio[2] + "/" + inicio[1] + "/" + inicio[0];
      let fim = periodoInputFim.split("-");
      fim = fim[2] + "/" + fim[1] + "/" + fim[0];
      setFiltrosAtivosPeriodoTexto("- " + inicio + " à " + fim);
      setExibeTabela(true);
      // setExibeTabelaGlobal(true);
      var auxAnoAtual = periodoInputInicio.split("-")[0];
      var auxAnoAnterior = parseInt(auxAnoAtual) - 1;
      setAuxAnoAnterior(auxAnoAnterior);
      setAuxAnoAtual(auxAnoAtual);
    } else if (tipo === "ano") {
      var auxAnoAtual = periodoAno.split(",");
      auxAnoAtual = auxAnoAtual.sort((a, b) => a - b);
      var auxAnoAnterior;
      if (auxAnoAtual.length > 1) {
        auxAnoAnterior = auxAnoAtual[auxAnoAtual.length - 2];
      } else {
        auxAnoAnterior = 0;
      }
      auxAnoAtual = auxAnoAtual[auxAnoAtual.length - 1];
      setAuxAnoAnterior(auxAnoAnterior);
      setAuxAnoAtual(auxAnoAtual);
    }
    setExibeTabela(true);
    setCarregando(true);
    setIsLoading(true);
    window.scrollTo(0, 0);
    if (!base) {
      setCarregando(true);
      setLojasDisponiveis([]);
      return;
    }
    const toastId = toast.loading("Buscando dados...");

    try {
      const response = await vendasService.listaVendaLojas(
        JSON.stringify({
          inicio: periodoInputInicio,
          fim: periodoInputFim,
          ano: periodoAno,
          tipo,
          nivelAcesso,
          base,
        })
      );

      if (!response?.data) {
        toast.error("Não há conexão com o RAD", {
          duration: 7000,
          style: {
            borderRadius: "0.25rem",
            background: "#333",
            color: "#fff",
          },
        });
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
        return;
      }

      if (response?.data?.success) {
        toast.success("Dados recebidos", {
          id: toastId,
        });
        try {
          setAnosDisponiveis(fAnosDisponiveis(response.data.anos));
          setAnosDisponiveisAuxiliar(fAnosDisponiveis(response.data.anos));
        } catch (error) {
          setDadosDisponiveis(false);
          setCarregando(false);
        }
        let dataVendasPeriodoAnterior =
          response.data.data.vendasPeriodoAnterior;
        var dataVendas = response.data.data.vendas;
        setDadosGerais(dataVendas);
        let grupoDeAnos = agruparPor(dataVendas, "ano");
        let grupoDeAnosArray = Object.entries(grupoDeAnos);
        let grupoDeAnoLoja = [];
        grupoDeAnosArray.forEach((item) => {
          let grupoDeLoja = agruparPor(item[1], "CodLoja");
          grupoDeAnoLoja.push(grupoDeLoja);
        });

        let resumoGrupoDeAnoLoja = [];
        grupoDeAnoLoja.forEach((item) => {
          let item2 = Object.entries(item);
          item2.forEach((item3) => {
            let total = 0;
            let totalPedidos = 0;
            let ano;
            let codLoja;
            let totalCusto = 0;
            let totalLucro = 0;
            let porcentagemLucro = 0;

            item3[1].forEach((item4, index) => {
              if (index === 0) {
                ano = item4.ano;
                codLoja = item4.CodLoja;
                if (
                  auxLojasSelecionadas.indexOf(converterLoja(codLoja)) === -1
                ) {
                  auxLojasSelecionadas.push(converterLoja(codLoja));
                }
              }
              totalPedidos += parseInt(item4.totalPedidos);
              total += parseFloat(item4.VlrTotal);

              totalCusto += parseFloat(item4.VlrCusto);
              totalLucro += parseFloat(item4.VlrLucroBruto);
              porcentagemLucro += parseFloat(item4.VlrLucroBrutoPorcentagem);
            });
            let resumoLojaAno = {
              ano: ano,
              codLoja: codLoja,
              total: total,
              totalPedidos: totalPedidos,
              totalCusto,
              totalLucro,
              porcentagemLucro,
            };
            resumoGrupoDeAnoLoja.push(resumoLojaAno);
          });
        });
        var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");
        grupoDeAnosArray = Object.entries(agrupado);
        preencherDadosGraficoAgrupado(
          grupoDeAnosArray,
          auxAnoAnterior,
          auxAnoAtual,
          tipo,
          dataVendasPeriodoAnterior
        );
        setDadosTabelaGlobal(grupoDeAnos);
        setDadosUltimoPeriodo(dataVendasPeriodoAnterior);
        preencherDadosTabelasGlobais(grupoDeAnos);
        setLojasDisponiveis(auxLojasSelecionadas);

        setDadosDisponiveis(true);
        setCarregando(false);
        setCarregando(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const filtrarBases = (value) => {
    let basesSelecionadasTemp = value;

    setBasesSelecionadas(basesSelecionadasTemp);
    setBase(basesSelecionadasTemp);
    setDadosDisponiveis(false);
    setLojasDisponiveis([]);
  };

  function agruparPor(objetoArray, propriedade) {
    return objetoArray.reduce(function (acc, obj) {
      let key = obj[propriedade];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  function iniciaFiltroPorAno() {
    var anosSelecionados = filtrosAnosSelecionados.join(",");
    setPeriodoAno(anosSelecionados);
  }

  function iniciaFiltroPorLoja() {
    preencherDadosVendasPorLoja();
  }

  async function preencherDadosGraficoAgrupado(
    dados,
    anoAnterior,
    anoAtual,
    tipo,
    dadosPeriodoAnterior = false
  ) {
    dados = dados.reverse();
    var label = [];
    var labelCompleta = [];
    var dataSets = [];
    var dadosTabelaAux = [];
    const promise = await dados.map((item, i) => {
      var dadosGrupo = [];

      item[1].forEach((itemGrupo) => {
        var nomeLoja = converterLoja(itemGrupo.codLoja);
        if (filtrosLojasSelecionadas.length > 0) {
          if (filtrosLojasSelecionadas.indexOf(nomeLoja) >= 0) {
            let jaExiste = label.indexOf(nomeLoja);
            if (jaExiste < 0) {
              label.push(nomeLoja);
              labelCompleta.push(nomeLoja);
            }
            dadosGrupo.push(parseFloat(itemGrupo.total));
            dadosTabelaAux.push(itemGrupo);
          } else {
            let jaExiste = labelCompleta.indexOf(nomeLoja);
            if (jaExiste < 0) {
              labelCompleta.push(nomeLoja);
            }
          }
        } else {
          let jaExiste = label.indexOf(nomeLoja);
          if (jaExiste < 0) {
            label.push(nomeLoja);
            labelCompleta.push(nomeLoja);
          }
          dadosGrupo.push(parseFloat(itemGrupo.total));
          dadosTabelaAux.push(itemGrupo);
        }
      });
      var cor =
        item[0] === anoAnterior
          ? "rgba(255, 194, 218, 1)"
          : item[0] === anoAtual
          ? "rgba(91, 139, 252, 1)"
          : i === dados.length - 3
          ? "#5B8BFC"
          : i === dados.length - 4
          ? "#FFC2DA"
          : i === dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (item[0] === anoAnterior) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else if (item[0] === anoAtual) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else {
        datalabels = {
          formatter: function (value, context) {
            return "";
          },
        };
      }
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });
    if (dadosPeriodoAnterior && dadosPeriodoAnterior?.length > 0) {
      let periodoAnteriorAux = [];
      if (filtrosLojasSelecionadas.length > 0) {
        await dadosPeriodoAnterior.forEach((item) => {
          var nomeLoja = converterLoja(item.CodLoja);
          if (filtrosLojasSelecionadas.indexOf(nomeLoja) >= 0) {
            periodoAnteriorAux.push(parseFloat(item.VlrTotal));
          }
        });
        dataSets.push({
          label: "Periodo anterior",
          data: periodoAnteriorAux,
          backgroundColor: "#FFDB8A",
          datalabels: {
            anchor: "start",
            display: true,
            align: "start",
            formatter: function (value, context) {
              return parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            },
          },
        });
      } else {
        const dadosPeriodoAnteriorTemp = dadosPeriodoAnterior.map((item) => {
          return parseFloat(item.VlrTotal);
        });
        periodoAnteriorAux = dadosPeriodoAnteriorTemp;
        dataSets.push({
          label: "Periodo anterior",
          data: periodoAnteriorAux,
          backgroundColor: "#FFDB8A",
          datalabels: {
            formatter: function (value, context) {
              return parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            },
          },
        });
      }
    }

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "codLoja");
    dadosTabelaAux = Object.entries(dadosTabelaAux);

    preencherDadosTabela(
      dadosTabelaAux,
      anoAnterior,
      anoAtual,
      dadosPeriodoAnterior
    );
    setDadosGraficoAgrupado(data);
    setNivelAtivoDoGraficoTotalVendas(0);
    setIsLoading(false);
  }

  function geraCorAleatoria() {
    var hexadecimais = "0123456789ABCDEF";
    var cor = "#";
    for (var i = 0; i < 6; i++) {
      cor += hexadecimais[Math.floor(Math.random() * 16)];
    }
    return cor;
  }

  function preencherDadosTabela(
    dados,
    anoAnterior,
    anoAtual,
    dadosPeriodoAnterior = false
  ) {
    var dadosTabela = [];
    var dadosGraficoDesempenho = [];
    var quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      vlrMedioAnoAtualResumo = 0,
      desempenhoPAResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      vlrMedioAnoAnteriorResumo = 0,
      desempenhoResumo = 0,
      rprAnoAtual = 0,
      rprAnoAnterior = 0,
      qtdPeriodoAnteriorResumo = 0,
      rprPeriodoAnterior = 0,
      vlrTotalPeriodoAnteriorResumo = 0,
      vlrMedioPeriodoAnteriorResumo = 0,
      lucroBrutoTotalAnoAtualResumo = 0,
      lucroBrutoTotalAnoAnteriorResumo = 0,
      lucroPorcentagemAnoAtualResumo = 0,
      lucroPorcentagemAnoAnteriorResumo = 0,
      custoTotalAnoAtualResumo = 0,
      custoTotalAnoAnteriorResumo = 0,
      custoPeriodoAnteriorResumo = 0;

    var totalPedidosAnoAtual = 0;
    var totalPedidosAnoAnterior = 0;
    var totalPedidosPeriodoAnterior = 0;

    if (dados.length > 0) {
      dados.forEach((item) => {
        item[1].forEach((item2) => {
          if (+item2.ano === +anoAtual) {
            totalPedidosAnoAtual += parseInt(item2.total);
          } else if (+item2.ano === +anoAnterior) {
            totalPedidosAnoAnterior += parseInt(item2.total);
          }
        });
      });

      if (dadosPeriodoAnterior?.length) {
        dadosPeriodoAnterior?.forEach((item) => {
          totalPedidosPeriodoAnterior += Number(item.totalPedidos);
        });
      }
      dados.forEach((item) => {
        let loja = parseInt(item[0]);
        let qtdAnoAtual = 0,
          vlrAnoAtual = 0,
          vlrMedioAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrTotalAnoAnterior = 0,
          vlrMedioAnoAnterior = 0,
          desempenho = 0,
          desempPeriodoAnterior = 0,
          representatividadeAtual = 0,
          representatividadeAnterior = 0,
          custoTotalAnoAtual = 0,
          custoTotalAnoAnterior = 0,
          lucroBrutoTotalAnoAtual = 0,
          lucroBrutoTotalAnoAnterior = 0,
          lucroPorcentagemAnoAtual = 0,
          lucroPorcentagemAnoAnterior = 0;

        const periodoAnteriorItem = dadosPeriodoAnterior?.find(
          (item) => Number(item?.CodLoja) === loja
        );
        let vlrPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.VlrTotal)
          : 0;
        let qtdPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.totalPedidos)
          : 0;
        let vlrMedioPeriodoAnterior =
          periodoAnteriorItem && qtdPeriodoAnterior
            ? vlrPeriodoAnterior / qtdPeriodoAnterior
            : 0;

        let representatividadePeriodoAnterior =
          periodoAnteriorItem && totalPedidosPeriodoAnterior
            ? Number(
                (
                  (qtdPeriodoAnterior / totalPedidosPeriodoAnterior) *
                  100
                ).toFixed(2)
              )
            : 0;
        let custoPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem.VlrCusto)
          : 0;
        let lucroPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem.VlrTotal) -
            Number(periodoAnteriorItem.VlrCusto)
          : 0;

        let porcentagemLucroPeriodoAnterior = parseFloat(
          parseFloat(
            Number(
              (vlrPeriodoAnterior - custoPeriodoAnterior) / vlrPeriodoAnterior
            ) * 100
          ).toFixed(2)
        );
        qtdPeriodoAnteriorResumo += qtdPeriodoAnterior;
        vlrTotalPeriodoAnteriorResumo += vlrPeriodoAnterior;
        custoPeriodoAnteriorResumo += custoPeriodoAnterior;

        rprPeriodoAnterior += Number(
          representatividadePeriodoAnterior.toFixed(2)
        );

        let auxValorAnoAnterior = 0,
          auxValorAnoAtual = 0;
        var separaDoisUltimosAnos = [];
        if (item[1][item[1].length - 1]) {
          separaDoisUltimosAnos.push(item[1][item[1].length - 1]);
        }
        if (item[1][item[1].length - 2]) {
          separaDoisUltimosAnos.push(item[1][item[1].length - 2]);
        } else {
          let ano = (parseInt(item[1][item[1].length - 1].ano) - 1).toString();
          let codLoja = item[1][item[1].length - 1].codLoja;
          let total = 0;
          let totalPedidos = 0;
          separaDoisUltimosAnos.push({ ano, codLoja, total, totalPedidos });
        }

        separaDoisUltimosAnos.forEach((item2, index2) => {
          if (+item2.ano === +anoAtual) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = parseFloat(parseFloat(item2.total).toFixed(2));

            custoTotalAnoAtual = parseFloat(
              parseFloat(item2.totalCusto).toFixed(2)
            );
            lucroBrutoTotalAnoAtual = parseFloat(
              parseFloat(item2.total - item2.totalCusto).toFixed(2)
            );
            lucroPorcentagemAnoAtual = parseFloat(
              parseFloat(
                Number((item2.total - item2.totalCusto) / item2.total) * 100
              ).toFixed(2)
            );
            auxValorAnoAtual += parseFloat(item2.total);
            vlrMedioAnoAtual = parseFloat(
              (vlrAnoAtual / qtdAnoAtual).toFixed(2)
            );
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            vlrMedioAnoAtualResumo += vlrMedioAnoAtual;

            custoTotalAnoAtualResumo += custoTotalAnoAtual;
            lucroBrutoTotalAnoAtualResumo += lucroBrutoTotalAnoAtual;
            lucroPorcentagemAnoAtualResumo += lucroPorcentagemAnoAtual;

            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else if (+item2.ano === +anoAnterior) {
            qtdAnoAnterior = parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = parseFloat(
              parseFloat(item2.total).toFixed(2)
            );

            custoTotalAnoAnterior = isNaN(item2.totalCusto)
              ? 0
              : parseFloat(parseFloat(item2.totalCusto).toFixed(2));
            lucroBrutoTotalAnoAnterior = isNaN(item2.totalLucro)
              ? 0
              : parseFloat(
                  parseFloat(item2.total - item2.totalCusto).toFixed(2)
                );
            lucroPorcentagemAnoAnterior = isNaN(item2.porcentagemLucro)
              ? 0
              : parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                );

            auxValorAnoAnterior += parseFloat(item2.total);
            vlrMedioAnoAnterior =
              qtdAnoAnterior === 0
                ? 0
                : parseFloat((vlrTotalAnoAnterior / qtdAnoAnterior).toFixed(2));
            qtdAnoAnteriorResumo += qtdAnoAnterior;

            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            vlrMedioAnoAnteriorResumo += vlrMedioAnoAnterior;
            custoTotalAnoAnteriorResumo += custoTotalAnoAnterior;
            lucroBrutoTotalAnoAnteriorResumo += lucroBrutoTotalAnoAnterior;
            lucroPorcentagemAnoAnteriorResumo += lucroPorcentagemAnoAnterior;

            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (qtdAnoAnterior > 0) {
          desempenho = parseFloat(
            ((auxValorAnoAtual / auxValorAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempenho = 0;
        }

        if (qtdAnoAnterior > 0) {
          desempPeriodoAnterior = parseFloat(
            ((auxValorAnoAtual / vlrPeriodoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempPeriodoAnterior = 0;
        }
        dadosGraficoDesempenho.push({ loja, desempenho });
        desempenhoResumo += desempenho;
        representatividadeAtual = parseFloat(
          ((vlrAnoAtual / totalPedidosAnoAtual) * 100).toFixed(2)
        );
        representatividadeAnterior =
          totalPedidosAnoAnterior && totalPedidosAnoAnterior > 0
            ? parseFloat(
                ((vlrTotalAnoAnterior / totalPedidosAnoAnterior) * 100).toFixed(
                  2
                )
              )
            : 0;
        rprAnoAtual += representatividadeAtual;
        rprAnoAnterior += representatividadeAnterior;

        dadosTabela.push({
          loja: loja,
          qtdAnoAtual: qtdAnoAtual,
          vlrAnoAtual: vlrAnoAtual,
          vlrMedioAnoAtual: vlrMedioAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          custoTotalAtual: custoTotalAnoAtual,
          custoTotalAnterior: custoTotalAnoAnterior,
          lucroBrutoAtual: lucroBrutoTotalAnoAtual,
          lucroBrutoAnterior: lucroBrutoTotalAnoAnterior,
          lucro: lucroPorcentagemAnoAtual,
          lucroAnterior: lucroPorcentagemAnoAnterior,
          vlrMedioAnoAnterior: vlrMedioAnoAnterior,
          desempPeriodoAnterior: desempPeriodoAnterior,
          desempenho: desempenho,
          representatividadeAtual: representatividadeAtual,
          representatividadeAnterior: representatividadeAnterior,
          vlrPeriodoAnterior: vlrPeriodoAnterior,
          qtdPeriodoAnterior: qtdPeriodoAnterior,
          vlrMedioPeriodoAnterior: vlrMedioPeriodoAnterior,
          representatividadePeriodoAnterior: representatividadePeriodoAnterior,
          lucroPeriodoAnterior: lucroPeriodoAnterior,
          custoPeriodoAnterior: custoPeriodoAnterior,
          porcentagemLucroPeriodoAnterior: porcentagemLucroPeriodoAnterior,
        });
      });
    }
    vlrMedioAnoAtualResumo = parseFloat(
      (vlrMedioAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    vlrMedioAnoAnteriorResumo =
      quantidadeLojasAnoAnterior && quantidadeLojasAnoAnterior > 0
        ? parseFloat(
            (vlrMedioAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(2)
          )
        : 0;
    vlrMedioPeriodoAnteriorResumo = parseFloat(
      (vlrTotalPeriodoAnteriorResumo / qtdPeriodoAnteriorResumo).toFixed(2)
    );

    desempenhoResumo =
      vlrTotalAnoAnteriorResumo && vlrTotalAnoAnteriorResumo > 0
        ? parseFloat(
            ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(
              2
            )
          )
        : 0;

    desempenhoPAResumo =
      vlrTotalPeriodoAnteriorResumo && vlrTotalPeriodoAnteriorResumo > 0
        ? parseFloat(
            (
              (vlrAnoAtualResumo / vlrTotalPeriodoAnteriorResumo - 1) *
              100
            ).toFixed(2)
          )
        : 0;

    lucroPorcentagemAnoAtualResumo = parseFloat(
      (lucroPorcentagemAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    lucroPorcentagemAnoAnteriorResumo = parseFloat(
      (lucroPorcentagemAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(
        2
      )
    );

    if (desempenhoResumo === 0) {
      setResetGrafico(false);
    }

    dadosTabela.push({
      loja: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,

      custoTotalAtual: custoTotalAnoAtualResumo,
      custoTotalAnterior: custoTotalAnoAnteriorResumo,
      lucroBrutoAtual: lucroBrutoTotalAnoAtualResumo,
      lucroBrutoAnterior: lucroBrutoTotalAnoAnteriorResumo,
      lucro: lucroPorcentagemAnoAtualResumo,
      lucroAnterior: lucroPorcentagemAnoAnteriorResumo,

      desempenho: desempenhoResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
      desempPeriodoAnterior: desempenhoPAResumo,
      vlrPeriodoAnterior: vlrTotalPeriodoAnteriorResumo,
      qtdPeriodoAnterior: qtdPeriodoAnteriorResumo,
      vlrMedioPeriodoAnterior: vlrMedioPeriodoAnteriorResumo,
      representatividadePeriodoAnterior: rprPeriodoAnterior,

      lucroPeriodoAnterior:
        vlrTotalPeriodoAnteriorResumo - custoPeriodoAnteriorResumo,
      custoPeriodoAnterior: custoPeriodoAnteriorResumo,
      porcentagemLucroPeriodoAnterior: vlrTotalPeriodoAnteriorResumo
        ? (1 - custoPeriodoAnteriorResumo / vlrTotalPeriodoAnteriorResumo) * 100
        : 0,
    });

    setDadosParaTabela(dadosTabela);
    preencherDadosGraficoDesempenho(dadosGraficoDesempenho);
  }

  function preencherDadosTabelasGlobais(dados) {
    let grupoDeAnosArray = Object.entries(dados);
    if (grupoDeAnosArray.length > 1) {
      var separaDoisUltimosAnos = [
        grupoDeAnosArray[grupoDeAnosArray.length - 1],
        grupoDeAnosArray[grupoDeAnosArray.length - 2],
      ];
      var dadosTabelaGlobalAnoAtual = [];
      var dadosTabelaGlobalAnoAnterior = [];
      var dadosTabelaGlobalDesempenho = [];

      let janTotalAnoAtual = 0,
        fevTotalAnoAtual = 0,
        marTotalAnoAtual = 0,
        abrTotalAnoAtual = 0,
        maiTotalAnoAtual = 0,
        junTotalAnoAtual = 0,
        julTotalAnoAtual = 0,
        agoTotalAnoAtual = 0,
        setTotalAnoAtual = 0,
        outTotalAnoAtual = 0,
        novTotalAnoAtual = 0,
        dezTotalAnoAtual = 0,
        totalAnoAtual = 0;

      let janQtdAnoAtual = 0,
        fevQtdAnoAtual = 0,
        marQtdAnoAtual = 0,
        abrQtdAnoAtual = 0,
        maiQtdAnoAtual = 0,
        junQtdAnoAtual = 0,
        julQtdAnoAtual = 0,
        agoQtdAnoAtual = 0,
        setQtdAnoAtual = 0,
        outQtdAnoAtual = 0,
        novQtdAnoAtual = 0,
        dezQtdAnoAtual = 0,
        qtdAnoAtual = 0;

      let janCustoAnoAtual = 0,
        fevCustoAnoAtual = 0,
        marCustoAnoAtual = 0,
        abrCustoAnoAtual = 0,
        maiCustoAnoAtual = 0,
        junCustoAnoAtual = 0,
        julCustoAnoAtual = 0,
        agoCustoAnoAtual = 0,
        setCustoAnoAtual = 0,
        outCustoAnoAtual = 0,
        novCustoAnoAtual = 0,
        dezCustoAnoAtual = 0,
        custoAnoAtual = 0;

      let janTotalAnoAnterior = 0,
        fevTotalAnoAnterior = 0,
        marTotalAnoAnterior = 0,
        abrTotalAnoAnterior = 0,
        maiTotalAnoAnterior = 0,
        junTotalAnoAnterior = 0,
        julTotalAnoAnterior = 0,
        agoTotalAnoAnterior = 0,
        setTotalAnoAnterior = 0,
        outTotalAnoAnterior = 0,
        novTotalAnoAnterior = 0,
        dezTotalAnoAnterior = 0,
        totalAnoAnterior = 0;

      let janQtdAnoAnterior = 0,
        fevQtdAnoAnterior = 0,
        marQtdAnoAnterior = 0,
        abrQtdAnoAnterior = 0,
        maiQtdAnoAnterior = 0,
        junQtdAnoAnterior = 0,
        julQtdAnoAnterior = 0,
        agoQtdAnoAnterior = 0,
        setQtdAnoAnterior = 0,
        outQtdAnoAnterior = 0,
        novQtdAnoAnterior = 0,
        dezQtdAnoAnterior = 0,
        qtdAnoAnterior = 0;

      let janCustoAnoAnterior = 0,
        fevCustoAnoAnterior = 0,
        marCustoAnoAnterior = 0,
        abrCustoAnoAnterior = 0,
        maiCustoAnoAnterior = 0,
        junCustoAnoAnterior = 0,
        julCustoAnoAnterior = 0,
        agoCustoAnoAnterior = 0,
        setCustoAnoAnterior = 0,
        outCustoAnoAnterior = 0,
        novCustoAnoAnterior = 0,
        dezCustoAnoAnterior = 0,
        custoAnoAnterior = 0;

      let janDesempenho = 0,
        fevDesempenho = 0,
        marDesempenho = 0,
        abrDesempenho = 0,
        maiDesempenho = 0,
        junDesempenho = 0,
        julDesempenho = 0,
        agoDesempenho = 0,
        setDesempenho = 0,
        outDesempenho = 0,
        novDesempenho = 0,
        dezDesempenho = 0;
      if (separaDoisUltimosAnos && separaDoisUltimosAnos.length > 0) {
        separaDoisUltimosAnos.map((item, index) => {
          if (item[1].length > 0) {
            let linha = [];
            if (filtrosLojasSelecionadas.length > 0) {
              var lojasAux = item[1].filter((a) =>
                filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) > -1
                  ? true
                  : false
              );
            } else {
              var lojasAux = item[1];
            }
            let grupoDeLojaPorAno = agruparPor(lojasAux, "CodLoja");
            let grupoDeLojaPorAnoArray = Object.entries(grupoDeLojaPorAno);
            var dadosDesempenho = [];
            grupoDeLojaPorAnoArray.map((item2) => {
              if (item2.length === 2 && item2[1].length > 0) {
                var dadosLojaPorMes = item2[1].sort((a, b) => {
                  return parseInt(a.mes) - parseInt(b.mes);
                });
                let jan = 0,
                  fev = 0,
                  mar = 0,
                  abr = 0,
                  mai = 0,
                  jun = 0,
                  jul = 0,
                  ago = 0,
                  set = 0,
                  out = 0,
                  nov = 0,
                  dez = 0,
                  total = 0;

                let janTicket = 0,
                  fevTicket = 0,
                  marTicket = 0,
                  abrTicket = 0,
                  maiTicket = 0,
                  junTicket = 0,
                  julTicket = 0,
                  agoTicket = 0,
                  setTicket = 0,
                  outTicket = 0,
                  novTicket = 0,
                  dezTicket = 0,
                  totalTicket = 0;

                let janQtd = 0,
                  fevQtd = 0,
                  marQtd = 0,
                  abrQtd = 0,
                  maiQtd = 0,
                  junQtd = 0,
                  julQtd = 0,
                  agoQtd = 0,
                  setQtd = 0,
                  outQtd = 0,
                  novQtd = 0,
                  dezQtd = 0,
                  totalQtd = 0;

                let janLucro = 0,
                  fevLucro = 0,
                  marLucro = 0,
                  abrLucro = 0,
                  maiLucro = 0,
                  junLucro = 0,
                  julLucro = 0,
                  agoLucro = 0,
                  setLucro = 0,
                  outLucro = 0,
                  novLucro = 0,
                  dezLucro = 0,
                  totalLucro = 0;

                let janCusto = 0,
                  fevCusto = 0,
                  marCusto = 0,
                  abrCusto = 0,
                  maiCusto = 0,
                  junCusto = 0,
                  julCusto = 0,
                  agoCusto = 0,
                  setCusto = 0,
                  outCusto = 0,
                  novCusto = 0,
                  dezCusto = 0,
                  totalCusto = 0;

                let codLoja;
                dadosLojaPorMes.forEach((item3) => {
                  totalCusto += parseFloat(item3.VlrCusto);
                  switch (item3.mes) {
                    case "1":
                      jan = parseFloat(item3.VlrTotal);
                      janQtd = parseFloat(item3.totalPedidos);
                      janCusto = parseFloat(item3.VlrCusto);
                      janLucro = jan ? (1 - janCusto / jan) * 100 : 0;
                      janTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "2":
                      fev = parseFloat(item3.VlrTotal);
                      fevQtd = parseFloat(item3.totalPedidos);
                      fevCusto = parseFloat(item3.VlrCusto);
                      fevLucro = fev ? (1 - fevQtd / fev) * 100 : 0;

                      fevTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "3":
                      mar = parseFloat(item3.VlrTotal);
                      marQtd = parseFloat(item3.totalPedidos);
                      marCusto = parseFloat(item3.VlrCusto);
                      marLucro = mar ? (1 - marCusto / mar) * 100 : 0;
                      marTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "4":
                      abr = parseFloat(item3.VlrTotal);
                      abrQtd = parseFloat(item3.totalPedidos);
                      abrCusto = parseFloat(item3.VlrCusto);
                      abrLucro = abr ? (1 - abrCusto / abr) * 100 : 0;
                      abrTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "5":
                      mai = parseFloat(item3.VlrTotal);
                      maiQtd = parseFloat(item3.totalPedidos);
                      maiCusto = parseFloat(item3.VlrCusto);
                      maiLucro = mai ? (1 - maiCusto / mai) * 100 : 0;
                      maiTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "6":
                      jun = parseFloat(item3.VlrTotal);
                      junQtd = parseFloat(item3.totalPedidos);
                      junCusto = parseFloat(item3.VlrCusto);
                      junLucro = jun ? (1 - junCusto / jun) * 100 : 0;
                      junTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "7":
                      jul = parseFloat(item3.VlrTotal);
                      julQtd = parseFloat(item3.totalPedidos);
                      julCusto = parseFloat(item3.VlrCusto);
                      julLucro = jul ? (1 - julCusto / jul) * 100 : 0;
                      julTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "8":
                      ago = parseFloat(item3.VlrTotal);
                      agoQtd = parseFloat(item3.totalPedidos);
                      agoCusto = parseFloat(item3.VlrCusto);
                      agoLucro = ago ? (1 - agoCusto / ago) * 100 : 0;
                      agoTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "9":
                      set = parseFloat(item3.VlrTotal);
                      setQtd = parseFloat(item3.totalPedidos);
                      setCusto = parseFloat(item3.VlrCusto);
                      setLucro = set ? (1 - setCusto / set) * 100 : 0;
                      setTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "10":
                      out = parseFloat(item3.VlrTotal);
                      outQtd = parseFloat(item3.totalPedidos);
                      outCusto = parseFloat(item3.VlrCusto);
                      outLucro = out ? (1 - outCusto / out) * 100 : 0;
                      outTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "11":
                      nov = parseFloat(item3.VlrTotal);
                      novQtd = parseFloat(item3.totalPedidos);
                      novCusto = parseFloat(item3.VlrCusto);
                      novLucro = nov ? (1 - novCusto / nov) * 100 : 0;
                      novTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    case "12":
                      dez = parseFloat(item3.VlrTotal);
                      dezQtd = parseFloat(item3.totalPedidos);
                      dezCusto = parseFloat(item3.VlrCusto);
                      dezLucro = dez ? (1 - dezCusto / dez) * 100 : 0;
                      dezTicket = item3?.totalPedidos
                        ? item3.VlrTotal / item3.totalPedidos
                        : 0;
                      break;
                    default:
                      break;
                  }
                  codLoja = item3.CodLoja;
                });
                total =
                  jan +
                  fev +
                  mar +
                  abr +
                  mai +
                  jun +
                  jul +
                  ago +
                  set +
                  out +
                  nov +
                  dez;

                totalQtd =
                  janQtd +
                  fevQtd +
                  marQtd +
                  abrQtd +
                  maiQtd +
                  junQtd +
                  julQtd +
                  agoQtd +
                  setQtd +
                  outQtd +
                  novQtd +
                  dezQtd;

                totalCusto =
                  janCusto +
                  fevCusto +
                  marCusto +
                  abrCusto +
                  maiCusto +
                  junCusto +
                  julCusto +
                  agoCusto +
                  setCusto +
                  outCusto +
                  novCusto +
                  dezCusto;

                totalTicket = total / totalQtd;
                totalLucro = 1 - totalCusto / total;
                if (index === 0) {
                  totalAnoAtual += total;
                  qtdAnoAtual += totalQtd;
                  custoAnoAtual += totalCusto;

                  linha = {
                    loja: codLoja,
                    jan,
                    fev,
                    mar,
                    abr,
                    mai,
                    jun,
                    jul,
                    ago,
                    set,
                    out,
                    nov,
                    dez,
                    total,
                    janTicket,
                    fevTicket,
                    marTicket,
                    abrTicket,
                    maiTicket,
                    junTicket,
                    julTicket,
                    agoTicket,
                    setTicket,
                    outTicket,
                    novTicket,
                    dezTicket,
                    totalTicket,
                    janQtd,
                    fevQtd,
                    marQtd,
                    abrQtd,
                    maiQtd,
                    junQtd,
                    julQtd,
                    agoQtd,
                    setQtd,
                    outQtd,
                    novQtd,
                    dezQtd,
                    totalQtd,
                    janLucro,
                    fevLucro,
                    marLucro,
                    abrLucro,
                    maiLucro,
                    junLucro,
                    julLucro,
                    agoLucro,
                    setLucro,
                    outLucro,
                    novLucro,
                    dezLucro,
                    totalLucro: totalLucro * 100,
                  };

                  janTotalAnoAtual += jan;
                  fevTotalAnoAtual += fev;
                  marTotalAnoAtual += mar;
                  abrTotalAnoAtual += abr;
                  maiTotalAnoAtual += mai;
                  junTotalAnoAtual += jun;
                  julTotalAnoAtual += jul;
                  agoTotalAnoAtual += ago;
                  setTotalAnoAtual += set;
                  outTotalAnoAtual += out;
                  novTotalAnoAtual += nov;
                  dezTotalAnoAtual += dez;

                  janQtdAnoAtual += janQtd;
                  fevQtdAnoAtual += fevQtd;
                  marQtdAnoAtual += marQtd;
                  abrQtdAnoAtual += abrQtd;
                  maiQtdAnoAtual += maiQtd;
                  junQtdAnoAtual += junQtd;
                  julQtdAnoAtual += julQtd;
                  agoQtdAnoAtual += agoQtd;
                  setQtdAnoAtual += setQtd;
                  outQtdAnoAtual += outQtd;
                  novQtdAnoAtual += novQtd;
                  dezQtdAnoAtual += dezQtd;

                  janCustoAnoAtual += janCusto;
                  fevCustoAnoAtual += fevCusto;
                  marCustoAnoAtual += marCusto;
                  abrCustoAnoAtual += abrCusto;
                  maiCustoAnoAtual += maiCusto;
                  junCustoAnoAtual += junCusto;
                  julCustoAnoAtual += julCusto;
                  agoCustoAnoAtual += agoCusto;
                  setCustoAnoAtual += setCusto;
                  outCustoAnoAtual += outCusto;
                  novCustoAnoAtual += novCusto;
                  dezCustoAnoAtual += dezCusto;

                  dadosDesempenho.push(linha);
                  dadosTabelaGlobalAnoAtual.push(linha);
                } else if (index === 1) {
                  totalAnoAnterior += total;

                  qtdAnoAnterior += totalQtd;
                  custoAnoAnterior += totalCusto;

                  linha = {
                    loja: codLoja,
                    jan,
                    fev,
                    mar,
                    abr,
                    mai,
                    jun,
                    jul,
                    ago,
                    set,
                    out,
                    nov,
                    dez,
                    total,
                    janTicket,
                    fevTicket,
                    marTicket,
                    abrTicket,
                    maiTicket,
                    junTicket,
                    julTicket,
                    agoTicket,
                    setTicket,
                    outTicket,
                    novTicket,
                    dezTicket,
                    totalTicket,
                    janQtd,
                    fevQtd,
                    marQtd,
                    abrQtd,
                    maiQtd,
                    junQtd,
                    julQtd,
                    agoQtd,
                    setQtd,
                    outQtd,
                    novQtd,
                    dezQtd,
                    totalQtd,
                    janLucro,
                    fevLucro,
                    marLucro,
                    abrLucro,
                    maiLucro,
                    junLucro,
                    julLucro,
                    agoLucro,
                    setLucro,
                    outLucro,
                    novLucro,
                    dezLucro,
                    totalLucro: totalLucro * 100,
                  };
                  janTotalAnoAnterior += jan;
                  fevTotalAnoAnterior += fev;
                  marTotalAnoAnterior += mar;
                  abrTotalAnoAnterior += abr;
                  maiTotalAnoAnterior += mai;
                  junTotalAnoAnterior += jun;
                  julTotalAnoAnterior += jul;
                  agoTotalAnoAnterior += ago;
                  setTotalAnoAnterior += set;
                  outTotalAnoAnterior += out;
                  novTotalAnoAnterior += nov;
                  dezTotalAnoAnterior += dez;

                  janQtdAnoAnterior += janQtd;
                  fevQtdAnoAnterior += fevQtd;
                  marQtdAnoAnterior += marQtd;
                  abrQtdAnoAnterior += abrQtd;
                  maiQtdAnoAnterior += maiQtd;
                  junQtdAnoAnterior += junQtd;
                  julQtdAnoAnterior += julQtd;
                  agoQtdAnoAnterior += agoQtd;
                  setQtdAnoAnterior += setQtd;
                  outQtdAnoAnterior += outQtd;
                  novQtdAnoAnterior += novQtd;
                  dezQtdAnoAnterior += dezQtd;

                  janCustoAnoAnterior += janCusto;
                  fevCustoAnoAnterior += fevCusto;
                  marCustoAnoAnterior += marCusto;
                  abrCustoAnoAnterior += abrCusto;
                  maiCustoAnoAnterior += maiCusto;
                  junCustoAnoAnterior += junCusto;
                  julCustoAnoAnterior += julCusto;
                  agoCustoAnoAnterior += agoCusto;
                  setCustoAnoAnterior += setCusto;
                  outCustoAnoAnterior += outCusto;
                  novCustoAnoAnterior += novCusto;
                  dezCustoAnoAnterior += dezCusto;

                  dadosDesempenho.push(linha);
                  dadosTabelaGlobalAnoAnterior.push(linha);
                }
              }
            });
            dadosTabelaGlobalDesempenho.push(dadosDesempenho);
          }
        });
        var auxDadosTabelaGlobalDesempenho = [];
        dadosTabelaGlobalDesempenho[0].map((item) => {
          dadosTabelaGlobalDesempenho[1].map((item2) => {
            if (item.loja === item2.loja) {
              let janDes,
                fevDes,
                marDes,
                abrDes,
                maiDes,
                junDes,
                julDes,
                agoDes,
                setDes,
                outDes,
                novDes,
                dezDes,
                totalDes = 0;

              janDes =
                Number(item2.jan) === 0 ? 0 : (item.jan / item2.jan - 1) * 100;
              fevDes =
                Number(item2.fev) === 0 ? 0 : (item.fev / item2.fev - 1) * 100;
              marDes =
                Number(item2.mar) === 0 ? 0 : (item.mar / item2.mar - 1) * 100;
              abrDes =
                Number(item2.abr) === 0 ? 0 : (item.abr / item2.abr - 1) * 100;
              maiDes =
                Number(item2.mai) === 0 ? 0 : (item.mai / item2.mai - 1) * 100;
              junDes =
                Number(item2.jun) === 0 ? 0 : (item.jun / item2.jun - 1) * 100;
              julDes =
                Number(item2.jul) === 0 ? 0 : (item.jul / item2.jul - 1) * 100;
              agoDes =
                Number(item2.ago) === 0 ? 0 : (item.ago / item2.ago - 1) * 100;
              setDes =
                Number(item2.set) === 0 ? 0 : (item.set / item2.set - 1) * 100;
              outDes =
                Number(item2.out) === 0 ? 0 : (item.out / item2.out - 1) * 100;
              novDes =
                Number(item2.nov) === 0 ? 0 : (item.nov / item2.nov - 1) * 100;
              dezDes =
                Number(item2.dez) === 0 ? 0 : (item.dez / item2.dez - 1) * 100;

              totalDes =
                janDes +
                fevDes +
                marDes +
                abrDes +
                maiDes +
                junDes +
                julDes +
                agoDes +
                setDes +
                outDes +
                novDes +
                dezDes;

              auxDadosTabelaGlobalDesempenho.push({
                loja: item.loja,
                jan: parseFloat(janDes.toFixed(2)),
                fev: parseFloat(fevDes.toFixed(2)),
                mar: parseFloat(marDes.toFixed(2)),
                abr: parseFloat(abrDes.toFixed(2)),
                mai: parseFloat(maiDes.toFixed(2)),
                jun: parseFloat(junDes.toFixed(2)),
                jul: parseFloat(julDes.toFixed(2)),
                ago: parseFloat(agoDes.toFixed(2)),
                set: parseFloat(setDes.toFixed(2)),
                out: parseFloat(outDes.toFixed(2)),
                nov: parseFloat(novDes.toFixed(2)),
                dez: parseFloat(dezDes.toFixed(2)),
                total: parseFloat((totalDes / 12).toFixed(2)),
              });

              janDesempenho += janDes;
              fevDesempenho += fevDes;
              marDesempenho += marDes;
              abrDesempenho += abrDes;
              maiDesempenho += maiDes;
              junDesempenho += junDes;
              julDesempenho += julDes;
              agoDesempenho += agoDes;
              setDesempenho += setDes;
              outDesempenho += outDes;
              novDesempenho += novDes;
              dezDesempenho += dezDes;
            }
          });
        });
        var totalLojasReferencia =
          dadosTabelaGlobalDesempenho[0].length <
          dadosTabelaGlobalDesempenho[1].length
            ? dadosTabelaGlobalDesempenho[0].length
            : dadosTabelaGlobalDesempenho[1].length;
        var desempenhoTotal = (totalAnoAtual / totalAnoAnterior - 1) * 100;
        auxDadosTabelaGlobalDesempenho.push({
          loja: "Total",
          jan: parseFloat((janDesempenho / totalLojasReferencia).toFixed(2)),
          fev: parseFloat((fevDesempenho / totalLojasReferencia).toFixed(2)),
          mar: parseFloat((marDesempenho / totalLojasReferencia).toFixed(2)),
          abr: parseFloat((abrDesempenho / totalLojasReferencia).toFixed(2)),
          mai: parseFloat((maiDesempenho / totalLojasReferencia).toFixed(2)),
          jun: parseFloat((junDesempenho / totalLojasReferencia).toFixed(2)),
          jul: parseFloat((julDesempenho / totalLojasReferencia).toFixed(2)),
          ago: parseFloat((agoDesempenho / totalLojasReferencia).toFixed(2)),
          set: parseFloat((setDesempenho / totalLojasReferencia).toFixed(2)),
          out: parseFloat((outDesempenho / totalLojasReferencia).toFixed(2)),
          nov: parseFloat((novDesempenho / totalLojasReferencia).toFixed(2)),
          dez: parseFloat((dezDesempenho / totalLojasReferencia).toFixed(2)),
          total: parseFloat(desempenhoTotal.toFixed(2)),
        });
        dadosTabelaGlobalAnoAtual.push({
          loja: "Total",
          jan: janTotalAnoAtual,
          fev: fevTotalAnoAtual,
          mar: marTotalAnoAtual,
          abr: abrTotalAnoAtual,
          mai: maiTotalAnoAtual,
          jun: junTotalAnoAtual,
          jul: julTotalAnoAtual,
          ago: agoTotalAnoAtual,
          set: setTotalAnoAtual,
          out: outTotalAnoAtual,
          nov: novTotalAnoAtual,
          dez: dezTotalAnoAtual,
          total: totalAnoAtual,

          janQtd: janQtdAnoAtual,
          fevQtd: fevQtdAnoAtual,
          marQtd: marQtdAnoAtual,
          abrQtd: abrQtdAnoAtual,
          maiQtd: maiQtdAnoAtual,
          junQtd: junQtdAnoAtual,
          julQtd: julQtdAnoAtual,
          agoQtd: agoQtdAnoAtual,
          setQtd: setQtdAnoAtual,
          outQtd: outQtdAnoAtual,
          novQtd: novQtdAnoAtual,
          dezQtd: dezQtdAnoAtual,
          totalQtd: qtdAnoAtual,

          janTicket: janQtdAnoAtual ? janTotalAnoAtual / janQtdAnoAtual : 0,
          fevTicket: fevQtdAnoAtual ? fevTotalAnoAtual / fevQtdAnoAtual : 0,
          marTicket: marQtdAnoAtual ? marTotalAnoAtual / marQtdAnoAtual : 0,
          abrTicket: abrQtdAnoAtual ? abrTotalAnoAtual / abrQtdAnoAtual : 0,
          maiTicket: maiQtdAnoAtual ? maiTotalAnoAtual / maiQtdAnoAtual : 0,
          junTicket: junQtdAnoAtual ? junTotalAnoAtual / junQtdAnoAtual : 0,
          julTicket: julQtdAnoAtual ? julTotalAnoAtual / julQtdAnoAtual : 0,
          agoTicket: agoQtdAnoAtual ? agoTotalAnoAtual / agoQtdAnoAtual : 0,
          setTicket: setQtdAnoAtual ? setTotalAnoAtual / setQtdAnoAtual : 0,
          outTicket: outQtdAnoAtual ? outTotalAnoAtual / outQtdAnoAtual : 0,
          novTicket: novQtdAnoAtual ? novTotalAnoAtual / novQtdAnoAtual : 0,
          dezTicket: dezQtdAnoAtual ? dezTotalAnoAtual / dezQtdAnoAtual : 0,
          totalTicket: totalAnoAtual / qtdAnoAtual,

          janLucro: janTotalAnoAtual
            ? (1 - janCustoAnoAtual / janTotalAnoAtual) * 100
            : 0,
          fevLucro: fevTotalAnoAtual
            ? (1 - fevCustoAnoAtual / fevTotalAnoAtual) * 100
            : 0,
          marLucro: marTotalAnoAtual
            ? (1 - marCustoAnoAtual / marTotalAnoAtual) * 100
            : 0,
          abrLucro: abrTotalAnoAtual
            ? (1 - abrCustoAnoAtual / abrTotalAnoAtual) * 100
            : 0,
          maiLucro: maiTotalAnoAtual
            ? (1 - maiCustoAnoAtual / maiTotalAnoAtual) * 100
            : 0,
          junLucro: junTotalAnoAtual
            ? (1 - junCustoAnoAtual / junTotalAnoAtual) * 100
            : 0,
          julLucro: julTotalAnoAtual
            ? (1 - julCustoAnoAtual / julTotalAnoAtual) * 100
            : 0,
          agoLucro: agoTotalAnoAtual
            ? (1 - agoCustoAnoAtual / agoTotalAnoAtual) * 100
            : 0,
          setLucro: setTotalAnoAtual
            ? (1 - setCustoAnoAtual / setTotalAnoAtual) * 100
            : 0,
          outLucro: outTotalAnoAtual
            ? (1 - outCustoAnoAtual / outTotalAnoAtual) * 100
            : 0,
          novLucro: novTotalAnoAtual
            ? (1 - novCustoAnoAtual / novTotalAnoAtual) * 100
            : 0,
          dezLucro: dezTotalAnoAtual
            ? (1 - dezCustoAnoAtual / dezTotalAnoAtual) * 100
            : 0,
          totalLucro: totalAnoAtual
            ? (1 - custoAnoAtual / totalAnoAtual) * 100
            : 0,
        });
        dadosTabelaGlobalAnoAnterior.push({
          loja: "Total",
          jan: janTotalAnoAnterior,
          fev: fevTotalAnoAnterior,
          mar: marTotalAnoAnterior,
          abr: abrTotalAnoAnterior,
          mai: maiTotalAnoAnterior,
          jun: junTotalAnoAnterior,
          jul: julTotalAnoAnterior,
          ago: agoTotalAnoAnterior,
          set: setTotalAnoAnterior,
          out: outTotalAnoAnterior,
          nov: novTotalAnoAnterior,
          dez: dezTotalAnoAnterior,
          total: totalAnoAnterior,

          janQtd: janQtdAnoAnterior,
          fevQtd: fevQtdAnoAnterior,
          marQtd: marQtdAnoAnterior,
          abrQtd: abrQtdAnoAnterior,
          maiQtd: maiQtdAnoAnterior,
          junQtd: junQtdAnoAnterior,
          julQtd: julQtdAnoAnterior,
          agoQtd: agoQtdAnoAnterior,
          setQtd: setQtdAnoAnterior,
          outQtd: outQtdAnoAnterior,
          novQtd: novQtdAnoAnterior,
          dezQtd: dezQtdAnoAnterior,
          totalQtd: qtdAnoAnterior,

          janTicket: janQtdAnoAnterior
            ? janTotalAnoAnterior / janQtdAnoAnterior
            : 0,
          fevTicket: fevQtdAnoAnterior
            ? fevTotalAnoAnterior / fevQtdAnoAnterior
            : 0,
          marTicket: marQtdAnoAnterior
            ? marTotalAnoAnterior / marQtdAnoAnterior
            : 0,
          abrTicket: abrQtdAnoAnterior
            ? abrTotalAnoAnterior / abrQtdAnoAnterior
            : 0,
          maiTicket: maiQtdAnoAnterior
            ? maiTotalAnoAnterior / maiQtdAnoAnterior
            : 0,
          junTicket: junQtdAnoAnterior
            ? junTotalAnoAnterior / junQtdAnoAnterior
            : 0,
          julTicket: julQtdAnoAnterior
            ? julTotalAnoAnterior / julQtdAnoAnterior
            : 0,
          agoTicket: agoQtdAnoAnterior
            ? agoTotalAnoAnterior / agoQtdAnoAnterior
            : 0,
          setTicket: setQtdAnoAnterior
            ? setTotalAnoAnterior / setQtdAnoAnterior
            : 0,
          outTicket: outQtdAnoAnterior
            ? outTotalAnoAnterior / outQtdAnoAnterior
            : 0,
          novTicket: novQtdAnoAnterior
            ? novTotalAnoAnterior / novQtdAnoAnterior
            : 0,
          dezTicket: dezQtdAnoAnterior
            ? dezTotalAnoAnterior / dezQtdAnoAnterior
            : 0,
          totalTicket: totalAnoAnterior / qtdAnoAnterior,

          janLucro: janTotalAnoAnterior
            ? (1 - janCustoAnoAnterior / janTotalAnoAnterior) * 100
            : 0,
          fevLucro: fevTotalAnoAnterior
            ? (1 - fevCustoAnoAnterior / fevTotalAnoAnterior) * 100
            : 0,
          marLucro: marTotalAnoAnterior
            ? (1 - marCustoAnoAnterior / marTotalAnoAnterior) * 100
            : 0,
          abrLucro: abrTotalAnoAnterior
            ? (1 - abrCustoAnoAnterior / abrTotalAnoAnterior) * 100
            : 0,
          maiLucro: maiTotalAnoAnterior
            ? (1 - maiCustoAnoAnterior / maiTotalAnoAnterior) * 100
            : 0,
          junLucro: junTotalAnoAnterior
            ? (1 - junCustoAnoAnterior / junTotalAnoAnterior) * 100
            : 0,
          julLucro: julTotalAnoAnterior
            ? (1 - julCustoAnoAnterior / julTotalAnoAnterior) * 100
            : 0,
          agoLucro: agoTotalAnoAnterior
            ? (1 - agoCustoAnoAnterior / agoTotalAnoAnterior) * 100
            : 0,
          setLucro: setTotalAnoAnterior
            ? (1 - setCustoAnoAnterior / setTotalAnoAnterior) * 100
            : 0,
          outLucro: outTotalAnoAnterior
            ? (1 - outCustoAnoAnterior / outTotalAnoAnterior) * 100
            : 0,
          novLucro: novTotalAnoAnterior
            ? (1 - novCustoAnoAnterior / novTotalAnoAnterior) * 100
            : 0,
          dezLucro: dezTotalAnoAnterior
            ? (1 - dezCustoAnoAnterior / dezTotalAnoAnterior) * 100
            : 0,
          totalLucro: totalAnoAnterior
            ? (1 - custoAnoAnterior / totalAnoAnterior) * 100
            : 0,
        });
        setDadosParaTabelaGlobalDesempenho(auxDadosTabelaGlobalDesempenho);
        setDadosParaTabelaGlobalAnoAtual(dadosTabelaGlobalAnoAtual);
        setDadosParaTabelaGlobalAnoAnterior(dadosTabelaGlobalAnoAnterior);
        setExibeTabelaGlobal(true);
      } else {
        setExibeTabelaGlobal(false);
      }
    } else {
      setExibeTabelaGlobal(false);
    }
  }

  function preencherDadosTabelaMes(dados, anoAnterior, anoAtual) {
    var dadosTabela = [];
    var dadosResumoTabela = [];
    var quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      vlrMedioAnoAtualResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      vlrMedioAnoAnteriorResumo = 0,
      desempenhoResumo = 0,
      rprAnoAtual = 0,
      rprAnoAnterior = 0,
      lucroBrutoTotalAnoAtualResumo = 0,
      lucroBrutoTotalAnoAnteriorResumo = 0,
      lucroPorcentagemAnoAtualResumo = 0,
      lucroPorcentagemAnoAnteriorResumo = 0,
      custoTotalAnoAtualResumo = 0,
      custoTotalAnoAnteriorResumo = 0;

    var vlrTotalAnoAtualAux = 0;
    var vlrTotalAnoAnteriorAux = 0;

    if (dados.length > 0) {
      dados.forEach((item) => {
        item[1].forEach((item2, index2) => {
          if (+item2.ano === +anoAtual) {
            vlrTotalAnoAtualAux += parseInt(item2.VlrTotal);
          } else if (+item2.ano === +anoAnterior) {
            vlrTotalAnoAnteriorAux += parseInt(item2.VlrTotal);
          }
        });
      });
      dados.forEach((item) => {
        let mes = parseInt(item[0]);
        let qtdAnoAtual = 0,
          vlrAnoAtual = 0,
          vlrMedioAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrTotalAnoAnterior = 0,
          vlrMedioAnoAnterior = 0,
          desempenho = 0,
          representatividadeAtual = 0,
          representatividadeAnterior = 0,
          custoTotalAnoAtual = 0,
          custoTotalAnoAnterior = 0,
          lucroBrutoTotalAnoAtual = 0,
          lucroBrutoTotalAnoAnterior = 0,
          lucroPorcentagemAnoAtual = 0,
          lucroPorcentagemAnoAnterior = 0;

        item[1].forEach((item2, index2) => {
          if (+item2.ano === +anoAtual) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = parseFloat(parseFloat(item2.VlrTotal).toFixed(2));

            custoTotalAnoAtual = parseFloat(
              parseFloat(item2.totalCusto).toFixed(2)
            );
            lucroBrutoTotalAnoAtual = parseFloat(
              parseFloat(item2.total - item2.totalCusto).toFixed(2)
            );
            lucroPorcentagemAnoAtual = parseFloat(
              parseFloat(
                Number((item2.total - item2.totalCusto) / item2.total) * 100
              ).toFixed(2)
            );

            vlrMedioAnoAtual = parseFloat(
              (vlrAnoAtual / qtdAnoAtual).toFixed(2)
            );
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            vlrMedioAnoAtualResumo += vlrMedioAnoAtual;

            custoTotalAnoAtualResumo += custoTotalAnoAtual;
            lucroBrutoTotalAnoAtualResumo += lucroBrutoTotalAnoAtual;
            lucroPorcentagemAnoAtualResumo += lucroPorcentagemAnoAtual;

            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else if (+item2.ano === +anoAnterior) {
            qtdAnoAnterior = parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = parseFloat(
              parseFloat(item2.VlrTotal).toFixed(2)
            );
            vlrMedioAnoAnterior = parseFloat(
              (vlrTotalAnoAnterior / qtdAnoAnterior).toFixed(2)
            );

            custoTotalAnoAnterior = isNaN(item2.totalCusto)
              ? 0
              : parseFloat(parseFloat(item2.totalCusto).toFixed(2));
            lucroBrutoTotalAnoAnterior = isNaN(item2.totalLucro)
              ? 0
              : parseFloat(
                  parseFloat(item2.total - item2.totalCusto).toFixed(2)
                );
            lucroPorcentagemAnoAnterior = isNaN(item2.porcentagemLucro)
              ? 0
              : parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                );

            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            vlrMedioAnoAnteriorResumo += vlrMedioAnoAnterior;

            custoTotalAnoAnteriorResumo += custoTotalAnoAnterior;
            lucroBrutoTotalAnoAnteriorResumo += lucroBrutoTotalAnoAnterior;
            lucroPorcentagemAnoAnteriorResumo += lucroPorcentagemAnoAnterior;

            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (qtdAnoAnterior > 0) {
          desempenho = parseFloat(
            ((qtdAnoAtual / qtdAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempenho = 0;
        }
        desempenhoResumo += desempenho;
        representatividadeAtual = parseFloat(
          ((vlrAnoAtual / vlrTotalAnoAtualAux) * 100).toFixed(2)
        );
        representatividadeAnterior = parseFloat(
          ((vlrTotalAnoAnterior / vlrTotalAnoAnteriorAux) * 100).toFixed(2)
        );
        rprAnoAtual += representatividadeAtual;
        rprAnoAnterior += representatividadeAnterior;
        dadosTabela.push({
          mes: mes,
          qtdAnoAtual: qtdAnoAtual,
          vlrAnoAtual: vlrAnoAtual,
          vlrMedioAnoAtual: vlrMedioAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          vlrMedioAnoAnterior: vlrMedioAnoAnterior,
          desempenho: desempenho,
          representatividadeAtual: representatividadeAtual,
          representatividadeAnterior: representatividadeAnterior,
          custoTotalAtual: custoTotalAnoAtual,
          custoTotalAnterior: custoTotalAnoAnterior,
          lucroBrutoAtual: lucroBrutoTotalAnoAtual,
          lucroBrutoAnterior: lucroBrutoTotalAnoAnterior,
          lucro: lucroPorcentagemAnoAtual,
          lucroAnterior: lucroPorcentagemAnoAnterior,
        });
      });
    }

    vlrMedioAnoAtualResumo = parseFloat(
      (vlrMedioAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    vlrMedioAnoAnteriorResumo = parseFloat(
      (vlrMedioAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(2)
    );
    desempenhoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );

    lucroPorcentagemAnoAtualResumo = parseFloat(
      (lucroPorcentagemAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );

    dadosResumoTabela.push({
      total: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,

      custoTotalAtual: custoTotalAnoAtualResumo,
      custoTotalAnterior: custoTotalAnoAnteriorResumo,
      lucroBrutoAtual: lucroBrutoTotalAnoAtualResumo,
      lucroBrutoAnterior: lucroBrutoTotalAnoAnteriorResumo,
      lucro: lucroPorcentagemAnoAtualResumo,
      lucroAnterior: lucroPorcentagemAnoAnteriorResumo,

      desempenho: desempenhoResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
    });
    setDadosParaTabela(dadosTabela);
  }

  async function preencherDadosGraficoAgrupadoMes(
    dados,
    anoAnterior,
    anoAtual
  ) {
    dados = dados.reverse();
    var label = [];
    var labelCompleta = [];
    var dataSets = [];
    var dadosTabelaAux = [];
    const promise = await dados.map((item, i) => {
      var dadosGrupo = [];
      item[1] = item[1].sort((a, b) => {
        return a.mes - b.mes;
      });

      item[1].map((itemGrupo) => {
        var nomeMes = converterMes(itemGrupo.mes);
        if (filtrosMesesSelecionados.length > 0) {
          if (filtrosMesesSelecionados.indexOf(nomeMes) >= 0) {
            let jaExiste = label.indexOf(nomeMes);
            if (jaExiste < 0) {
              label.push(nomeMes);
              labelCompleta.push(nomeMes);
            }
            dadosGrupo.push(parseFloat(itemGrupo.VlrTotal));
            dadosTabelaAux.push(itemGrupo);
          } else {
            let jaExiste = labelCompleta.indexOf(nomeMes);
            if (jaExiste < 0) {
              labelCompleta.push(nomeMes);
            }
          }
        } else {
          let jaExiste = label.indexOf(nomeMes);
          if (jaExiste < 0) {
            label.push(nomeMes);
            labelCompleta.push(nomeMes);
          }
          dadosGrupo.push(parseFloat(itemGrupo.VlrTotal));
          dadosTabelaAux.push(itemGrupo);
        }
      });
      var cor =
        item[0] === anoAnterior
          ? "rgba(255, 194, 218, 1)"
          : item[0] === anoAtual
          ? "rgba(91, 139, 252, 1)"
          : i === dados.length - 3
          ? "#5B8BFC"
          : i === dados.length - 4
          ? "#FFC2DA"
          : i === dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (item[0] === anoAnterior) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else if (item[0] === anoAtual) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      }
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };

    dadosTabelaAux = agruparPor(dadosTabelaAux, "mes");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    preencherDadosTabelaMes(dadosTabelaAux, anoAnterior, anoAtual);
    setDadosGraficoAgrupado(data);
    setMesesDisponiveis(labelCompleta);
    setNivelAtivoDoGraficoTotalVendas(1);
  }

  async function preencherDadosGraficoDesempenho(dados) {
    var label = [];
    var dadosDesempenho = [];
    var backgroundColor = [];

    const promise = await dados.map((item, i) => {
      var loja = converterLoja(item.loja.toString());
      label.push(loja);
      dadosDesempenho.push(item.desempenho);
      backgroundColor.push("rgba(255, 194, 218, 1)");
    });

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: [
        {
          label: "Desempenho %",
          data: dadosDesempenho,
          backgroundColor: backgroundColor,
          borderWidth: 1,
        },
      ],
    };

    setDadosGraficoDesempenho(data);
    setDadosGlobaisDesempenho(data);
    setNivelAtivoDoGraficoDesempenho(0);
  }

  function preencherDadosVendasPorMes(codLoja) {
    var dados = dadosGerais;
    dados = dados.filter((a) => {
      return a.CodLoja === codLoja ? true : false;
    });
    let grupoDeAnos = agruparPor(dados, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);

    preencherDadosGraficoAgrupadoMes(
      grupoDeAnosArray,
      auxAnoAnterior,
      auxAnoAtual
    );
  }

  function preencherDadosVendasPorLoja() {
    var dados = dadosGerais;
    let grupoDeAnos = agruparPor(dados, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let grupoDeAnoLoja = [];
    grupoDeAnosArray.map((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodLoja");
      grupoDeAnoLoja.push(grupoDeLoja);
    });

    let resumoGrupoDeAnoLoja = [];
    grupoDeAnoLoja.map((item) => {
      let item2 = Object.entries(item);
      item2.map((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codLoja;

        let totalCusto = 0;
        let totalLucro = 0;
        let porcentagemLucro = 0;

        item3[1].map((item4, index) => {
          if (index === 0) {
            ano = item4.ano;
            codLoja = item4.CodLoja;
          }
          totalPedidos += parseInt(item4.totalPedidos);
          total += parseFloat(item4.VlrTotal);

          totalCusto += parseFloat(item4.VlrCusto);
          totalLucro += parseFloat(item4.VlrLucroBruto);
          porcentagemLucro += parseFloat(item4.VlrLucroBrutoPorcentagem);
        });
        let resumoLojaAno = {
          ano: ano,
          codLoja: codLoja,
          total: total,
          totalPedidos: totalPedidos,
          totalCusto,
          totalLucro,
          porcentagemLucro,
        };
        resumoGrupoDeAnoLoja.push(resumoLojaAno);
      });
    });

    var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");

    grupoDeAnosArray = Object.entries(agrupado);

    preencherDadosGraficoAgrupado(
      grupoDeAnosArray,
      auxAnoAnterior,
      auxAnoAtual,
      tipoPeriodo,
      dadosUltimoPeriodo
    );
    preencherDadosTabelasGlobais(dadosTabelasGlobais);
  }

  function iniciarFiltroPorPeriodo() {
    setTipoPeriodo("input");
    setPeriodoAno(false);
    setFiltrosAnosSelecionados([]);
    setPeriodoInputInicio(periodoInputInicioAux);
    setPeriodoInputFim(periodoInputFimAux);
  }

  function iniciarFiltroHoje() {
    var dataHoje = new Date();
    var [, hoje] = converterDataFormatoPadrao(dataHoje);

    setPeriodoInputInicio(hoje);
    setPeriodoInputFim(hoje);

    setPeriodoInputInicioAux(hoje);
    setPeriodoInputFimAux(hoje);
  }

  function limparFiltros() {
    setFiltrosAnosSelecionados([]);
    setPeriodoAno(false);
    resetarGraficoDesempenho();
  }

  function fVoltar() {
    // setIsLoading(true)
    // setResetGrafico(true)
    setMesesDisponiveis(false);
    setCodLojaAtiva(false);
    let dataVendas = dadosGerais;
    let grupoDeAnos = agruparPor(dataVendas, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let grupoDeAnoLoja = [];
    grupoDeAnosArray.forEach((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodLoja");
      grupoDeAnoLoja.push(grupoDeLoja);
    });

    let resumoGrupoDeAnoLoja = [];
    grupoDeAnoLoja.forEach((item) => {
      let item2 = Object.entries(item);
      item2.forEach((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codLoja;
        let totalCusto = 0;
        let totalLucro = 0;
        let porcentagemLucro = 0;

        item3[1].forEach((item4, index) => {
          if (index === 0) {
            ano = item4.ano;
            codLoja = item4.CodLoja;
          }
          totalPedidos += parseInt(item4.totalPedidos);
          total += parseFloat(item4.VlrTotal);

          totalCusto += parseFloat(item4.VlrCusto);
          totalLucro += parseFloat(item4.VlrLucroBruto);
          porcentagemLucro += parseFloat(item4.VlrLucroBrutoPorcentagem);
        });
        let resumoLojaAno = {
          ano: ano,
          codLoja: codLoja,
          total: total,
          totalPedidos: totalPedidos,
          totalCusto,
          totalLucro,
          porcentagemLucro,
        };
        resumoGrupoDeAnoLoja.push(resumoLojaAno);
      });
    });
    setTipoPeriodo("input");
    var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");
    grupoDeAnosArray = Object.entries(agrupado);
    preencherDadosGraficoAgrupado(
      grupoDeAnosArray,
      auxAnoAnterior,
      auxAnoAtual,
      "input",
      dadosUltimoPeriodo
    );
    setNivelAtivoDoGraficoTotalVendas(0);
  }

  function resetarGraficoDesempenho() {
    setDadosGraficoDesempenho(dadosGlobaisDesempenho);
    setNivelAtivoDoGraficoDesempenho(0);
  }

  function filtrarPorMes(mes) {
    setTipoPeriodo("mes");
    var mesesSelecionados = [];
    if (filtrosMesesSelecionados.length === 0) {
      mesesSelecionados.push(mes);
    } else {
      if (filtrosMesesSelecionados.indexOf(mes) >= 0) {
        filtrosMesesSelecionados.map((item) => {
          if (item !== mes) {
            mesesSelecionados.push(item);
          }
        });
      } else {
        filtrosMesesSelecionados.map((item) => {
          mesesSelecionados.push(item);
        });
        mesesSelecionados.push(mes);
      }
    }
    setFiltrosMesesSelecionados(mesesSelecionados);
    var mesesDisponiveisAux = [];
    mesesDisponiveis.map((item) => {
      mesesDisponiveisAux.push(item);
    });
    setMesesDisponiveis(mesesDisponiveisAux);
  }

  function filtrarPorAno(ano) {
    let filtrosLojasSelecionadasAuc = filtrosLojasSelecionadas;
    setTipoPeriodo("ano");
    setDadosGraficoAgrupado(false);
    setMesesDisponiveis(false);
    var anosSelecionados = [];
    if (filtrosAnosSelecionados.length === 0) {
      anosSelecionados.push(ano);
    } else {
      if (filtrosAnosSelecionados.indexOf(ano) >= 0) {
        filtrosAnosSelecionados.map((item) => {
          if (item !== ano) {
            anosSelecionados.push(item);
          }
        });
      } else {
        filtrosAnosSelecionados.map((item) => {
          anosSelecionados.push(item);
        });
        anosSelecionados.push(ano);
      }
    }
    if (anosSelecionados.length === 0) {
      setPeriodoAno(false);
      preencherInputPeriodo();
      setFiltrosAnosSelecionados([]);
    } else {
      setFiltrosAnosSelecionados(anosSelecionados);
      setFiltrosLojasSelecionadas(filtrosLojasSelecionadasAuc);
    }
  }
  const LoadingTemp = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };

  const iniciarFiltroPorLoja = (lojas) => {
    const lojasSelecionadas = lojas.map((item) => item.value);

    setFiltradoPorLoja(true);
    setFiltrosLojasSelecionadas(lojasSelecionadas);
  };

  function preencherGraficoDesempenhoPorMes(nomeLoja) {
    var codLoja = converterNomeLoja(nomeLoja);
    var label = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];
    var dadosDesempenhoMesEspecifico = [];
    var backgroundColor = [];
    dadosParaTabelaGlobalDesempenho.forEach((item) => {
      if (item.loja === codLoja) {
        dadosDesempenhoMesEspecifico = [
          item.jan,
          item.fev,
          item.mar,
          item.abr,
          item.mai,
          item.jun,
          item.jul,
          item.ago,
          item.set,
          item.out,
          item.nov,
          item.dez,
        ];
      }
    });

    label.forEach((item, i) => {
      backgroundColor.push(
        i === 0
          ? "rgb(255, 99, 132)"
          : i === 1
          ? "rgba(91, 139, 252, 1)"
          : i === 2
          ? "#00b7ce"
          : i === 3
          ? "#f6398d"
          : i === 4
          ? ""
          : geraCorAleatoria()
      );
    });

    const data = {
      labels: label,
      datasets: [
        {
          label: "Desempenho %",
          data: dadosDesempenhoMesEspecifico,
          backgroundColor: backgroundColor,
          borderWidth: 1,
        },
      ],
    };

    setDadosGraficoDesempenho(data);
    setNivelAtivoDoGraficoDesempenho(1);
  }

  const optionsChartBarVendas = {
    title: {
      display: true,
      padding: 20,
      text: "Valor Total de Vendas",
      fontColor: "#FC68A2",
      fontSize: 20,
    },
    legend: {
      position: "bottom",
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
      },
    },
  };

  const optionsChartBarDesempenho = {
    title: {
      display: false,
      padding: 20,
      fontColor: "#FC68A2",
      text: "Desempenho em Relação ao Ano Anterior",
      fontSize: 20,
    },
    legend: {
      position: "bottom",
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: "end",
        display: true,
        color: "#828282",
        align: "end",
      },
    },
  };

  const getElementAtEvent = (element) => {
    if (nivelAtivoDoGraficoTotalVendas > 0) return;
    if (!element.length) return;
    const { _index: index, _xScale: eixoX } = element[0];
    var nomeLoja = eixoX.ticks[index];
    let codLoja = converterNomeLoja(nomeLoja);
    if (!codLoja) return;
    setTipoPeriodo("mes");
    setCodLojaAtiva(codLoja);
  };

  const getElementAtEventGraficoDesempenho = (element) => {
    if (nivelAtivoDoGraficoDesempenho > 0) return;
    if (!element.length) return;
    const { _index: index, _xScale: eixoX } = element[0];
    var nomeLoja = eixoX.ticks[index];
    preencherGraficoDesempenhoPorMes(nomeLoja);
  };

  return (
    <>
      {PermissionsPage("vendGlobal") && (
        <>
          <Box ml={2}>
            <Title title="Vendas Global" />
          </Box>

          <Stack gap={2} direction={"row"} mx={2}>
            <Box component={Paper} variant="outlined" px={1} pb={1}>
              <FiltroPeriodo
                periodoInputInicioAux={periodoInputInicioAux}
                periodoInputFimAux={periodoInputFimAux}
                setPeriodoInputInicioAux={setPeriodoInputInicioAux}
                setPeriodoInputFimAux={setPeriodoInputFimAux}
              />
              <Stack gap={1}>
                <ButtonFilter
                  type="button"
                  onClick={() => iniciarFiltroPorPeriodo()}
                  disabled={isLoading}
                >
                  Filtrar
                </ButtonFilter>
                <ButtonToday
                  type="button"
                  onClick={() => iniciarFiltroHoje()}
                  disabled={isLoading}
                >
                  Hoje
                </ButtonToday>
              </Stack>
            </Box>

            <Box width={"100%"}>
              <Box pb={1}>
                <MultiSelectFilterBases
                  basesDisponiveis={basesDisponiveis}
                  basesSelecionadas={basesSelecionadas}
                  onChange={filtrarBases}
                  disabled={isLoading}
                />
                <Typography variant="caption" component={"span"}>
                  *Atenção: Quando nenhuma base estiver selecionada, todas as
                  bases serão retornadas.*
                </Typography>
              </Box>

              <Box>
                <Typography
                  textAlign={"initial"}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  Lojas
                </Typography>
                <Select
                  options={lojasDisponiveis.map((lojas) => ({
                    label: converterLoja(lojas),
                    value: lojas,
                  }))}
                  isMulti
                  onChange={iniciarFiltroPorLoja}
                  placeholder="Selecione uma loja"
                  styles={colourStyles}
                  isDisabled={isLoading}
                />
                <Typography variant="caption" component={"span"}>
                  *Atenção: Quando nenhuma loja estiver selecionada, todas as
                  lojas serão retornadas.*
                </Typography>
              </Box>
            </Box>
          </Stack>

          {isLoading ? (
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"90vh"}
            >
              <LoadingTable isLoading={isLoading} />
            </Box>
          ) : (
            <>
              {dadosDisponiveis && dadosGraficoAgrupado && (
                <>
                  <Box mx={2} component={Paper} variant="outlined" p={2} m={2}>
                    <Bar
                      data={dadosGraficoAgrupado}
                      options={optionsChartBarVendas}
                      getElementAtEvent={getElementAtEvent}
                    />
                    <ButtonVoltar fVoltar={fVoltar} />
                  </Box>
                </>
              )}

              <>
                {dadosDisponiveis && exibeTabela ? (
                  <>
                    <Box m={2} variant="outlined" style={{ overflow: "auto" }}>
                      <TituloTabela>Vendas Por Loja</TituloTabela>
                      <SubTituloTabela>
                        Legenda: AT - Ano atual AN - Ano anterior PA - Período
                        anterior{" "}
                      </SubTituloTabela>

                      <TabelaNext
                        dadosParaTabela={dadosParaTabela}
                        colunas={colunasInputMesAnos2(
                          tipoPeriodo,
                          anosDisponiveis,
                          filtrosAnosSelecionados
                        )}
                      />
                    </Box>
                  </>
                ) : (
                  <></>
                )}
              </>
              {anosDisponiveis.length > 1 && (
                <>
                  {resetGrafico && (
                    <>
                      <Box mx={2} variant="outlined" m={2}>
                        <TituloTabela>
                          Desempenho em Relação ao Ano Anterior{" "}
                        </TituloTabela>
                        <WrapperTable>
                          <aside>
                            <Bar
                              data={dadosGraficoDesempenho}
                              options={optionsChartBarDesempenho}
                              getElementAtEvent={
                                getElementAtEventGraficoDesempenho
                              }
                            />
                            <ButtonVoltar
                              fVoltar={() => resetarGraficoDesempenho()}
                            />
                          </aside>
                        </WrapperTable>
                      </Box>
                    </>
                  )}
                </>
              )}
              <>
                {dadosDisponiveis && exibeTabelaGlobal ? (
                  <>
                    <Box
                      mx={2}
                      variant="outlined"
                      m={2}
                      style={{ overflow: "auto" }}
                    >
                      <TituloTabela>VENDAS GLOBAIS ANO ATUAL</TituloTabela>
                      <>
                        <TabelaNext
                          dadosParaTabela={dadosParaTabelaGlobalAnoAtual}
                          colunas={colunasTabelasGlobaisV2()}
                        />
                        <LegendaTabela />
                      </>
                    </Box>
                  </>
                ) : (
                  ""
                )}
              </>

              <>
                {dadosDisponiveis && exibeTabelaGlobal ? (
                  <>
                    {anosDisponiveis.length > 1 && (
                      <>
                        <Box
                          mx={2}
                          variant="outlined"
                          m={2}
                          style={{ overflow: "auto" }}
                        >
                          <TituloTabela>
                            VENDAS GLOBAIS ANO ANTERIOR
                          </TituloTabela>
                          <>
                            <TabelaNext
                              dadosParaTabela={dadosParaTabelaGlobalAnoAnterior}
                              colunas={colunasTabelasGlobaisV2()}
                            />
                          </>
                        </Box>
                        <Box
                          mx={2}
                          variant="outlined"
                          m={2}
                          style={{ overflow: "auto" }}
                        >
                          <TituloTabela>DESEMPENHO POR MÊS</TituloTabela>
                          <TabelaNext
                            dadosParaTabela={dadosParaTabelaGlobalDesempenho}
                            colunas={colunasTabelasGlobaisNext(true)}
                          />
                        </Box>
                      </>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            </>
          )}
        </>
      )}
    </>
  );
}

export default VendasGlobal;
