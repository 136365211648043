import styled from "styled-components"
const larguraDisplay = {
    dispoMedio: "705px"
}

export const Content = styled.section`
background-color: #fff;
`
export const ContainerTitle = styled.section`
    padding: 1rem 1.5rem;
    justify-content: space-between;
    display: flex;
    @media(max-width:${larguraDisplay.dispoMedio} ){
        flex-direction: column-reverse;
    }
   div{
    display: flex;

    justify-content: center;
    align-items: center;
    img{
        width: 6rem;
        margin: 0;
        padding: 0;
    }
    h1{
        color: #f6398d;

    }
   }
   aside{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    @media(max-width:${larguraDisplay.dispoMedio} ){
        align-items: flex-end;
        h3{
            margin-bottom: 0;
        }
    }
   }
`
export const ContainerCard = styled.section`
    padding: 0 1.5rem 1rem 1.5rem ;
    display: flex;
    justify-content: center;
    gap: 2rem;
    transition: filter 2s;
    @media(max-width:${larguraDisplay.dispoMedio} ){
        flex-direction: column;
    }
    aside{
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: max-content;
        section{
            flex-direction: column;
            display: flex;
            justify-content: center;
            min-width: 17rem;
            @media (max-width:${larguraDisplay.dispoMedio} ){
                    min-width: 0;
                }
            small{
                margin:auto;
            }
            h6{
                text-align: center;
               
            }
            h5{
                display: flex;
                gap: 1rem;
                justify-content: space-between;
               span{
                font-weight: bold;
               }
            }
        }
        padding: 1.5rem 2rem;
        background: #b1cce4;
        border-radius: 0.24rem;
        >div{
            flex-direction: column;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 225px;
            max-width: 400px;
            border-color: #FF0000;
            @media (max-width:${larguraDisplay.dispoMedio} ){
                min-width: 0;
                min-width:100%;
            }
        }
    }

`