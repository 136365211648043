import React, { useEffect, useMemo, useState } from "react";

import { Button, Container, Form, Row, Tab, Tabs } from "react-bootstrap";
import "chartjs-plugin-datalabels";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import Title from "../../components/title";

import { colunasEstoqueMes } from "../../util/tables";
import TabelaNext from "../../components/componentteste/TabelaNext";
import { baseService } from "../../services/baseServices";
import { HorizontalBar } from "react-chartjs-2";
import {
  functionGraficsTables,
  options,
  options3,
  optionsQtd,
} from "../../services/chartjsOptions";
import {
  ContainerCenterBotoes,
  ContainerGrafico,
  ContainerTipoGrupo,
} from "./styles";
import {
  arrayBases,
  tratamentoDados,
  tratamentoDadosSb,
} from "../../util/loja";
import FilterLojaSimples from "../../components/FilterLojaSimples";
import toast from "react-hot-toast";

import FilterBases from "../../components/filterBases";
import { useRef } from "react";
import { PermissionsPage } from "../../util/permissionsPage";
import { useUserData } from "../../hooks/useUserData";
import { Box } from "@mui/material";
import { LoadingTable } from "../../components/Loading/LoadingTable";

const initialStateDataBase = () => {
  return {
    dadosGerais: [],
    dadosPorLoja: [],
    dadosEstoqueTransito: [],
    estoqueAguardandoEntrega: [],
  };
};
const initialStateNivelGrafico = () => {
  return;
};

function EstoqueMes() {
  const chartRef = useRef(null);

  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;
  const [filtrosLojasSelecionadas, setFiltrosLojasSelecionadas] = useState([]);
  const [radioValue, setRadioValue] = useState("1");
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [estoque, setEstoque] = useState([]);
  const [labelsBar, setLabelsBar] = useState([]);
  const [dataSet, setDataSet] = useState("");
  const [dataSet3, setDataSet3] = useState("");
  const [dataSetQtd, setDataSetQtd] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dataBar, setDataBar] = useState({});
  const [dadosAuxiliar, setDadosAuxiliar] = useState({
    todosSubGrupos: [],
    tipoGrupo: "Grupo",
    permissionAnterior: [],
  });
  const [nivelGrafico, setNivelGrafico] = useState(initialStateNivelGrafico);
  const [dataBarQtd, setDataBarQtd] = useState({});
  const [dataBar3, setDataBar3] = useState({});
  const [base, setBase] = useState();
  const [carregando, setCarregando] = useState(true);
  const [periodoReferencia, setPeriodoReferencia] = useState("");
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);
  const [dataBase, setDataBase] = useState(initialStateDataBase);
  const [graficos, setGraficos] = useState([]);
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [estadosControle, setEstadosControle] = useState({
    erroReceberDadosRAD: false,
    graficoHandleClickActive: false,
  });

  useEffect(() => {
    buscarNivelAcesso();
    setIsLoading(true);
    setCarregando(true);
  }, [userData]);

  useMemo(() => {
    if (
      periodoReferencia &&
      periodoReferencia !== "" &&
      accessLevel?.length > 0 &&
      acessBase?.length > 0 &&
      base
    ) {
      setIsLoading(true);
      preencherDadosEstoquePorGrupo();
    }
  }, [periodoReferencia, userData, base]);

  useEffect(() => {
    console.log("chartRef -> ", chartRef.current);
    setDataBar({
      labels: labelsBar?.dataSet1,
      datasets: dataSet,
    });
    setDataBarQtd({
      labels: labelsBar?.dataSetQtd,
      datasets: dataSetQtd,
    });
    setDataBar3({
      labels: labelsBar?.dataSet3,
      datasets: dataSet3,
    });
    const teste = chartRef?.current?.chartInstance?.update();
  }, [dataSet, labelsBar, dataSetQtd, dataSet3]);

  useEffect(() => {
    if (!dataBar || !dataBar3 || !dataBarQtd) {
      return;
    }
    const graficosAux = [
      {
        radioValue: "1",
        id: "CustoVendasCustoEstoque",
        height: 550,
        data: dataBar,
        options: options,
        getElementAtEvent: handleClickNivelGrafico,
        button: {
          name: "bttCustoVendasCustoEstoque",
          onClick: bttVoltarNivel,
        },
      },
      {
        radioValue: "3",
        id: "tmEstoqueTmVendas",
        height: 350,
        data: dataBar3,
        options: options3,
        getElementAtEvent: handleClickNivelGrafico,
        button: {
          name: "bttTmEstoqueTmVendas",
          onClick: bttVoltarNivel,
        },
      },
      {
        radioValue: "2",
        id: "QtdVendasQtdEstoque",
        height: 550,
        data: dataBarQtd,
        options: optionsQtd,
        getElementAtEvent: handleClickNivelGrafico,
        button: {
          name: "bttQtdVendasQtdEstoque",
          onClick: bttVoltarNivel,
        },
      },
    ];
    setGraficos(graficosAux);
  }, [
    dataBarQtd,
    optionsQtd,
    dataBar3,
    options3,
    dataBar,
    options,
    radioValue,
    dataSet,
    labelsBar,
    dataSetQtd,
    dataSet3,
  ]);

  useEffect(() => {
    const data = new Date();
    const dia = data.getDate();
    const mes = data.getMonth() + 1;
    const ano = data.getFullYear();
    const dataAtual = ano + "-" + mes + "-" + dia;
    setPeriodoReferencia(dataAtual);
  }, []);

  async function preencherDadosEstoquePorGrupo() {
    const toastId = toast.loading("Buscando dados...");
    const { dadosGerais, dadosPorLoja, lojasDisponiveis } =
      await baseService.getEstoqueMes(periodoReferencia, accessLevel, base);

    if (!dadosGerais) {
      toast.error("Erro ao receber dados do RAD", {
        id: toastId,
      });
      setEstadosControle({ ...estadosControle, erroReceberDadosRAD: true });
      setIsLoading(false);
      return;
    }
    setLojasDisponiveis(lojasDisponiveis);

    //colocar um aviso quando a consulta pelos dados for falso
    const { response, titles, dataSet1, dataSetQ, dataSetTM } =
      functionGraficsTables.manipulacaoDadosTabelasPreencher(dadosGerais);
    setDataBase({
      ...dataBase,
      dadosGerais: dadosGerais,
      dadosPorLoja: dadosPorLoja,
    });
    setEstoque(response);
    setLabelsBar({
      ...labelsBar,
      dataSet1: titles,
      dataSetQtd: titles,
      dataSet3: titles,
    });
    setDataSet(dataSet1);
    setDataSetQtd(dataSetQ);
    setDataSet3(dataSetTM);
    setDadosAuxiliar({
      ...dadosAuxiliar,
      dadosEstoque: response,
      todosSubGrupos: [],
      tipoGrupo: "Grupo",
    });
    if (dadosGerais) {
      setIsLoading(false);
      toast.success("Dados recebidos", {
        id: toastId,
      });
    }
  }
  const bttVoltarNivel = (event) => {
    if (!event.target) {
      return;
    }
    const dataBaseAux = dataBase?.dadosGerais;
    const { dataSetQ, titles, dataSet1, response, dataSetTM } =
      functionGraficsTables.manipulacaoDadosTabelasPreencher(dataBaseAux);
    setDataSetQtd(dataSetQ);
    setNivelGrafico({
      ...nivelGrafico,
      nivelTmEstoqueTmVendas: 0,
      nivelQtdVendasQtdEstoque: 0,
      nivelCustoVendasCustoEstoque: 0,
    });
    setLabelsBar({
      ...labelsBar,
      dataSetQtd: titles,
      dataSet3: titles,
      dataSet1: titles,
    });
    setDataSet3(dataSetTM);
    setDataSet(dataSet1);
    setEstoque(response);
    setEstadosControle({ ...estadosControle, graficoHandleClickActive: false });
    setFiltrosLojasSelecionadas([]);
    setDadosAuxiliar({
      ...dadosAuxiliar,
      todosSubGrupos: [],
      tipoGrupo: "Grupo",
    });
  };

  const buscarNivelAcesso = async () => {
    console.log("accessLevel -> ", accessLevel);
    if (accessLevel) {
      setBasesDisponiveis(arrayBases(acessBase));
      if (acessBase.length > 0) {
        setBase(acessBase[0]);
        setBasesSelecionadas([acessBase[0]]);
      }
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  };
  const filtrarBases = (codBase) => {
    setLojasDisponiveis([]);
    let basesSelecionadasTemp = basesSelecionadas.filter((a) => true);
    if (basesSelecionadasTemp.filter((a) => a === codBase).length > 0) {
      basesSelecionadasTemp = basesSelecionadasTemp.filter(
        (a) => a !== codBase
      );
    } else {
      basesSelecionadasTemp = codBase;
    }
    setBase(basesSelecionadasTemp);
    setBasesSelecionadas([basesSelecionadasTemp]);
  };
  const handleClickNivelGrafico = async (element) => {
    if (!functionGraficsTables.getElementAtEvent(element)) {
      return;
    }

    const { nomeGrupo, idGrafico } =
      functionGraficsTables.getElementAtEvent(element);
    let dadosGeraisAux = dataBase?.dadosGerais;
    let idGrupo = dadosGeraisAux.find((item) => {
      if (item.NomeGrupo === nomeGrupo) {
        return item.CodGrp1;
      } else {
        return false;
      }
    });
    if (!idGrupo) {
      return;
    }
    setEstadosControle({ ...estadosControle, graficoHandleClickActive: true });
    setIsLoading(true);
    preencherDadosSubGrupo(idGrupo.CodGrp1, idGrafico);
  };
  const preencherDadosSubGrupo = async (idGrupo, idGrafico) => {
    const toastId = toast.loading("Buscando dados...");
    const { dataAux, dataLojasSg, todosSubGrupos } =
      await baseService.getEstoqueMesSubGrupo(
        periodoReferencia,
        accessLevel,
        idGrupo,
        base
      );
    const { response, titles, dataSet1, dataSetQ, dataSetTM } =
      functionGraficsTables.manipulacaoDadosTabelasPreencher(dataAux);
    if (idGrafico === "CustoVendasCustoEstoque") {
      setLabelsBar({ ...labelsBar, dataSet1: titles });
      setNivelGrafico({ ...nivelGrafico, nivelCustoVendasCustoEstoque: 1 });
      setDataSet(dataSet1);
    }
    if (idGrafico === "QtdVendasQtdEstoque") {
      setNivelGrafico({ ...nivelGrafico, nivelQtdVendasQtdEstoque: 1 });
      setLabelsBar({ ...labelsBar, dataSetQtd: titles });
      setDataSetQtd(dataSetQ);
    }
    if (idGrafico === "tmEstoqueTmVendas") {
      setNivelGrafico({ ...nivelGrafico, nivelTmEstoqueTmVendas: 1 });
      setLabelsBar({ ...labelsBar, dataSet3: titles });
      setDataSet3(dataSetTM);
    }
    setDataBase({ ...dataBase, dataLojasSg: dataLojasSg });
    setEstoque(response);
    if (dataAux) {
      setIsLoading(false);
      toast.success("Dados recebidos", {
        id: toastId,
      });
    }
    const data =
      functionGraficsTables.manipulacaoDadosTabelasPreencher(todosSubGrupos);
    setDadosAuxiliar({
      ...dadosAuxiliar,
      idGrupo: idGrupo,
      idGrafico: idGrafico,
      todosSubGrupos: data.response,
      tipoGrupo: "Sub Grupo",
    });
  };
  useEffect(() => {
    if (filtrosLojasSelecionadas.length > 0) {
      if (
        dataBase?.dataLojasSg &&
        estadosControle.graficoHandleClickActive === true
      ) {
        dadosLojasSelecionadasSubGrupo(
          dataBase.dataLojasSg,
          filtrosLojasSelecionadas
        );
      }
    }
  }, [
    filtrosLojasSelecionadas,
    dataBase?.dataLojasSg,
    estadosControle.graficoHandleClickActive,
  ]);

  const radios = [
    { name: "Custo Vendas x Custo Estoque", value: "1" },
    { name: "Qtd Vendas x Qtd Estoque", value: "2" },
    { name: "TM Estoque x TM Vendas", value: "3" },
  ];
  function filtrarPorLoja(loja) {
    let lojasSelecionadas = [];
    if (filtrosLojasSelecionadas.length === 0) {
      lojasSelecionadas.push(loja);
    } else {
      if (filtrosLojasSelecionadas.indexOf(loja) >= 0) {
        filtrosLojasSelecionadas.map((item) => {
          if (item !== loja) {
            lojasSelecionadas.push(item);
          }
        });
      } else {
        filtrosLojasSelecionadas.map((item) => {
          lojasSelecionadas.push(item);
        });
        lojasSelecionadas.push(loja);
      }
    }
    if (estadosControle.graficoHandleClickActive === true) {
      dadosLojasSelecionadasSubGrupo(dataBase?.dataLojasSg, lojasSelecionadas);
    } else {
      dadosLojasSelecionadas(dataBase?.dadosPorLoja, lojasSelecionadas);
    }

    setFiltrosLojasSelecionadas(lojasSelecionadas);

    var lojasDisponiveisAux = [];
    lojasDisponiveis.map((item) => {
      lojasDisponiveisAux.push(item);
    });
    if (lojasSelecionadas.length === 0) {
      setIsLoading(true);
      if (estadosControle.graficoHandleClickActive === true) {
        preencherDadosSubGrupo(
          dadosAuxiliar?.idGrupo,
          dadosAuxiliar?.idGrafico
        );
      } else {
        preencherDadosEstoquePorGrupo();
      }
    } else {
      setLojasDisponiveis(lojasDisponiveisAux);
    }
  }

  const dadosLojasSelecionadasSubGrupo = (data, lojasSelecionadas) => {
    const { dadosEstoque, dadosVenda } = data;
    let dataAux = [],
      dataAuxVendas = [],
      dataAuxEstoque = [];
    lojasSelecionadas.forEach((item) => {
      dadosVenda.filter((event) => {
        if (event.CodLoja === item.replace(/[^0-9]/g, "")) {
          dataAuxVendas.push(event);
          return event;
        }
      });
    });
    lojasSelecionadas.forEach((item) => {
      dadosEstoque.filter((event) => {
        if (event.CodLoja === item.replace(/[^0-9]/g, "")) {
          dataAuxEstoque.push(event);
          return event;
        }
      });
    });

    dataAux = tratamentoDadosSb(dataAuxEstoque, dataAuxVendas);
    const { response, titles, dataSet1, dataSetQ, dataSetTM } =
      functionGraficsTables.manipulacaoDadosTabelasPreencher(dataAux);
    // setDataBase({ ...dataBase, dataLojasSg: dataAux })
    setEstoque(response);
    setLabelsBar({
      ...labelsBar,
      dataSet1: titles,
      dataSetQtd: titles,
      dataSet3: titles,
    });
    setDataSet(dataSet1);
    setDataSetQtd(dataSetQ);
    setDataSet3(dataSetTM);
    setDadosAuxiliar({ ...dadosAuxiliar, dadosEstoque: response });
  };
  const dadosLojasSelecionadas = (data, lojasSelecionadas) => {
    const {
      dadosEstoque,
      dadosVenda,
      dadosEstoqueTransito,
      estoqueAguardandoEntrega,
    } = data;
    let dataAux = [],
      dataAuxVendas = [],
      dataAuxEstoque = [],
      dataAuxEstoqueTransito = [],
      dataAuxEstoqueAguardandoEntrega = [];
    lojasSelecionadas.forEach((item) => {
      dadosVenda.filter((event) => {
        if (event.CodLoja === item.replace(/[^0-9]/g, "")) {
          dataAuxVendas.push(event);
          return event;
        }
      });
    });
    lojasSelecionadas.forEach((item) => {
      dadosEstoque.filter((event) => {
        if (event.CodLoja === item.replace(/[^0-9]/g, "")) {
          dataAuxEstoque.push(event);
          return event;
        }
      });
    });
    lojasSelecionadas.forEach((item) => {
      dadosEstoqueTransito.filter((event) => {
        if (event.CodLoja === item.replace(/[^0-9]/g, "")) {
          dataAuxEstoqueTransito.push(event);
          return event;
        }
      });
    });

    // lojasSelecionadas.forEach(item => {
    estoqueAguardandoEntrega.filter((event) => {
      // if (event.CodLoja === item.replace(/[^0-9]/g, '')) {
      dataAuxEstoqueAguardandoEntrega.push(event);
      // return event
      // }
    });
    // })

    dataAux = tratamentoDados(
      dataAuxEstoque,
      dataAuxVendas,
      dataAuxEstoqueTransito,
      dataAuxEstoqueAguardandoEntrega
    );
    const { response, titles, dataSet1, dataSetQ, dataSetTM } =
      functionGraficsTables.manipulacaoDadosTabelasPreencher(dataAux);
    setDataBase({ ...dataBase, dadosGerais: dataAux });
    setEstoque(response);
    setLabelsBar({
      ...labelsBar,
      dataSet1: titles,
      dataSetQtd: titles,
      dataSet3: titles,
    });
    setDataSet(dataSet1);
    setDataSetQtd(dataSetQ);
    setDataSet3(dataSetTM);
    setDadosAuxiliar({ ...dadosAuxiliar, dadosEstoque: response });
  };
  return (
    <>
      <div className="container-app">
        {PermissionsPage("estoqueMes") && (
          <Container>
            <div className="container-conteudo">
              <Container className="containerFlexBox">
                <Title title="ESTOQUE MÊS" />
                <FilterBases
                  basesDisponiveis={basesDisponiveis}
                  basesSelecionadas={basesSelecionadas}
                  filtrarBases={filtrarBases}
                />
              </Container>
              {isLoading ? (
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"90vh"}
                >
                  <LoadingTable isLoading={isLoading} />
                </Box>
              ) : (
                <Container>
                  <ContainerCenterBotoes>
                    <div>
                      {/* <p>Click para exibir os graficos</p> */}
                      <section>
                        <Form.Select
                          onChange={(e) => {
                            setEstoque(dadosAuxiliar?.dadosEstoque);
                            setRadioValue(e.currentTarget.value);
                          }}
                        >
                          {radios.map((radio, idx) => {
                            return (
                              <option
                                key={idx}
                                id={`radio-${idx}`}
                                // type="radio"
                                // variant='outline-secondary'
                                name="radio"
                                value={radio.value}
                                checked={radioValue === radio.value}
                              >
                                {radio.name}
                              </option>
                            );
                          })}
                        </Form.Select>
                      </section>
                    </div>
                    <div>
                      <FilterLojaSimples
                        lojasDisponiveis={lojasDisponiveis}
                        filtrosLojasSelecionadas={filtrosLojasSelecionadas}
                        filtrarPorLoja={filtrarPorLoja}
                      />
                    </div>
                  </ContainerCenterBotoes>
                  <ContainerTipoGrupo>
                    <p>{dadosAuxiliar.tipoGrupo}</p>
                  </ContainerTipoGrupo>
                  {(dataSet || dataSet3 || dataSetQtd) &&
                    graficos.length > 0 &&
                    graficos.map((ev) => {
                      if (radioValue === ev.radioValue) {
                        return (
                          <ContainerGrafico>
                            <HorizontalBar
                              ref={chartRef}
                              id={ev.id}
                              data={ev.data}
                              options={ev.options}
                              height={ev.height}
                              getElementAtEvent={ev.getElementAtEvent}
                            />
                            <Button
                              variant="outline-secondary"
                              onClick={ev.button.onClick}
                              name={ev.button.name}
                            >
                              Voltar
                            </Button>
                          </ContainerGrafico>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  <Tabs
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className=""
                  >
                    <Tab eventKey="home" title="Tabela Grupos">
                      {estoque && (
                        <Row className="wrapper">
                          <TabelaNext
                            dadosParaTabela={estoque?.length > 0 ? estoque : []}
                            colunas={colunasEstoqueMes(
                              estoque?.length > 0 ? estoque : []
                            )}
                          />
                        </Row>
                      )}
                    </Tab>
                    <Tab
                      eventKey="profile"
                      title="Todos Subgrupos"
                      disabled={
                        dadosAuxiliar?.todosSubGrupos?.length > 0 ? false : true
                      }
                    >
                      <TabelaNext
                        dadosParaTabela={
                          dadosAuxiliar?.todosSubGrupos?.length > 0
                            ? dadosAuxiliar?.todosSubGrupos
                            : []
                        }
                        colunas={colunasEstoqueMes(
                          dadosAuxiliar?.todosSubGrupos?.length > 0
                            ? dadosAuxiliar?.todosSubGrupos
                            : []
                        )}
                      />
                    </Tab>
                  </Tabs>
                </Container>
              )}
            </div>
          </Container>
        )}
      </div>
    </>
  );
}

export default EstoqueMes;
