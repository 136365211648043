import { storageStoresGet } from "../storage/storageStores";

export const converterLoja = (codLoja) => {
  let loja = codLoja;
  let data = storageStoresGet();

  if (data?.length > 0) {
    data.forEach((item) => {
      if (+item.id === +codLoja) {
        loja = item.id + " " + item.sigla;
      }
    });
  }
  return loja;
};

export const converterBase = (codBase) => {
  let base = codBase;
  let data = storageStoresGet();

  if (data?.length > 0) {
    data.forEach((item) => {
      if (+item.id === +codBase) {
        base = item.id + " " + item.sigla;
      }
    });
  }
  return base;
};

export const fAnosPorLoja = (nomeLoja) => {
  let idLoja,
    novosAnos = [],
    data = localStorage.getItem("anosPorLoja");
  data = JSON.parse(data);
  nomeLoja.map((ev) => {
    idLoja = converterNomeLoja(ev);
    data.map((elmt) => {
      if (elmt.Codloja === idLoja) {
        novosAnos.push(elmt.ano);
      }
    });
  });
  novosAnos.sort().reverse();
  if (nomeLoja.length === 1) {
    return novosAnos;
  }
  let novosAnosCount = {},
    duplicate = [];
  novosAnos.forEach((element) => {
    novosAnosCount[element] = novosAnosCount[element]
      ? novosAnosCount[element] + 1
      : 1;
  });
  Object.keys(novosAnosCount).map((evt) => {
    if (novosAnosCount[evt] === nomeLoja.length) {
      duplicate.push(evt);
    }
  });
  return duplicate;
};

export const fAnosDisponiveis = (data) => {
  let anos = [];
  if (data.anosPorLoja) {
    localStorage.setItem("anosPorLoja", JSON.stringify(data.anosPorLoja));
  }
  if (data.anos) {
    data.anos.map((item) => {
      anos.push(item.ano);
    });
    anos.reverse();
  } else {
    data.map((item) => {
      anos.push(item.ano);
      anos.sort().reverse();
    });
  }
  return anos;
};

export function agruparPorQualidade(array, key2 = null, key3 = "EstoqueAtual") {
  const key1 = "EtapaFunil";
  let result = {};

  array.map((item, index) => {
    const key1Value = item[key1];
    const key2Value = item[key2];

    if (!result[key1Value]) {
      result[key1Value] = {
        id: index,
        titulo: key1Value,
        EstoqueAtual: 0,
        PrecoVenda: 0,
        TotalMonetario: 0,
        products: [],
        agrupamento: {},
      };
    }
    if (key2) {
      if (!result[key1Value].agrupamento[key2Value]) {
        result[key1Value].agrupamento[key2Value] = {
          indicador: key2Value,
          EstoqueAtual: 0,
          PrecoVenda: 0,
          TotalMonetario: 0,
          products: [],
        };
      }
      result[key1Value].agrupamento[key2Value].EstoqueAtual += parseFloat(
        item.EstoqueAtual
      );
      result[key1Value].agrupamento[key2Value].PrecoVenda += parseFloat(
        item.PrecoVenda
      );
      result[key1Value].agrupamento[key2Value].TotalMonetario += parseFloat(
        item.TotalMonetario
      );
      result[key1Value].agrupamento[key2Value].products.push(item);
    }

    result[key1Value].EstoqueAtual += parseFloat(item.EstoqueAtual);
    result[key1Value].PrecoVenda += parseFloat(item.PrecoVenda);
    result[key1Value].TotalMonetario += parseFloat(item.TotalMonetario);
    result[key1Value].products.push(item);
  });

  let total = array.reduce((sum, item) => sum + parseFloat(item[key3]), 0);

  return Object.values(result).map((item) => {
    item.percentage = `${((item[key3] / total) * 100).toFixed(2)}%`;
    item.agrupamento = Object.values(item.agrupamento).map((agrupamento) => {
      agrupamento.percentage = `${(
        (agrupamento[key3] / item[key3]) *
        100
      ).toFixed(2)}%`;
      return agrupamento;
    });
    return item;
  });
}

export function agruparPor(objetoArray, propriedade) {
  return objetoArray.reduce(function (acc, obj) {
    let key = obj[propriedade];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}
export const fLojas = () => {
  let data = localStorage.getItem("siglasLojasCadastradas");
  data = JSON.parse(data);
  let idLojas = [];
  data.map((item) => {
    if (item.id !== 10) {
      idLojas.push(item.id);
    }
  });
  return idLojas;
};
export const fObjetoCompletoLojas = () => {
  let lojas = [];
  let data = localStorage.getItem("siglasLojasCadastradas");
  data = JSON.parse(data);
  if (data?.length > 0) {
    data.forEach((item) => {
      lojas.push(item);
    });
  }
  return lojas;
};
export function converterNomeLoja(nomeLoja) {
  let loja = nomeLoja;
  let data = storageStoresGet();

  if (data?.length > 0) {
    data.forEach((item) => {
      if (item.id + " " + item.sigla === nomeLoja) {
        loja = item.id;
      }
    });
  }
  return loja;
}
export const arrayBases = (data) => {
  let basesDisponiveisAux = [];
  data.forEach((a) => {
    if (a.response) {
      basesDisponiveisAux.push({ codBase: a.base, base: "Base " + a.base });
    } else {
      if (!isNaN(parseInt(a))) {
        basesDisponiveisAux.push({ codBase: a, base: "Base " + a });
      }
    }
  });
  return basesDisponiveisAux;
};
export const converterMes = (mes) => {
  let meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
  let nomeMes = "";
  meses.map((item, index) => {
    if (index + 1 === mes) {
      return (nomeMes = item);
    }
  });
  return nomeMes;
};
export function formatarNumero(numero, money = true, decimal = 2) {
  if (money) {
    return numero.toLocaleString("pt-BR", {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
      style: "currency",
      currency: "BRL",
    });
  } else {
    return numero.toLocaleString("pt-BR", {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  }
}

export function formatarNumeroCompleto(
  numero,
  money = true,
  decimal = 2,
  sufixo = false,
  validar = false,
  apenasValidar = false
) {
  if (!numero) {
    numero = "0";
  }
  if (apenasValidar) {
    if (numero[0] === ".") {
      numero = "0" + numero;
      numero = parseFloat(numero);
    } else {
      numero = parseFloat(numero);
    }
    return numero;
  }
  if (validar) {
    if (numero[0] === ".") {
      numero = "0" + numero;
      numero = parseFloat(numero);
    } else {
      numero = parseFloat(numero);
    }
  }
  if (money) {
    if (sufixo) {
      if (numero > 1000000) {
        numero = numero / 1000000;
        return (
          numero.toLocaleString("pt-BR", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
            style: "currency",
            currency: "BRL",
          }) + " Mi"
        );
      } else {
        numero = numero / 1000;
        return (
          numero.toLocaleString("pt-BR", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
            style: "currency",
            currency: "BRL",
          }) + " Mil"
        );
      }
    } else {
      return numero.toLocaleString("pt-BR", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
        style: "currency",
        currency: "BRL",
      });
    }
  } else {
    if (sufixo) {
      if (numero > 1000000) {
        numero = numero / 1000000;
        return (
          numero.toLocaleString("pt-BR", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
          }) + " Mi"
        );
      } else {
        numero = numero / 1000;
        return (
          numero.toLocaleString("pt-BR", {
            minimumFractionDigits: decimal,
            maximumFractionDigits: decimal,
          }) + " Mil"
        );
      }
    } else {
      return numero.toLocaleString("pt-BR", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
    }
  }
}

export const converterNumero = (numero) => {
  if (!numero) {
    return numero;
  }
  if (numero > 1000) {
    return numero?.toLocaleString("pt-BR", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      style: "currency",
      currency: "BRL",
    });
  }
  return numero?.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  });
};
export const converterDia = (diaSemana) => {
  let nomeDia = "",
    semana = [
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ];
  semana.map((item, index) => {
    if (diaSemana === index + 1) {
      nomeDia = item;
    }
  });
  return nomeDia;
};
export function converterDataFormatoPadrao(hoje) {
  var ano = hoje.getFullYear();
  var mes = hoje.getMonth() + 1;
  var dia = hoje.getDate();
  if (mes < 10) {
    mes = "0" + mes;
  }
  if (dia < 10) {
    dia = "0" + dia;
  }
  var inicioAno = ano + "-" + mes + "-01";
  var hoje = ano + "-" + mes + "-" + dia;
  return [inicioAno, hoje];
}
export function converterData(data) {
  var ano = data.getFullYear();
  var mes = data.getMonth() + 1;
  var dia = data.getDate();
  if (mes < 10) {
    mes = "0" + mes;
  }
  if (dia < 10) {
    dia = "0" + dia;
  }
  var inicioAno = "01/" + mes + "/" + ano;
  var hoje = dia + "/" + mes + "/" + ano;
  return { inicioAno, hoje };
}

export const tratamentoDados = (
  dadosEstoque,
  dadosVenda,
  dataAuxEstoqueTransito,
  estoqueAguardandoEntrega
) => {
  let dadosAux = [],
    dadosGeraisVendas = {},
    dadosGeraisEstoque = {},
    dadosGeraisEstoqueTransito = {},
    dadosGeraisEstoqueAguardandoEntrega = {};
  dadosGeraisVendas = agruparPor(dadosVenda, "CodGrp1");
  dadosGeraisEstoque = agruparPor(dadosEstoque, "CodGrp1");
  dadosGeraisEstoqueTransito = agruparPor(dataAuxEstoqueTransito, "CodGrp1");
  dadosGeraisEstoqueAguardandoEntrega = agruparPor(
    estoqueAguardandoEntrega,
    "CodGrp1"
  );
  dadosGeraisVendas = Object.keys(dadosGeraisVendas).map((item) => {
    let custoVendas = 0,
      qtdVendas = 0;
    dadosGeraisVendas[item].forEach((event) => {
      custoVendas += parseFloat(event.custoVendas)
        ? parseFloat(event.custoVendas)
        : 0;
      qtdVendas += parseFloat(event.qtdVendas)
        ? parseFloat(event.qtdVendas)
        : 0;
    });
    return {
      CodGrp1: dadosGeraisVendas[item][0].CodGrp1,
      NomeGrupo1: dadosGeraisVendas[item][0].NomeGrupo1,
      custoVendas: custoVendas,
      qtdVendas: qtdVendas,
    };
  });
  dadosGeraisEstoque = Object.keys(dadosGeraisEstoque).map((item) => {
    let custoEstoque = 0,
      qtdEstoque = 0;
    dadosGeraisEstoque[item].forEach((event) => {
      custoEstoque += parseFloat(event.custoEstoque)
        ? parseFloat(event.custoEstoque)
        : 0;
      qtdEstoque += parseFloat(event.qtdEstoque)
        ? parseFloat(event.qtdEstoque)
        : 0;
    });
    return {
      CodGrp1: dadosGeraisEstoque[item][0].CodGrp1,
      NomeGrupo1: dadosGeraisEstoque[item][0].NomeGrupo1,
      custoEstoque: custoEstoque,
      qtdEstoque: qtdEstoque,
    };
  });

  dadosGeraisEstoqueTransito = Object.keys(dadosGeraisEstoqueTransito).map(
    (item) => {
      let custoEstoque = 0,
        qtdEstoque = 0;
      dadosGeraisEstoqueTransito[item].forEach((event) => {
        custoEstoque += parseFloat(event.custoEstoque)
          ? parseFloat(event.custoEstoque)
          : 0;
        qtdEstoque += parseFloat(event.qtdEstoque)
          ? parseFloat(event.qtdEstoque)
          : 0;
      });
      return {
        CodGrp1: dadosGeraisEstoqueTransito[item][0].CodGrp1,
        NomeGrupo1: dadosGeraisEstoqueTransito[item][0].NomeGrupo1,
        custoEstoque: custoEstoque,
        qtdEstoque: qtdEstoque,
      };
    }
  );

  dadosGeraisEstoqueAguardandoEntrega = Object.keys(
    dadosGeraisEstoqueAguardandoEntrega
  ).map((item) => {
    let custoEstoque = 0,
      qtdEstoque = 0;
    dadosGeraisEstoqueAguardandoEntrega[item].forEach((event) => {
      custoEstoque += parseFloat(event.custoEstoque)
        ? parseFloat(event.custoEstoque)
        : 0;
      qtdEstoque += parseFloat(event.qtdEstoque)
        ? parseFloat(event.qtdEstoque)
        : 0;
    });
    return {
      CodGrp1: dadosGeraisEstoqueAguardandoEntrega[item][0].CodGrp1,
      NomeGrupo1: dadosGeraisEstoqueAguardandoEntrega[item][0].NomeGrupo1,
      custoEstoque: custoEstoque,
      qtdEstoque: qtdEstoque,
    };
  });

  if (dadosGeraisVendas.length === 0 && dadosGeraisEstoque) {
    dadosGeraisEstoque.map((element) => {
      const QtdEstoqueTransito = dadosGeraisEstoqueTransito?.find(
        (item) => item.CodGrp1 === element.CodGrp1 && element.CodGrp1 !== "7"
      );
      const QtdEstoqueAguardandoEntrega =
        dadosGeraisEstoqueAguardandoEntrega?.find(
          (item) => item.CodGrp1 === element.CodGrp1 && element.CodGrp1 !== "7"
        );
      if (QtdEstoqueTransito) {
        element.qtdEstoqueTransito = QtdEstoqueTransito?.qtdEstoque;
        element.custoEstoqueTransito = QtdEstoqueTransito?.custoEstoque;
      }
      if (QtdEstoqueAguardandoEntrega) {
        element.qtdEstoqueAguardandoEntrega =
          QtdEstoqueAguardandoEntrega?.qtdEstoque;
        element.custoEstoqueAguardandoEntrega =
          QtdEstoqueAguardandoEntrega?.custoEstoque;
      }
      if (element.CodGrp1 !== "7") {
        dadosAux.push({
          ...element,
          NomeGrupo: element.NomeGrupo1,
          CustoVendas: 0,
          QtdVendas: 0,
          custoEstoque: element.custoEstoque,
          qtdEstoque: element.qtdEstoque,
        });
      }
    });
  } else {
    dadosGeraisEstoque.map((element) => {
      const QtdEstoqueTransito = dadosGeraisEstoqueTransito?.find(
        (item) => item.CodGrp1 === element.CodGrp1 && element.CodGrp1 !== "7"
      );
      const QtdEstoqueAguardandoEntrega =
        dadosGeraisEstoqueAguardandoEntrega?.find(
          (item) => item.CodGrp1 === element.CodGrp1 && element.CodGrp1 !== "7"
        );
      if (QtdEstoqueTransito) {
        element.qtdEstoqueTransito = QtdEstoqueTransito?.qtdEstoque;
        element.custoEstoqueTransito = QtdEstoqueTransito?.custoEstoque;
      }
      if (QtdEstoqueAguardandoEntrega) {
        element.qtdEstoqueAguardandoEntrega =
          QtdEstoqueAguardandoEntrega?.qtdEstoque;
        element.custoEstoqueAguardandoEntrega =
          QtdEstoqueAguardandoEntrega?.custoEstoque;
      }
      dadosGeraisVendas.map((ev) => {
        if (element.CodGrp1 === ev.CodGrp1 && element.CodGrp1 !== "7") {
          dadosAux.push({
            ...element,
            NomeGrupo: ev.NomeGrupo1,
            CustoVendas: ev.custoVendas,
            QtdVendas: ev.qtdVendas,
          });
        }
      });
    });
  }
  dadosAux = dadosAux.sort((a, b) =>
    a.custoEstoque > b.custoEstoque
      ? -1
      : a.custoEstoque < b.custoEstoque
      ? 1
      : 0
  );
  return dadosAux;
};
export const tratamentoDadosSb = (dadosEstoque, dadosVenda) => {
  let dadosAux = [],
    dadosGeraisVendas = {},
    dadosGeraisEstoque = {};
  dadosGeraisVendas = agruparPor(dadosVenda, "codSubGp");
  dadosGeraisEstoque = agruparPor(dadosEstoque, "codSubGp");
  dadosGeraisVendas = Object.keys(dadosGeraisVendas).map((item) => {
    let custoVendas = 0,
      qtdVendas = 0;
    dadosGeraisVendas[item].forEach((event) => {
      custoVendas += parseFloat(event.custoVendas)
        ? parseFloat(event.custoVendas)
        : 0;
      qtdVendas += parseFloat(event.qtdVendas)
        ? parseFloat(event.qtdVendas)
        : 0;
    });
    return {
      codSubGp: dadosGeraisVendas[item][0].codSubGp,
      NomeGrupo: dadosGeraisVendas[item][0].NomeGrupo,
      custoVendas: custoVendas,
      qtdVendas: qtdVendas,
    };
  });
  dadosGeraisEstoque = Object.keys(dadosGeraisEstoque).map((item) => {
    let custoEstoque = 0,
      qtdEstoque = 0;
    dadosGeraisEstoque[item].forEach((event) => {
      custoEstoque += parseFloat(event.custoEstoque)
        ? parseFloat(event.custoEstoque)
        : 0;
      qtdEstoque += parseFloat(event.qtdEstoque)
        ? parseFloat(event.qtdEstoque)
        : 0;
    });
    return {
      codSubGp: dadosGeraisEstoque[item][0].codSubGp,
      NomeGrupo: dadosGeraisEstoque[item][0].NomeGrupo,
      custoEstoque: custoEstoque,
      qtdEstoque: qtdEstoque,
    };
  });

  if (dadosGeraisVendas.length === 0 && dadosGeraisEstoque) {
    dadosGeraisEstoque = dadosGeraisEstoque.sort((a, b) =>
      a.custoEstoque > b.custoEstoque
        ? -1
        : a.custoEstoque < b.custoEstoque
        ? 1
        : 0
    );
    dadosGeraisEstoque = dadosGeraisEstoque.filter((item, index) => {
      if (index < 15) {
        return item;
      }
    });
    dadosGeraisEstoque.map((element) => {
      dadosAux.push({
        codSubGp: element.codSubGp,
        NomeGrupo: element.NomeGrupo,
        CustoVendas: 0,
        QtdVendas: 0,
        custoEstoque: element.custoEstoque,
        qtdEstoque: element.qtdEstoque,
      });
    });
  } else {
    let arrayMesclado = dadosGeraisEstoque.map((itemEstoque) => {
      const itemVenda = dadosGeraisVendas.find(
        (itemVendas) => itemVendas.codSubGp === itemEstoque.codSubGp
      );
      if (itemVenda) {
        return {
          ...itemEstoque,
          NomeGrupo: itemVenda.NomeGrupo,
          CustoVendas: itemVenda.custoVendas,
          QtdVendas: itemVenda.qtdVendas,
        };
      }
      return {
        ...itemEstoque,
        CustoVendas: 0,
        QtdVendas: 0,
      };
    });
    arrayMesclado = arrayMesclado.sort((a, b) =>
      a.custoEstoque > b.custoEstoque
        ? -1
        : a.custoEstoque < b.custoEstoque
        ? 1
        : 0
    );
    arrayMesclado = arrayMesclado.filter((item, index) => {
      if (index < 15) {
        return item;
      }
    });

    return arrayMesclado;
  }

  return dadosAux;
};
export const preencherPeriodo = () => {
  let dataHoje = new Date();
  let diaSemana = dataHoje.getDay();
  let [inicioAno, hoje] = converterDataFormatoPadrao(dataHoje);
  // let { inicioAno: inicioAnoTexto, hoje: hojeTexto } = converterData(dataHoje);

  return { inicio: inicioAno, fim: hoje, ano: false, mes: "", diaSemana };
};
