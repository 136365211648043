import React, { useEffect, useRef, useState } from "react";

import "chartjs-plugin-datalabels";
import Title from "../../components/title";
import { api as apiv2 } from "../../services/api.v2";
import "react-data-table-component-extensions/dist/index.css";
import Funnel2 from "../../components/Funnel2";
import { Row, Col } from "react-bootstrap";
import { arrayBases } from "../../util/loja";

import { TituloTabela } from "../../components/TituloTabela/TituloTabela";
import { ContainerTudo } from "../../styles/global";

import toast from "react-hot-toast";
import { PermissionsPage } from "../../util/permissionsPage";
import { useUserData } from "../../hooks/useUserData";
import { DatePikerFilter } from "../../components/DatePikerFilter/DatePikerFilter";
import {
  Box,
  Paper,
  Stack,
  Tab,
  Tabs,
  Container as ContainerMui,
} from "@mui/material";
import { MultiSelectBase } from "../../components/MultiSelect/MultiSelectBase/MultiSelectBase";
import { MultiSelectLojas } from "../../components/MultiSelect/MultiSelectLojas/MultiSelectLojas";
import dayjs from "dayjs";
import { formatShopAvailable } from "../../formatdata/formatter-shop-available";
import { NoData } from "../../components/NoData/NoData";
import { LoadingTable } from "../../components/Loading/LoadingTable";

import { GraficoPizza } from "../../components/componentsAnaliseClientes/GraficoPizza";
import { a11yProps } from "../../util/a11yProps";
import { CustomTabPanel } from "../../components/componentsAnaliseClientes/CustomTabPanel";
import DataTable, { defaultThemes } from "react-data-table-component";
import { columnsTable } from "../../column/analise-clientes/analise-clientes";
import { Legenda } from "../../components/componentsAnaliseClientes/Legenda";
import {
  ButtonFilter,
  ButtonToday,
} from "../../components/DatePikerFilter/styles";

const customStyles = {
  header: {
    style: {
      minHeight: "56px",
    },
  },
  headRow: {
    style: {
      borderTopStyle: "solid",
      borderTopWidth: "1px",
      borderTopColor: defaultThemes.default.divider.default,
    },
  },
  headCells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
  cells: {
    style: {
      "&:not(:last-of-type)": {
        borderRightStyle: "solid",
        borderRightWidth: "1px",
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};

function AnaliseClientes() {
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [dataPage, setDataPage] = useState({
    total_shopping: [],
    total_first_purchase: [],
    count_customers_first_purchase: 0,
    count_customers_shopping: 0,
    count_sales_without_customer_registered: 0,
    subtitles: {
      an: { start: "", end: "" },
      at: { start: "", end: "" },
      pa: { start: "", end: "" },
    },
  });

  const [, setIsLoading] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(true);

  const { userData } = useUserData();

  const [filterShop, setFilterShop] = useState([]);
  const accessLevel = userData?.nivelAcesso || [];
  const acessBase = userData?.baseAcesso;
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const firstDayOfMonth = dayjs().startOf("month").format("YYYY-MM-DD");
  const currencyDay = dayjs().format("YYYY-MM-DD");
  const [paramsConfig, setParamsConfig] = useState({
    base: [],
    first_period: firstDayOfMonth,
    final_period: currencyDay,
    stores: accessLevel,
  });
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (!!userData) {
      buscarNivelAcesso();
    }
  }, [userData]);

  const timerRef = useRef();

  const buscarNivelAcesso = () => {
    if (!!accessLevel && !!acessBase) {
      const bases = arrayBases(acessBase);
      setBasesDisponiveis(bases);
      const fristBase = bases.find((item) => item.codBase);
      const selectBaseInitial = [fristBase].map((item) => item.codBase);
      const shopsAvailableForBase = formatShopAvailable(
        selectBaseInitial,
        accessLevel
      );
      setLojasDisponiveis(accessLevel);
      setParamsConfig((old) => ({
        ...old,
        base: selectBaseInitial,
        stores: shopsAvailableForBase.map((item) => item.value),
      }));
      setFilterShop(shopsAvailableForBase);
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (paramsConfig.base.length <= 0) return;
    preencherDados();
  }, [basesDisponiveis]);

  const onChangeMultiSelect = (value, name) => {
    const shopsAvailableForBase = formatShopAvailable(
      value.map((item) => item.value),
      accessLevel
    );
    let filterId = value.map((val) => val.value);
    setFilterShop(shopsAvailableForBase);
    setParamsConfig((old) => ({
      ...old,
      [name]: filterId,
      stores: shopsAvailableForBase.map((item) => item.value),
    }));
  };
  const onChangeDatePicker = (value) => {
    setParamsConfig((old) => ({ ...old, ...value }));
  };

  const handleMultiSelectFilterShop = (value) => {
    setFilterShop(value);
    setParamsConfig((old) => ({
      ...old,
      stores: value.map((item) => item.value),
    }));
  };

  async function preencherDados() {
    if (paramsConfig.stores.length === 0) {
      toast.error("Selecione uma loja");
      return;
    }
    setIsLoadingData(true);
    if (timerRef.current) {
      timerRef.current.style.opacity = 0;
    }

    // window.scrollTo(0, 0);
    const toastId = toast.loading("Buscando dados...");

    try {
      const response = await apiv2.get("/sales/customer-analysis", {
        params: {
          ...paramsConfig,
        },
      });
      if (response.status === 200) {
        const data = response.data;

        setDataPage(data);
        toast.success("Dados recebidos", {
          duration: 5000,
          id: toastId,
        });
      } else {
        toast.error("Ocorreu um erro ao carregar, por favor tente novamente", {
          id: toastId,
        });
      }
    } catch (error) {
      toast.error(error, {
        id: toastId,
      });
    }

    setIsLoadingData(false);
  }
  const handleFilter = () => {
    preencherDados();
  };

  const handleClearFilter = () => {
    buscarNivelAcesso();
  };

  return (
    <>
      <>
        {PermissionsPage("analiseClientes") && (
          <>
            <Title title="Análise de Clientes" />
            <Box
              mx={2}
              sx={{
                mt: 1,
              }}
            >
              <Stack direction={"row"} gap={2} mb={2}>
                <DatePikerFilter
                  paramsConfig={paramsConfig}
                  onChange={onChangeDatePicker}
                  isLoading={isLoadingData}
                  showButtonFilter={false}
                />
                <Box>
                  <Stack gap={2}>
                    <MultiSelectBase
                      bases={basesDisponiveis}
                      onChange={onChangeMultiSelect}
                      paramsConfig={paramsConfig}
                      isLoading={isLoadingData}
                    />

                    <MultiSelectLojas
                      lojas={lojasDisponiveis}
                      onChange={handleMultiSelectFilterShop}
                      paramsConfig={paramsConfig}
                      isLoading={isLoadingData}
                      shops={filterShop}
                    />
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    mb={2}
                  >
                    <Stack direction={"row"} spacing={1} mt={2}>
                      <ButtonFilter
                        style={{
                          width: "fit-content",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                        onClick={handleClearFilter}
                        disabled={isLoadingData}
                      >
                        LIMPAR FILTROS
                      </ButtonFilter>
                      <ButtonToday
                        style={{
                          width: "fit-content",
                          fontSize: ".8rem",
                          fontWeight: "500",
                        }}
                        onClick={handleFilter}
                        disabled={isLoadingData}
                      >
                        FILTRAR
                      </ButtonToday>
                    </Stack>
                  </Stack>
                </Box>
              </Stack>
              {isLoadingData && <LoadingTable isLoading={isLoadingData} />}

              {!isLoadingData && (
                <>
                  <Box
                    sx={{ width: "100%" }}
                    px={2}
                    component={Paper}
                    variant="outlined"
                    elevation={1}
                  >
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={value}
                        onChange={handleChange}
                        sx={{
                          "& .MuiTabs-flexContainer": {
                            width: "100%",
                            justifyContent: "center",
                          },
                        }}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Funil" {...a11yProps(0)} />
                        <Tab label="Pizza" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={value} index={0}>
                      <Box px={5}>
                        {dataPage.total_shopping.length > 0 && (
                          <Funnel2 dataPage={dataPage} />
                        )}
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                      <Box
                        sx={{
                          display: ["grid", "grid", "flex"],
                          width: "100%",
                        }}
                      >
                        <GraficoPizza dataPage={dataPage} />
                      </Box>
                    </CustomTabPanel>
                  </Box>
                  <Box
                    component={Paper}
                    my={3}
                    variant="outlined"
                    elevation={1}
                  >
                    <TituloTabela>Clientes Por Loja</TituloTabela>
                    <DataTable
                      dense
                      noDataComponent={NoData}
                      columns={columnsTable}
                      customStyles={customStyles}
                      data={dataPage.total_shopping}
                    />
                  </Box>
                  <Legenda subtitles={dataPage.subtitles} />
                </>
              )}
            </Box>
          </>
        )}
      </>
    </>
  );
}

export default AnaliseClientes;
