import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { api, registerInterceptTokenManager } from "../services/api.v2";
import queryString from "query-string";
import {
  storageAccessTokenGet,
  storageAccessTokenSave,
} from "../storage/storageAuthToken";
import { storageStoresSave } from "../storage/storageStores";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const history = useHistory();

  const [isLoadingUserData, setIsLoadingUserData] = useState(true);

  const singOut = () => {
    storageAccessTokenSave(null);
    if (window.location.pathname !== "/401") {
      window.location.href = "/401";
    }
  };

  useEffect(() => {
    const removeInterceptor = registerInterceptTokenManager(singOut);

    return () => {
      removeInterceptor();
    };
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    //    const accessToken = params.get("access_token");
    const accessToken =
      //"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IlRJQUdPIiwic3ViIjoxMzYsImlhdCI6MTcxMDI2NzM1MywiZXhwIjoxNzEyODU5MzUzfQ.0BV7gbOER1G_XJdogEH49XwxZMHPgPITenNagnvPF1s";
      //"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IldMT1lPTEEiLCJzdWIiOjU3MywiaWF0IjoxNzExNzI0MjcwLCJleHAiOjE3MTQzMTYyNzB9.PpPFp4NH5KxX0oBXb04QwpNSXTww5JGXq9NXx7Gl24g";
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IldMT1lPTEEiLCJzdWIiOjU3MywiaWF0IjoxNzExNzI4NDU5LCJleHAiOjE3MTQzMjA0NTl9.Vu4PUSeH6-D6LkGStwPuNijoC1Ef0V6b9L2RbbmhUqw";
    const setAccessToken = async () => {
      try {
        if (!!accessToken) {
          storageAccessTokenSave(accessToken);
          const newSearch = queryString.stringify({});
          history.replace({ search: newSearch });
          setIsLoadingUserData(false);
        } else {
          storageAccessTokenGet();
        }
      } catch (error) {}
    };

    const setAvailableStores = async () => {
      try {
        const response = await api.get("/base/available-stores");
        if (response.status === 200) {
          storageStoresSave(response.data);
        }
      } catch (error) {
        console.log({ error });
      }
    };

    setAccessToken();
    setAvailableStores();
  }, []);

  return (
    <AuthContext.Provider value={{ isLoadingUserData, singOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
