import { apiProd } from "./api";
import { api } from "./api.v2";

export const vendasService = {
  listaVendaLojas: async (data) => {
    const response = apiProd
      .get("/bi/vendas/vendas-por-loja.php", {
        params: { entityData: data },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return response;
  },
  listaVendasGrupo: async (data) => {
    const response = apiProd
      .get("/bi/vendas/vendas-por-grupo.php", {
        params: { entityData: data },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return response;
  },
  listaVendasFornecedor: async (data) => {
    console.log("ListaVendasFornecedor: ", data);
    const response = apiProd
      .get("/bi/vendas/vendas-por-fornecedor.php", {
        params: { entityData: data },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return response;
  },
  listaVendasDiaSemana: async (data) => {
    const response = apiProd
      .get("/bi/vendas/vendas-por-dia.php", {
        params: { entityData: data },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return response;
  },
  listaVendasDesconto: async (data) => {
    const response = apiProd
      .get("/bi/vendas/vendas-desconto.php", {
        params: { entityData: data },
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return response;
  },
  listaVendasPorVendedorGrupo: async (params) => {
    const response = api
      .get("/sales/sales-by-seller-group", {
        params,
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return error;
      });
    return response;
  },
};
