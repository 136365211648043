import { Stack, Box, Skeleton, Button } from "@mui/material";
import Select from "react-select";

import React, { useEffect, useState } from "react";
import "chartjs-plugin-datalabels";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";

import { CardBase } from "../../../components/componentsListaMetas/CardBase";
import listaMetas from "../../../services/listaMetas";
import ErrorToastHandler from "../../../util/ErrorToastHandler";
import Title from "../../../components/title";
import { useHistory } from "react-router-dom";

export function ListarMetas() {
  const [periodosDisponiveis, setPeriodosDisponiveis] = useState([]);
  const [params, setParams] = useState({
    periodo: null,
  });
  const [metasDetalhadasAtivas, setMetasDetalhadasAtivas] = useState([]);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const getDataPage = async () => {
    setIsLoading(true);
    try {
      const response = await listaMetas.getListGoals({
        periodo: params.periodo?.value,
      });

      setMetasDetalhadasAtivas(response.data?.dadosDetalhadosAtivos);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };
  const getAvailablePeriods = async () => {
    try {
      const response = await listaMetas.getListPeriods();
      const periodos = response.data.map((item) => ({
        value: item.id,
        label: item.periodo,
      }));
      setParams((old) => ({
        ...old,
        periodo: periodos.find((item) => !!item),
      }));
      setPeriodosDisponiveis(periodos);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    }
  };
  useEffect(() => {
    getAvailablePeriods();
  }, []);
  useEffect(() => {
    if (periodosDisponiveis.length > 0) {
      getDataPage();
    }
  }, [periodosDisponiveis, params]);
  return (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Title title={"LISTAGEM DE METAS POR BASE"} />
        <Button
          onClick={() => {
            history.push("/financeiro/metas/cadastro");
          }}
          size="small"
          variant="outlined"
          sx={{
            height: "fit-content",
          }}
          color="aprincipal"
        >
          Editar Cadastro
        </Button>
      </Box>

      <Select
        options={periodosDisponiveis}
        onChange={(e) => setParams((old) => ({ ...old, periodo: e }))}
        value={params.periodo}
        isLoading={isLoading}
        placeholder="Selecione um período"
        isDisabled={isLoading}
      />

      {isLoading ? (
        <Stack gap={2}>
          <Box height={100}>
            <Skeleton height={"100%"} />
          </Box>
        </Stack>
      ) : (
        <Stack gap={2}>
          {metasDetalhadasAtivas?.[0]?.valoresBase.map((item) => (
            <CardBase data={item} idMeta={metasDetalhadasAtivas?.[0].idMeta} />
          ))}
        </Stack>
      )}
    </>
  );
}
