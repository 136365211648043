import React, { useEffect, useMemo, useState } from "react";

import { Container, Tab, Tabs } from "react-bootstrap";
import "chartjs-plugin-datalabels";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import Title from "../../components/title";

import { useLevelAcess } from "../../hooks/usePermissions";
import {
  arrayBases,
  formatarNumero,
  formatarNumeroCompleto,
} from "../../util/loja";
import toast from "react-hot-toast";

import FilterBases from "../../components/filterBases";
import { PermissionsPage } from "../../util/permissionsPage";
import { estoqueService } from "../../services/estoqueServices";
import BootstrapTable from "react-bootstrap-table-next";
import { Box } from "@mui/material";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import { useUserData } from "../../hooks/useUserData";

export function MetaEstoque() {
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [base, setBase] = useState();
  const [periodoReferencia, setPeriodoReferencia] = useState("");
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);
  const [colunasPlanejamento, setColunasPlanejamento] = useState([
    { dataField: "id", text: "" },
  ]);
  const [dadosPlanejamentoFinanceiro, setDadosPlanejamentoFinanceiro] =
    useState([]);

  useEffect(() => {
    buscarNivelAcesso();
    setIsLoading(true);
  }, [accessLevel, acessBase]);

  useMemo(() => {
    if (
      periodoReferencia &&
      periodoReferencia !== "" &&
      accessLevel?.length > 0 &&
      acessBase?.length > 0 &&
      base
    ) {
      setIsLoading(true);
      criarPlanejanejamentoFinanceiro();
    }
  }, [periodoReferencia, accessLevel, acessBase, base]);

  useEffect(() => {
    preencherPeriodo();
  }, []);

  function preencherPeriodo() {
    let dataAtual = new Date();
    let ano = dataAtual.getFullYear();
    let mes = dataAtual.getMonth() + 1;
    let dia = dataAtual.getDate();

    let horas = dataAtual.getHours();
    let minutos = dataAtual.getMinutes();
    let segundos = dataAtual.getSeconds();

    if (mes < 10) {
      mes = "0" + mes;
    }
    if (dia < 10) {
      dia = "0" + dia;
    }
    if (horas < 10) {
      horas = "0" + horas;
    }
    if (minutos < 10) {
      minutos = "0" + minutos;
    }
    if (segundos < 10) {
      segundos = "0" + segundos;
    }

    let dataAtualParaReferencia = ano + "-" + mes + "-" + dia;

    setPeriodoReferencia(dataAtualParaReferencia);
  }

  const buscarNivelAcesso = async () => {
    if (accessLevel) {
      setBasesDisponiveis(arrayBases(acessBase));
      if (acessBase.length > 0) {
        setBase(acessBase[0]);
        setBasesSelecionadas([acessBase[0]]);
      }
    } else {
      setIsLoading(false);
    }
  };

  const filtrarBases = (codBase) => {
    let basesSelecionadasTemp = basesSelecionadas.filter((a) => true);
    if (basesSelecionadasTemp.filter((a) => a === codBase).length > 0) {
      basesSelecionadasTemp = basesSelecionadasTemp.filter(
        (a) => a !== codBase
      );
    } else {
      basesSelecionadasTemp = codBase;
    }
    setBase(basesSelecionadasTemp);
    setBasesSelecionadas([basesSelecionadasTemp]);
  };

  function converterMesSigla(numeroMes) {
    switch (numeroMes) {
      case 0:
        return "Jan";

      case 1:
        return "Fev";

      case 2:
        return "Mar";

      case 3:
        return "Abr";

      case 4:
        return "Mai";

      case 5:
        return "Jun";

      case 6:
        return "Jul";

      case 7:
        return "Ago";
      case 8:
        return "Set";

      case 9:
        return "Out";

      case 10:
        return "Nov";

      case 11:
        return "Dez";

      default:
        return numeroMes;
    }
  }

  async function criarPlanejanejamentoFinanceiro() {
    let dataAtual = new Date();

    let year = dataAtual.getFullYear();
    let mesAtual = dataAtual.getMonth();

    let previsaoFornecedor = { order: "1", contas: "PREV. FORNECEDOR" };
    let fornecedores = { order: "2", contas: "FORNECEDORES" };
    let planejamentoOrcamentarioCompras = {
      order: "3",
      contas: "PLANEJAMENTO ORÇAMENTARIO COMPRAS",
    };
    let saldoParaCompras = {
      order: "4",
      contas: "SALDO PARA COMPRAS",
      formula: "3 - 1 - 2",
    };

    let fornecedoresRealizado = [];
    let planejamentoCompras = [];
    let previsaoFornecedorValores = [];

    const periodoReferenciaAux =
      periodoReferencia.length === 10
        ? periodoReferencia.substring(0, 8) + "01"
        : "2022-01-01";
    const toastId = toast.loading("Buscando meta financeira de compras");
    try {
      const result = await estoqueService.buscaPlanejamentoOrcamentario({
        entityData: JSON.stringify({
          base: basesSelecionadas[0],
          periodo: periodoReferencia,
          periodoReferencia: periodoReferenciaAux,
        }),
      });

      if (result?.data?.success) {
        const data = result.data.data;

        fornecedoresRealizado = data.fornecedoresRealizado;
        planejamentoCompras = data.planejamentoCompras;
        previsaoFornecedorValores = data.previsaoFornecedor;
      }
    } catch (error) {
      toast.error("Erro ao receber dados do plano orçamentário", {
        id: toastId,
        duration: 7000,
        style: {
          borderRadius: "0.25rem",
          background: "#333",
          color: "#fff",
        },
      });

      setIsLoading(false);
      return;
    }

    toast.loading("Criando planejamento financeiro", {
      id: toastId,
    });

    //Para esta tabela as colunas são dinamicas, aqui é onde os são iniciadas as 3 primeiras colunas, que são fixas
    let colunasPlanejamentoTemp = [
      {
        dataField: "contas",
        text: "Contas",
        headerStyle: {
          cursor: "pointer",
          backgroundColor: "#00B0F0",
          color: "#fff",
          width: "100px",
        },
        classes: (cell, row, rowIndex, colIndex) => {
          // //console.log(colIndex);
          let classes = "";
          if (colIndex === 0 || colIndex === 1)
            classes += "classe-column-highlight ";
          if (row.contas === "TOTAL GERAL" && colIndex > 1)
            classes += "classe-column-line-highlight-compra ";
          return classes;
        },
        formatter: (cell, row, rowIndex) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>{cell}</div>
              <div>{row.order}</div>
            </div>
          );
        },
      },
    ];

    //Esse for preenche as colunas com os nomes dos meses, o mês atual e os 8 seguintes.
    //É iniciada também um vetor auxiliar para guardar as colunas que foram criadas 'listaColunasAtivas'
    let listaColunasAtivas = [];
    for (let i = 0; i < 9; i++) {
      let dataAtualTemp = new Date(year, mesAtual + i, 1);
      let anoAtualTemp = dataAtualTemp.getFullYear();
      let mesAtualTemp = dataAtualTemp.getMonth();
      let mesSigla = converterMesSigla(mesAtualTemp) + "/" + anoAtualTemp;
      colunasPlanejamentoTemp.push({
        dataField: mesAtualTemp + "" + anoAtualTemp,
        text: mesSigla,
        headerStyle: {
          cursor: "pointer",
          backgroundColor: "#00B0F0",
          color: "#fff",
          width: "80px",
        },
        formatter: (cell, row, rowIndex) => {
          return (
            <div
              style={
                row.contas === "SALDO FINAL" ||
                row.contas === "RESULTADO FLUXO CAIXA MÊS"
                  ? cell[0] === "-"
                    ? { color: "#f00" }
                    : { color: "#00f" }
                  : {}
              }
            >
              {row.formula ? (
                <>
                  <div>{cell}</div>
                  <div style={{ fontSize: "80%" }}>({row.formula})</div>
                </>
              ) : (
                cell
              )}
            </div>
          );
        },
        classes: (cell, row, rowIndex, colIndex) => {
          let classes = "";
          if (colIndex === 0) classes += "classe-column-highlight ";
          if (row.contas === "SALDO PARA COMPRAS" && colIndex > 0)
            classes += "classe-column-line-highlight-saldo ";
          return classes;
        },
      });
      listaColunasAtivas.push({
        periodoReferencia: mesAtualTemp + "" + anoAtualTemp,
        month: mesAtualTemp,
        year: anoAtualTemp,
        siglaReferencia: mesSigla,
      });
    }
    setColunasPlanejamento(colunasPlanejamentoTemp);

    listaColunasAtivas.map((a) => {
      let key = a.periodoReferencia;

      // PREENCHENDO PREVISÃO DE FORNECEDORES
      const previsaoFornecedorValoresTemp = previsaoFornecedorValores.reduce(
        (acumulador, valorAtual) => {
          if (
            a.month + 1 === Number(valorAtual.Mes) &&
            a.year === Number(valorAtual.Ano)
          ) {
            return !isNaN(valorAtual.ValorLiquido)
              ? acumulador + Number(valorAtual.ValorLiquido)
              : acumulador;
          }
          return acumulador;
        },
        0
      );
      previsaoFornecedor[key] = formatarNumeroCompleto(
        previsaoFornecedorValoresTemp,
        false,
        2,
        false,
        true
      );

      // PREENCHENDO FORNECEDORES (PEDIDOS + TRANSITO)
      const fornecedorValoresTemp = fornecedoresRealizado.reduce(
        (acumulador, valorAtual) => {
          if (
            a.month + 1 === Number(valorAtual.Mes) &&
            a.year === Number(valorAtual.Ano)
          ) {
            return !isNaN(valorAtual.ValorLiquido)
              ? acumulador + Number(valorAtual.ValorLiquido)
              : acumulador;
          }
          return acumulador;
        },
        0
      );
      fornecedores[key] = formatarNumeroCompleto(
        fornecedorValoresTemp,
        false,
        2,
        false,
        true,
        true
      );

      // PREENCHENDO PLANO ORCAMENTARIO DE COMPRAS
      const planejamentoComprasTemp = planejamentoCompras.reduce(
        (acumulador, valorAtual) => {
          if (
            a.month + 1 === Number(valorAtual.Mes) &&
            a.year === Number(valorAtual.Ano)
          ) {
            return !isNaN(valorAtual.ValorLiquido)
              ? acumulador + Number(valorAtual.ValorLiquido)
              : acumulador;
          }
          return acumulador;
        },
        0
      );

      planejamentoOrcamentarioCompras[key] = formatarNumero(
        planejamentoComprasTemp,
        false,
        2,
        false,
        true
      );

      saldoParaCompras[key] = formatarNumeroCompleto(
        planejamentoComprasTemp -
          previsaoFornecedorValoresTemp -
          fornecedorValoresTemp,
        false,
        2,
        false,
        true
      );
    });

    listaColunasAtivas.map((a, i) => {
      let key = a.month + "" + a.year;
      fornecedores[key] = fornecedores[key]
        ? formatarNumeroCompleto(fornecedores[key], false)
        : formatarNumeroCompleto(0, false, 2, false, true);
    });

    setDadosPlanejamentoFinanceiro([
      previsaoFornecedor,
      fornecedores,
      planejamentoOrcamentarioCompras,
      saldoParaCompras,
    ]);

    toast.success("Sucesso ao receber dados", {
      id: toastId,
    });
    setIsLoading(false);
  }

  const rowClasses = (row, rowIndex) => {
    // //console.log(row);
    let classes = null;

    if (row.id === 0 || row.id === 9) {
      classes = "header-footer-table";
    }

    return classes;
  };

  return (
    <>
      <div className="container-app">
        {PermissionsPage("estoqueMes") && (
          <Container>
            <div className="container-conteudo">
              <Container className="containerFlexBox">
                <Title title="META ESTOQUE" />
                <FilterBases
                  basesDisponiveis={basesDisponiveis}
                  basesSelecionadas={basesSelecionadas}
                  filtrarBases={filtrarBases}
                />
              </Container>
              {isLoading ? (
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  height={"90vh"}
                >
                  <LoadingTable isLoading={isLoading} />
                </Box>
              ) : (
                <>
                  <Container>
                    <Tabs
                      defaultActiveKey="home"
                      id="uncontrolled-tab-example"
                      className=""
                    >
                      <Tab
                        eventKey="home"
                        title={`Base ${basesSelecionadas[0]}`}
                      >
                        <BootstrapTable
                          classes="table-previsao-vendas"
                          wrapperClasses="table-responsive"
                          keyField="contas"
                          bootstrap4={true}
                          striped
                          data={dadosPlanejamentoFinanceiro}
                          rowClasses={rowClasses}
                          columns={colunasPlanejamento}
                        />
                      </Tab>
                    </Tabs>
                  </Container>
                </>
              )}
            </div>
          </Container>
        )}
      </div>
    </>
  );
}
