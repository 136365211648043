import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Bar } from "react-chartjs-2";
import { formatarNumero } from "../../util/loja";
const width = typeof window !== "undefined" ? window?.screen?.width : 0;

export const Bar1 = ({
  dataChart,
  dataDescontoAplicadoFromApi,
  dataChartLineFromApi,
  lojas,
}) => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  const optionsChartBarMetas = {
    maintainAspectRatio: !isSm ? false : true,
    responsive: !isSm ? false : true,
    legend: {
      position: "bottom",
      reverse: true,
      labels: {
        filter: function (item, chart) {
          return (
            !item.text.includes("Desconto") &&
            !item.text.includes("Ouro") &&
            !item.text.includes("Diamante")
          );
        },
      },
    },
    layout: {
      padding: {
        top: 30,
        right: 50,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          id: "A",
          ticks: {
            padding: 25,
            display: true,
          },
        },
        {
          id: "B",
          stacked: true,
          ticks: {
            padding: 25,
            display: false,
          },
          offset: true,
          offsetGridLines: false,
        },
      ],
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (tooltipItem, data) => {
          tooltipItem = tooltipItem[0];
          if (tooltipItem.datasetIndex === 0) {
            return "Valor Projetado Para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 1) {
            return "Meta Para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 2) {
            return "Meta Diamante para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 3) {
            return "Meta Ouro para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 4) {
            return "Valor Realizado Por " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 5) {
            let valorDesconto = formatarNumero(
              dataDescontoAplicadoFromApi[tooltipItem.index]
            );
            return "Total sem desconto " + valorDesconto;
          }
        },
        label: (tooltipItem, data) => {
          if (tooltipItem.datasetIndex !== 5) {
            return (
              "Valor Total: " + formatarNumero(parseFloat(tooltipItem.value))
            );
          } else {
            let valorDesconto =
              dataDescontoAplicadoFromApi[tooltipItem.index] -
              dataChartLineFromApi[tooltipItem.index];
            return "Valor do Desconto: " + formatarNumero(valorDesconto);
          }
        },
        footer: (tooltipItem, data) => {
          tooltipItem = tooltipItem[0];
          if (tooltipItem.datasetIndex === 5) {
            let valorDesconto =
              dataDescontoAplicadoFromApi[tooltipItem.index] -
              dataChartLineFromApi[tooltipItem.index];
            let valorDescontoPorcentagem =
              (valorDesconto / dataDescontoAplicadoFromApi[tooltipItem.index]) *
              100;
            return (
              "Porcentagem " +
              formatarNumero(valorDescontoPorcentagem, false) +
              "%"
            );
          } else if (
            tooltipItem.datasetIndex === 3 ||
            tooltipItem.datasetIndex === 2
          ) {
            let totalRealizado = dataChartLineFromApi[tooltipItem.index];
            return (
              "Valor realizado " + formatarNumero(parseFloat(totalRealizado))
            );
          }
        },
      },
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
        formatter: function (value, context) {
          return parseFloat(value).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
  };

  const calculateWidth = () => {
    const x = dataChart.labels;
    const length = x.length;
    if (length) {
      if (length * 110 < width) {
        return width;
      }
      return length * 110;
    }
    return null;
  };

  return (
    <Box
      padding={0}
      maxWidth={"100vw"}
      sx={{ overflowX: "scroll" }}
      height={"60vh"}
    >
      <Bar
        height={!isSm ? 500 : null}
        width={calculateWidth()}
        data={dataChart}
        options={optionsChartBarMetas}
      />
    </Box>
  );
};
