import React, { useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
// import api from '../../services/api';
import "chartjs-plugin-datalabels";
import Title from "../../components/title";

import toast from "react-hot-toast";
import "react-data-table-component-extensions/dist/index.css";
import {
  arrayBases,
  converterData,
  converterDataFormatoPadrao,
  converterLoja,
  converterMes,
  converterNomeLoja,
  fAnosDisponiveis,
} from "../../util/loja";
import Select from "react-select";

import { colunasInputMesAnos2 } from "../../util/tables";
import TabelaNext from "../../components/componentteste/TabelaNext";
import { TituloTabela } from "../../components/TituloTabelaNew/TituloTabela";
import ButtonVoltar from "../../components/ButtonVoltarNew";

import { vendasService } from "../../services/vendas";
import { useUserData } from "../../hooks/useUserData";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import { MultiSelectFilterBases } from "../../components/FilterLojaMetas/MultiSelectFilterBases";
import { FiltroPeriodo } from "../../components/FiltroPeriodo/FiltroPeriodo";
import {
  ButtonFilter,
  ButtonToday,
} from "../../components/DatePikerFilter/styles";
const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};
function QuantidadePedidos() {
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;

  const [periodoInputInicio, setPeriodoInputInicio] = useState("");
  const [periodoInputFim, setPeriodoInputFim] = useState("");
  const [periodoInputInicioAux, setPeriodoInputInicioAux] = useState("");
  const [periodoInputFimAux, setPeriodoInputFimAux] = useState("");
  const [periodoAno, setPeriodoAno] = useState(false);

  const [mesesDisponiveis, setMesesDisponiveis] = useState(false);
  const [anosDisponiveisAuxiliar, setAnosDisponiveisAuxiliar] = useState([]);
  const [anosDisponiveis, setAnosDisponiveis] = useState(false);
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [dadosGraficoAgrupado, setDadosGraficoAgrupado] = useState({
    labels: [],
    datasets: [],
  });
  const [dadosGerais, setDadosGerais] = useState(0);
  const [filtrosAtivosPeriodoTexto, setFiltrosAtivosPeriodoTexto] =
    useState("");
  const [filtrosMesesSelecionados, setFiltrosMesesSelecionados] = useState([]);
  const [filtrosAnosSelecionados, setFiltrosAnosSelecionados] = useState([]);
  const [filtrosLojasSelecionadas, setFiltrosLojasSelecionadas] = useState([]);
  const [codLojaAtiva, setCodLojaAtiva] = useState(false);

  const [dadosParaTabela, setDadosParaTabela] = useState([]);

  const [dadosUltimoPeriodo, setDadosUltimoPeriodo] = useState();
  const [exibeTabela, setExibeTabela] = useState(false);
  const [tipoPeriodo, setTipoPeriodo] = useState("");
  const [filtradoPorLoja, setFiltradoPorLoja] = useState(false);
  const [nivelAtivoDoGrafico, setNivelAtivoDoGrafico] = useState(0);
  const [auxAnoAtual, setAuxAnoAtual] = useState(0);
  const [auxAnoAnterior, setAuxAnoAnterior] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [dadosDisponiveis, setDadosDisponiveis] = useState(true);
  const [carregando, setCarregando] = useState(false);

  const [base, setBase] = useState();
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);
  useEffect(() => {
    if (!!userData) {
      buscarNivelAcesso();
      setIsLoading(true);
      setCarregando(true);
      return;
    }
  }, [userData]);
  useMemo(() => {
    if (periodoAno && !!userData) {
      preencherDados("ano");
    } else if (
      periodoInputInicio &&
      periodoInputInicio !== "" &&
      periodoInputFim &&
      periodoInputFim !== "" &&
      accessLevel?.length > 0 &&
      acessBase?.length > 0 &&
      base
    ) {
      preencherDados("input");
    }
  }, [periodoInputInicio, periodoInputFim, periodoAno, userData, base]);

  useEffect(() => {
    preencherInputPeriodo();
  }, []);
  useEffect(() => {
    if (codLojaAtiva) {
      preencherDadosVendasPorMes(codLojaAtiva);
    }
  }, [filtrosMesesSelecionados]);

  useEffect(() => {
    if (filtrosAnosSelecionados.length > 0) {
      iniciaFiltroPorAno();
    }
  }, [filtrosAnosSelecionados]);

  useEffect(() => {
    if (filtradoPorLoja) {
      iniciaFiltroPorLoja();
    }
  }, [filtrosLojasSelecionadas]);

  const buscarNivelAcesso = () => {
    if (accessLevel) {
      const bases = arrayBases(acessBase);
      setBasesDisponiveis(bases);
      setBase(bases?.map((item) => item.codBase));
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  };

  function preencherInputPeriodo() {
    var dataHoje = new Date();
    var [inicioAno, hoje] = converterDataFormatoPadrao(dataHoje);
    var { inicioAno: inicioAnoTexto, hoje: hojeTexto } =
      converterData(dataHoje);
    setPeriodoInputInicioAux(inicioAno);
    setPeriodoInputFimAux(hoje);
    setPeriodoInputInicio(inicioAno);
    setPeriodoInputFim(hoje);

    setFiltrosAtivosPeriodoTexto("- " + inicioAnoTexto + " à " + hojeTexto);
  }

  const filtrarBases = (value) => {
    const basesSelecionadasTemp = value;

    if (basesSelecionadasTemp.length === 0) {
      setBasesSelecionadas(basesDisponiveis?.map((item) => item.codBase));
      setBase(basesDisponiveis?.map((item) => item.codBase));
    } else {
      setBasesSelecionadas(basesSelecionadasTemp);
      setBase(basesSelecionadasTemp);
    }
  };

  async function preencherDados(tipo) {
    let auxLojasSelecionadas = [];
    setTipoPeriodo(tipo);
    setFiltradoPorLoja(false);
    var lojasDisponiveisAux = [];
    lojasDisponiveis.map((item) => {
      lojasDisponiveisAux.push(item);
    });
    setLojasDisponiveis(lojasDisponiveisAux);
    // console.log(tipo);
    setExibeTabela(true);
    if (tipo === "input") {
      let inicio = periodoInputInicio.split("-");
      inicio = inicio[2] + "/" + inicio[1] + "/" + inicio[0];
      let fim = periodoInputFim.split("-");
      fim = fim[2] + "/" + fim[1] + "/" + fim[0];
      setFiltrosAtivosPeriodoTexto("- " + inicio + " à " + fim);
      setExibeTabela(true);
      // setExibeTabelaGlobal(true);
      var auxAnoAtual = periodoInputInicio.split("-")[0];
      var auxAnoAnterior = parseInt(auxAnoAtual) - 1;
      setAuxAnoAnterior(auxAnoAnterior);
      setAuxAnoAtual(auxAnoAtual);
    } else if (tipo === "ano") {
      var auxAnoAtual = periodoAno.split(",");
      auxAnoAtual = auxAnoAtual.sort((a, b) => a - b);
      var auxAnoAnterior;
      if (auxAnoAtual.length > 1) {
        auxAnoAnterior = auxAnoAtual[auxAnoAtual.length - 2];
      } else {
        auxAnoAnterior = 0;
      }
      auxAnoAtual = auxAnoAtual[auxAnoAtual.length - 1];
      setAuxAnoAnterior(auxAnoAnterior);
      setAuxAnoAtual(auxAnoAtual);
    }
    if (!base) {
      setCarregando(true);
      setLojasDisponiveis([]);
    }
    setCarregando(true);
    setIsLoading(true);
    window.scrollTo(0, 0);
    const toastId = toast.loading("Buscando dados...");

    try {
      const response = await vendasService.listaVendaLojas(
        JSON.stringify({
          inicio: periodoInputInicio,
          fim: periodoInputFim,
          ano: periodoAno,
          tipo,
          nivelAcesso: accessLevel,
          base,
        })
      );
      console.log(response);

      if (!response?.data) {
        toast.error("Não há conexão com o RAD", {
          id: toastId,
          duration: 7000,
          style: {
            borderRadius: "0.25rem",
            background: "#333",
            color: "#fff",
          },
        });
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
        return;
      }
      if (response?.data?.success && response?.data?.data?.vendas?.length > 0) {
        setAnosDisponiveis(fAnosDisponiveis(response.data.anos));
        setAnosDisponiveisAuxiliar(fAnosDisponiveis(response.data.anos));
        var dataVendas = response.data.data.vendas;
        let dataVendasPeriodoAnterior =
          response.data.data.vendasPeriodoAnterior;
        setDadosGerais(dataVendas);
        let grupoDeAnos = agruparPor(dataVendas, "ano");
        let grupoDeAnosArray = Object.entries(grupoDeAnos);

        let grupoDeAnoLoja = [];
        grupoDeAnosArray.map((item) => {
          let grupoDeLoja = agruparPor(item[1], "CodLoja");
          grupoDeAnoLoja.push(grupoDeLoja);
        });

        let resumoGrupoDeAnoLoja = [];
        grupoDeAnoLoja.map((item) => {
          let item2 = Object.entries(item);
          item2.map((item3) => {
            let total = 0;
            let totalPedidos = 0;
            let ano;
            let codLoja;

            let totalCusto = 0;
            let totalLucro = 0;
            let porcentagemLucro = 0;

            item3[1].map((item4, index) => {
              if (index === 0) {
                ano = item4.ano;
                codLoja = item4.CodLoja;
                if (
                  auxLojasSelecionadas.indexOf(converterLoja(codLoja)) === -1
                ) {
                  auxLojasSelecionadas.push(converterLoja(codLoja));
                }
              }
              totalPedidos += parseInt(item4.totalPedidos);
              total += parseFloat(item4.VlrTotal);

              totalCusto += parseFloat(item4.VlrCusto);
              totalLucro += parseFloat(item4.VlrLucroBruto);
              porcentagemLucro += parseFloat(item4.VlrLucroBrutoPorcentagem);
            });
            let resumoLojaAno = {
              ano: ano,
              codLoja: codLoja,
              total: total,
              totalPedidos: totalPedidos,

              totalCusto,
              totalLucro,
              porcentagemLucro,
            };
            resumoGrupoDeAnoLoja.push(resumoLojaAno);
          });
        });

        var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");

        grupoDeAnosArray = Object.entries(agrupado);
        setDadosUltimoPeriodo(dataVendasPeriodoAnterior);

        preencherDadosGraficoAgrupado(
          grupoDeAnosArray,
          auxAnoAnterior,
          auxAnoAtual,
          tipo,
          dataVendasPeriodoAnterior
        );
        setLojasDisponiveis(auxLojasSelecionadas);
        setDadosDisponiveis(true);
        setIsLoading(false);
        setCarregando(false);

        toast.success("Dados recebidos", {
          id: toastId,
        });
      } else {
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function agruparPor(objetoArray, propriedade) {
    return objetoArray.reduce(function (acc, obj) {
      let key = obj[propriedade];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  function iniciaFiltroPorAno() {
    var anosSelecionados = filtrosAnosSelecionados.join(",");
    setPeriodoAno(anosSelecionados);
  }

  function iniciaFiltroPorLoja() {
    preencherDadosVendasPorLoja();
  }

  async function preencherDadosGraficoAgrupado(
    dados,
    anoAnterior,
    anoAtual,
    tipo,
    dadosPeriodoAnterior = false
  ) {
    dados = dados.reverse();
    var label = [];
    var labelCompleta = [];
    var dataSets = [];
    var dadosTabelaAux = [];
    const promise = await dados.map((item, i) => {
      var dadosGrupo = [];
      item[1].map((itemGrupo) => {
        var nomeLoja = converterLoja(itemGrupo.codLoja);
        if (filtrosLojasSelecionadas.length > 0) {
          if (filtrosLojasSelecionadas.indexOf(nomeLoja) >= 0) {
            let jaExiste = label.indexOf(nomeLoja);
            if (jaExiste < 0) {
              label.push(nomeLoja);
              labelCompleta.push(nomeLoja);
            }
            dadosGrupo.push(parseFloat(itemGrupo.totalPedidos));
            dadosTabelaAux.push(itemGrupo);
          } else {
            let jaExiste = labelCompleta.indexOf(nomeLoja);
            if (jaExiste < 0) {
              labelCompleta.push(nomeLoja);
            }
          }
        } else {
          let jaExiste = label.indexOf(nomeLoja);
          if (jaExiste < 0) {
            label.push(nomeLoja);
            labelCompleta.push(nomeLoja);
          }
          dadosGrupo.push(parseFloat(itemGrupo.totalPedidos));
          dadosTabelaAux.push(itemGrupo);
        }
      });
      var cor =
        item[0] === anoAnterior
          ? "rgba(255, 194, 218, 1)"
          : item[0] === anoAtual
          ? "rgba(91, 139, 252, 1)"
          : i === dados.length - 3
          ? "#5B8BFC"
          : i === dados.length - 4
          ? "#FFC2DA"
          : i === dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (item[0] === anoAnterior) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else if (item[0] === anoAtual) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          rotation: 0,
          formatter: function (value) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      }
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });

    if (dadosPeriodoAnterior && dadosPeriodoAnterior?.length > 0) {
      let periodoAnteriorAux = [];

      if (filtrosLojasSelecionadas.length > 0) {
        await dadosPeriodoAnterior.forEach((item) => {
          var nomeLoja = converterLoja(item.CodLoja);

          if (filtrosLojasSelecionadas.indexOf(nomeLoja) >= 0) {
            periodoAnteriorAux.push(parseInt(item.totalPedidos));
          }
        });
        dataSets.push({
          label: "Periodo anterior",
          data: periodoAnteriorAux,
          backgroundColor: "#FFDB8A",
          datalabels: {
            anchor: "start",
            display: true,
            align: "start",
          },
        });
      } else {
        const dadosPeriodoAnteriorTemp = dadosPeriodoAnterior.map((item) => {
          return parseInt(item.totalPedidos);
        });

        periodoAnteriorAux = dadosPeriodoAnteriorTemp;
        dataSets.push({
          label: "Periodo anterior",
          data: periodoAnteriorAux,
          backgroundColor: "#FFDB8A",
          datalabels: {
            anchor: "start",
            display: true,
            align: "start",
          },
        });
      }
    }

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "codLoja");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    preencherDadosTabela(
      dadosTabelaAux,
      anoAnterior,
      anoAtual,
      dadosPeriodoAnterior
    );
    setNivelAtivoDoGrafico(0);
    setDadosGraficoAgrupado(data);
  }

  function geraCorAleatoria() {
    var hexadecimais = "0123456789ABCDEF";
    var cor = "#";

    for (var i = 0; i < 6; i++) {
      cor += hexadecimais[Math.floor(Math.random() * 16)];
    }
    return cor;
  }

  function preencherDadosTabela(
    dados,
    anoAnterior,
    anoAtual,
    dadosPeriodoAnterior = false
  ) {
    var dadosTabela = [];
    var quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      qtdAnoAnteriorResumo = 0,
      qtdPeriodoAnteriorResumo = 0,
      vlrAnoAtualResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      vlrTotalPeriodoAnteriorResumo = 0,
      vlrMedioAnoAtualResumo = 0,
      vlrMedioAnoAnteriorResumo = 0,
      vlrMedioPeriodoAnteriorResumo = 0,
      desempenhoResumo = 0,
      desempenhoPAResumo = 0,
      rprAnoAtual = 0,
      rprAnoAnterior = 0,
      rprPeriodoAnterior = 0,
      lucroBrutoTotalAnoAtualResumo = 0,
      lucroBrutoTotalAnoAnteriorResumo = 0,
      lucroPorcentagemAnoAtualResumo = 0,
      lucroPorcentagemAnoAnteriorResumo = 0,
      custoTotalAnoAtualResumo = 0,
      custoTotalAnoAnteriorResumo = 0,
      custoPeriodoAnteriorResumo = 0;

    var totalPedidosAnoAtual = 0;
    var totalPedidosAnoAnterior = 0;
    var totalPedidosPeriodoAnterior = 0;

    if (dados.length > 0) {
      dados.forEach((item) => {
        item[1].forEach((item2) => {
          if (item2.ano === anoAtual) {
            totalPedidosAnoAtual += parseInt(item2.totalPedidos);
          } else if (item2.ano === anoAnterior) {
            totalPedidosAnoAnterior += parseInt(item2.totalPedidos);
          }
        });
      });

      if (dadosPeriodoAnterior?.length) {
        dadosPeriodoAnterior?.forEach((item) => {
          totalPedidosPeriodoAnterior += Number(item.totalPedidos);
        });
      }

      dados.forEach((item) => {
        let loja = parseInt(item[0]);
        let vlrAnoAtual = 0,
          vlrTotalAnoAnterior = 0,
          qtdAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrMedioAnoAtual = 0,
          vlrMedioAnoAnterior = 0,
          desempenho = 0,
          desempPeriodoAnterior = 0,
          representatividadeAtual = 0,
          representatividadeAnterior = 0,
          custoTotalAnoAtual = 0,
          custoTotalAnoAnterior = 0,
          lucroBrutoTotalAnoAtual = 0,
          lucroBrutoTotalAnoAnterior = 0,
          lucroPorcentagemAnoAtual = 0,
          lucroPorcentagemAnoAnterior = 0;

        const periodoAnteriorItem = dadosPeriodoAnterior?.find(
          (item) => Number(item?.CodLoja) === loja
        );
        let vlrPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.VlrTotal)
          : 0;
        let qtdPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.totalPedidos)
          : 0;
        let vlrMedioPeriodoAnterior =
          periodoAnteriorItem && qtdPeriodoAnterior
            ? vlrPeriodoAnterior / qtdPeriodoAnterior
            : 0;
        let representatividadePeriodoAnterior =
          periodoAnteriorItem && totalPedidosPeriodoAnterior
            ? Number(
                (
                  (qtdPeriodoAnterior / totalPedidosPeriodoAnterior) *
                  100
                ).toFixed(2)
              )
            : 0;

        let custoPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem.VlrCusto)
          : 0;

        let lucroPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem.VlrTotal) -
            Number(periodoAnteriorItem.VlrCusto)
          : 0;

        let porcentagemLucroPeriodoAnterior = parseFloat(
          parseFloat(
            Number(
              (vlrPeriodoAnterior - custoPeriodoAnterior) / vlrPeriodoAnterior
            ) * 100
          ).toFixed(2)
        );
        qtdPeriodoAnteriorResumo += qtdPeriodoAnterior;
        vlrTotalPeriodoAnteriorResumo += vlrPeriodoAnterior;
        custoPeriodoAnteriorResumo += custoPeriodoAnterior;

        rprPeriodoAnterior += Number(
          representatividadePeriodoAnterior.toFixed(2)
        );

        item[1].map((item2) => {
          if (item2.ano === anoAtual) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = parseFloat(parseFloat(item2.total).toFixed(2));

            custoTotalAnoAtual = parseFloat(
              parseFloat(item2.totalCusto).toFixed(2)
            );
            lucroBrutoTotalAnoAtual = parseFloat(
              parseFloat(item2.total - item2.totalCusto).toFixed(2)
            );
            lucroPorcentagemAnoAtual = parseFloat(
              parseFloat(
                Number((item2.total - item2.totalCusto) / item2.total) * 100
              ).toFixed(2)
            );

            vlrMedioAnoAtual = parseFloat(
              (vlrAnoAtual / qtdAnoAtual).toFixed(2)
            );
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            vlrMedioAnoAtualResumo += vlrMedioAnoAtual;

            custoTotalAnoAtualResumo += custoTotalAnoAtual;
            lucroBrutoTotalAnoAtualResumo += lucroBrutoTotalAnoAtual;
            lucroPorcentagemAnoAtualResumo += lucroPorcentagemAnoAtual;

            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else if (item2.ano === anoAnterior) {
            qtdAnoAnterior = parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = parseFloat(
              parseFloat(item2.total).toFixed(2)
            );

            custoTotalAnoAnterior = isNaN(item2.totalCusto)
              ? 0
              : parseFloat(parseFloat(item2.totalCusto).toFixed(2));
            lucroBrutoTotalAnoAnterior = isNaN(item2.totalLucro)
              ? 0
              : parseFloat(
                  parseFloat(item2.total - item2.totalCusto).toFixed(2)
                );
            lucroPorcentagemAnoAnterior = isNaN(item2.porcentagemLucro)
              ? 0
              : parseFloat(
                  parseFloat(
                    Number((item2.total - item2.totalCusto) / item2.total) * 100
                  ).toFixed(2)
                );

            vlrMedioAnoAnterior = parseFloat(
              (vlrTotalAnoAnterior / qtdAnoAnterior).toFixed(2)
            );
            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            vlrMedioAnoAnteriorResumo += vlrMedioAnoAnterior;

            custoTotalAnoAnteriorResumo += custoTotalAnoAnterior;
            lucroBrutoTotalAnoAnteriorResumo += lucroBrutoTotalAnoAnterior;
            lucroPorcentagemAnoAnteriorResumo += lucroPorcentagemAnoAnterior;

            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (qtdAnoAnterior > 0) {
          desempenho = parseFloat(
            ((qtdAnoAtual / qtdAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempenho = 0;
        }

        if (qtdPeriodoAnterior > 0) {
          desempPeriodoAnterior = parseFloat(
            ((qtdAnoAtual / qtdPeriodoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempPeriodoAnterior = 0;
        }

        desempenhoResumo += desempenho;
        representatividadeAtual = parseFloat(
          ((qtdAnoAtual / totalPedidosAnoAtual) * 100).toFixed(2)
        );
        representatividadeAnterior = parseFloat(
          ((qtdAnoAnterior / totalPedidosAnoAnterior) * 100).toFixed(2)
        );
        rprAnoAtual += representatividadeAtual;
        rprAnoAnterior += representatividadeAnterior;
        dadosTabela.push({
          loja: loja,
          qtdAnoAtual: qtdAnoAtual,
          vlrAnoAtual: vlrAnoAtual,
          vlrMedioAnoAtual: vlrMedioAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          vlrMedioAnoAnterior: vlrMedioAnoAnterior,
          desempenho: desempenho,
          desempPeriodoAnterior: desempPeriodoAnterior,
          representatividadeAtual: representatividadeAtual,
          representatividadeAnterior: representatividadeAnterior,
          vlrPeriodoAnterior: vlrPeriodoAnterior,
          qtdPeriodoAnterior: qtdPeriodoAnterior,
          vlrMedioPeriodoAnterior: vlrMedioPeriodoAnterior,
          representatividadePeriodoAnterior: representatividadePeriodoAnterior,

          custoTotalAtual: custoTotalAnoAtual,
          custoTotalAnterior: custoTotalAnoAnterior,
          lucroBrutoAtual: lucroBrutoTotalAnoAtual,
          lucroBrutoAnterior: lucroBrutoTotalAnoAnterior,
          lucro: lucroPorcentagemAnoAtual,
          lucroAnterior: lucroPorcentagemAnoAnterior,
          lucroPeriodoAnterior: lucroPeriodoAnterior,
          custoPeriodoAnterior: custoPeriodoAnterior,
          porcentagemLucroPeriodoAnterior: porcentagemLucroPeriodoAnterior,
        });
      });
    }

    vlrMedioAnoAtualResumo = parseFloat(
      (vlrMedioAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    vlrMedioAnoAnteriorResumo = parseFloat(
      (vlrMedioAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(2)
    );
    vlrMedioPeriodoAnteriorResumo = parseFloat(
      (vlrTotalPeriodoAnteriorResumo / qtdPeriodoAnteriorResumo).toFixed(2)
    );

    desempenhoResumo = parseFloat(
      ((qtdAnoAtualResumo / qtdAnoAnteriorResumo - 1) * 100).toFixed(2)
    );

    lucroPorcentagemAnoAtualResumo = parseFloat(
      (lucroPorcentagemAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    lucroPorcentagemAnoAnteriorResumo = parseFloat(
      (lucroPorcentagemAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(
        2
      )
    );

    desempenhoPAResumo = parseFloat(
      ((qtdAnoAtualResumo / qtdPeriodoAnteriorResumo - 1) * 100).toFixed(2)
    );

    dadosTabela.push({
      loja: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,
      desempenho: desempenhoResumo,
      desempPeriodoAnterior: desempenhoPAResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
      vlrPeriodoAnterior: vlrTotalPeriodoAnteriorResumo,
      qtdPeriodoAnterior: qtdPeriodoAnteriorResumo,
      vlrMedioPeriodoAnterior: vlrMedioPeriodoAnteriorResumo,
      representatividadePeriodoAnterior: rprPeriodoAnterior,

      custoTotalAtual: custoTotalAnoAtualResumo,
      custoTotalAnterior: custoTotalAnoAnteriorResumo,
      lucroBrutoAtual: lucroBrutoTotalAnoAtualResumo,
      lucroBrutoAnterior: lucroBrutoTotalAnoAnteriorResumo,
      lucro: lucroPorcentagemAnoAtualResumo,
      lucroAnterior: lucroPorcentagemAnoAnteriorResumo,

      lucroPeriodoAnterior:
        vlrTotalPeriodoAnteriorResumo - custoPeriodoAnteriorResumo,
      custoPeriodoAnterior: custoPeriodoAnteriorResumo,
      porcentagemLucroPeriodoAnterior: vlrTotalPeriodoAnteriorResumo
        ? (1 - custoPeriodoAnteriorResumo / vlrTotalPeriodoAnteriorResumo) * 100
        : 0,
    });
    setDadosParaTabela(dadosTabela);
  }

  function preencherDadosTabelaMes(dados, anoAnterior, anoAtual) {
    var dadosTabela = [];
    var quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrAnoAtualResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      vlrMedioAnoAtualResumo = 0,
      vlrMedioAnoAnteriorResumo = 0,
      desempenhoResumo = 0,
      rprAnoAtual = 0,
      rprAnoAnterior = 0;

    var totalPedidosAnoAtual = 0;
    var totalPedidosAnoAnterior = 0;

    if (dados.length > 0) {
      dados.map((item) => {
        item[1].map((item2, index2) => {
          if (item2.ano === anoAtual) {
            totalPedidosAnoAtual += parseInt(item2.totalPedidos);
          } else if (item2.ano === anoAnterior) {
            totalPedidosAnoAnterior += parseInt(item2.totalPedidos);
          }
        });
      });
      dados.map((item) => {
        let mes = parseInt(item[0]);
        let qtdAnoAtual = 0,
          vlrAnoAtual = 0,
          vlrMedioAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrTotalAnoAnterior = 0,
          vlrMedioAnoAnterior = 0,
          desempenho = 0,
          representatividadeAtual = 0,
          representatividadeAnterior = 0;
        item[1].map((item2, index2) => {
          if (item2.ano === anoAtual) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = parseFloat(parseFloat(item2.VlrTotal).toFixed(2));
            vlrMedioAnoAtual = parseFloat(
              (vlrAnoAtual / qtdAnoAtual).toFixed(2)
            );
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            vlrMedioAnoAtualResumo += vlrMedioAnoAtual;
            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else if (item2.ano === anoAnterior) {
            qtdAnoAnterior = parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = parseFloat(
              parseFloat(item2.VlrTotal).toFixed(2)
            );
            vlrMedioAnoAnterior = parseFloat(
              (vlrTotalAnoAnterior / qtdAnoAnterior).toFixed(2)
            );
            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            vlrMedioAnoAnteriorResumo += vlrMedioAnoAnterior;
            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (qtdAnoAnterior > 0) {
          desempenho = parseFloat(
            ((qtdAnoAtual / qtdAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempenho = 0;
        }
        desempenhoResumo += desempenho;
        representatividadeAtual = parseFloat(
          ((qtdAnoAtual / totalPedidosAnoAtual) * 100).toFixed(2)
        );
        representatividadeAnterior = parseFloat(
          ((qtdAnoAnterior / totalPedidosAnoAnterior) * 100).toFixed(2)
        );
        rprAnoAtual += representatividadeAtual;
        rprAnoAnterior += representatividadeAnterior;
        dadosTabela.push({
          mes: mes,
          qtdAnoAtual: qtdAnoAtual,
          vlrAnoAtual: vlrAnoAtual,
          vlrMedioAnoAtual: vlrMedioAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          vlrMedioAnoAnterior: vlrMedioAnoAnterior,
          desempenho: desempenho,
          representatividadeAtual: representatividadeAtual,
          representatividadeAnterior: representatividadeAnterior,
        });
      });
    }
    var quantidadeReferencia =
      quantidadeLojasAnoAnterior < quantidadeLojasAnoAtual
        ? quantidadeLojasAnoAnterior
        : quantidadeLojasAnoAtual;
    vlrMedioAnoAtualResumo = parseFloat(
      (vlrMedioAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    vlrMedioAnoAnteriorResumo = parseFloat(
      (vlrMedioAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(2)
    );
    desempenhoResumo = parseFloat(
      (desempenhoResumo / quantidadeReferencia).toFixed(2)
    );

    dadosTabela.push({
      mes: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,
      desempenho: desempenhoResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
    });
    setDadosParaTabela(dadosTabela);
  }

  async function preencherDadosGraficoAgrupadoMes(
    dados,
    anoAnterior,
    anoAtual
  ) {
    dados = dados.reverse();
    let label = [];
    let labelCompleta = [];
    let dataSets = [];
    let dadosTabelaAux = [];
    const promise = await dados.map((item, i) => {
      let dadosGrupo = [];
      item[1] = item[1].sort((a, b) => {
        return a.mes - b.mes;
      });

      item[1].map((itemGrupo) => {
        let nomeMes = converterMes(itemGrupo.mes);
        if (filtrosMesesSelecionados.length > 0) {
          if (filtrosMesesSelecionados.indexOf(nomeMes) >= 0) {
            let jaExiste = label.indexOf(nomeMes);
            if (jaExiste < 0) {
              label.push(nomeMes);
              labelCompleta.push(nomeMes);
            }
            dadosGrupo.push(parseFloat(itemGrupo.totalPedidos));
            dadosTabelaAux.push(itemGrupo);
          } else {
            let jaExiste = labelCompleta.indexOf(nomeMes);
            if (jaExiste < 0) {
              labelCompleta.push(nomeMes);
            }
          }
        } else {
          let jaExiste = label.indexOf(nomeMes);
          if (jaExiste < 0) {
            label.push(nomeMes);
            labelCompleta.push(nomeMes);
          }
          dadosGrupo.push(parseFloat(itemGrupo.totalPedidos));
          dadosTabelaAux.push(itemGrupo);
        }
      });
      var cor =
        item[0] === anoAnterior
          ? "rgb(255, 99, 132)"
          : item[0] === anoAtual
          ? "rgb(00, 00, 255)"
          : i === dados.length - 3
          ? "#00b7ce"
          : i === dados.length - 4
          ? "#f6398d"
          : i === dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (item[0] === anoAnterior) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else if (item[0] === anoAtual) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      }
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });
    // dataSets.splice(1, 1)
    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "mes");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    preencherDadosTabelaMes(dadosTabelaAux, anoAnterior, anoAtual);
    setDadosGraficoAgrupado(data);
    setMesesDisponiveis(labelCompleta);
    setNivelAtivoDoGrafico(1);
  }

  function preencherDadosVendasPorMes(codLoja) {
    setCodLojaAtiva(codLoja);
    var dados = dadosGerais;
    dados = dados.filter((a) => {
      return a.CodLoja === codLoja ? true : false;
    });
    let grupoDeAnos = agruparPor(dados, "ano");

    let grupoDeAnosArray = Object.entries(grupoDeAnos);

    preencherDadosGraficoAgrupadoMes(
      grupoDeAnosArray,
      auxAnoAnterior,
      auxAnoAtual
    );
  }

  function preencherDadosVendasPorLoja() {
    var dados = dadosGerais;

    let grupoDeAnos = agruparPor(dados, "ano");

    let grupoDeAnosArray = Object.entries(grupoDeAnos);

    let grupoDeAnoLoja = [];
    grupoDeAnosArray.forEach((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodLoja");
      grupoDeAnoLoja.push(grupoDeLoja);
    });

    let resumoGrupoDeAnoLoja = [];
    grupoDeAnoLoja.forEach((item) => {
      let item2 = Object.entries(item);
      item2.forEach((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codLoja;

        item3[1].forEach((item4, index) => {
          if (index === 0) {
            ano = item4.ano;
            codLoja = item4.CodLoja;
          }
          totalPedidos += parseInt(item4.totalPedidos);
          total += parseFloat(item4.VlrTotal);
        });
        let resumoLojaAno = {
          ano: ano,
          codLoja: codLoja,
          total: total,
          totalPedidos: totalPedidos,
        };
        resumoGrupoDeAnoLoja.push(resumoLojaAno);
      });
    });

    var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");
    grupoDeAnosArray = Object.entries(agrupado);

    preencherDadosGraficoAgrupado(
      grupoDeAnosArray,
      auxAnoAnterior,
      auxAnoAtual,
      tipoPeriodo,
      dadosUltimoPeriodo
    );
  }

  function iniciarFiltroHoje() {
    var dataHoje = new Date();
    var [, hoje] = converterDataFormatoPadrao(dataHoje);

    setPeriodoInputInicio(hoje);
    setPeriodoInputFim(hoje);

    setPeriodoInputInicioAux(hoje);
    setPeriodoInputFimAux(hoje);
  }

  function iniciarFiltroPorPeriodo() {
    setTipoPeriodo("input");
    setPeriodoAno(false);
    setFiltrosAnosSelecionados([]);
    setPeriodoInputInicio(periodoInputInicioAux);
    setPeriodoInputFim(periodoInputFimAux);
  }

  function fVoltar() {
    setMesesDisponiveis(false);
    setCodLojaAtiva(false);
    let dataVendas = dadosGerais;
    let grupoDeAnos = agruparPor(dataVendas, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let grupoDeAnoLoja = [];
    grupoDeAnosArray.forEach((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodLoja");
      grupoDeAnoLoja.push(grupoDeLoja);
    });
    let resumoGrupoDeAnoLoja = [];
    grupoDeAnoLoja.forEach((item) => {
      let item2 = Object.entries(item);
      item2.forEach((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codLoja;
        item3[1].forEach((item4, index) => {
          if (index === 0) {
            ano = item4.ano;
            codLoja = item4.CodLoja;
          }
          totalPedidos += parseInt(item4.totalPedidos);
          total += parseFloat(item4.VlrTotal);
        });
        let resumoLojaAno = {
          ano: ano,
          codLoja: codLoja,
          total: total,
          totalPedidos: totalPedidos,
        };
        resumoGrupoDeAnoLoja.push(resumoLojaAno);
      });
    });

    var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");
    grupoDeAnosArray = Object.entries(agrupado);
    setTipoPeriodo("input");
    preencherDadosGraficoAgrupado(
      grupoDeAnosArray,
      auxAnoAnterior,
      auxAnoAtual,
      "input",
      dadosUltimoPeriodo
    );
    // preencherInputPeriodo();
    setNivelAtivoDoGrafico(0);
  }

  function filtrarPorMes(mes) {
    setTipoPeriodo("mes");
    var mesesSelecionados = [];
    if (filtrosMesesSelecionados.length === 0) {
      mesesSelecionados.push(mes);
    } else {
      if (filtrosMesesSelecionados.indexOf(mes) >= 0) {
        filtrosMesesSelecionados.forEach((item) => {
          if (item !== mes) {
            mesesSelecionados.push(item);
          }
        });
      } else {
        filtrosMesesSelecionados.forEach((item) => {
          mesesSelecionados.push(item);
        });
        mesesSelecionados.push(mes);
      }
    }
    setFiltrosMesesSelecionados(mesesSelecionados);
    var mesesDisponiveisAux = [];
    mesesDisponiveis.forEach((item) => {
      mesesDisponiveisAux.push(item);
    });
    setMesesDisponiveis(mesesDisponiveisAux);
  }

  function filtrarPorAno(ano) {
    let filtrosLojasSelecionadasAuc = filtrosLojasSelecionadas;
    setTipoPeriodo("ano");
    var anosSelecionados = [];
    if (filtrosAnosSelecionados.length === 0) {
      anosSelecionados.push(ano);
    } else {
      if (filtrosAnosSelecionados.indexOf(ano) >= 0) {
        filtrosAnosSelecionados.forEach((item) => {
          if (item !== ano) {
            anosSelecionados.push(item);
          }
        });
      } else {
        filtrosAnosSelecionados.forEach((item) => {
          anosSelecionados.push(item);
        });
        anosSelecionados.push(ano);
      }
    }
    if (anosSelecionados.length === 0) {
      setPeriodoAno(false);
      preencherInputPeriodo();
      setFiltrosAnosSelecionados([]);
      setMesesDisponiveis(false);
    } else {
      setMesesDisponiveis(false);
      setFiltrosLojasSelecionadas(filtrosLojasSelecionadasAuc);
      setFiltrosAnosSelecionados(anosSelecionados);
    }
  }
  const LoadingTemp = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };
  function filtrarPorLoja(loja) {
    LoadingTemp();
    setTipoPeriodo("loja");
    const lojasSelecionadas = loja.map((item) => item.label);

    setFiltradoPorLoja(true);
    setFiltrosLojasSelecionadas(lojasSelecionadas);
    var lojasDisponiveisAux = [];
    lojasDisponiveis.map((item) => {
      lojasDisponiveisAux.push(item);
    });
    if (lojasSelecionadas.length === 0) {
      preencherInputPeriodo();
    } else {
      setLojasDisponiveis(lojasDisponiveisAux);
    }
    setLojasDisponiveis(lojasDisponiveisAux);
  }
  const optionsChartBarVendas = {
    title: {
      display: true,
      padding: 20,
      text: "Quantidade de Pedidos",
    },
    legend: {
      position: "bottom",
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
      },
    },
  };

  const getElementAtEvent = (element) => {
    if (nivelAtivoDoGrafico > 0) return;
    if (!element.length) return;
    const { _index: index, _xScale: eixoX } = element[0];
    var nomeLoja = eixoX.ticks[index];
    let codLoja = converterNomeLoja(nomeLoja);
    preencherDadosVendasPorMes(codLoja);
    setTipoPeriodo("mes");
  };

  return (
    <>
      <>
        <Box ml={2}>
          <Title title="Número de Pedidos" />
        </Box>
        <>
          <Stack gap={2} direction={"row"} mx={2}>
            <Box component={Paper} variant="outlined" px={1} pb={1}>
              <FiltroPeriodo
                periodoInputInicioAux={periodoInputInicioAux}
                periodoInputFimAux={periodoInputFimAux}
                setPeriodoInputInicioAux={setPeriodoInputInicioAux}
                setPeriodoInputFimAux={setPeriodoInputFimAux}
              />
              <Stack gap={1}>
                <ButtonFilter
                  type="button"
                  onClick={() => iniciarFiltroPorPeriodo()}
                  disabled={isLoading}
                >
                  Filtrar
                </ButtonFilter>
                <ButtonToday
                  type="button"
                  onClick={() => iniciarFiltroHoje()}
                  disabled={isLoading}
                >
                  Hoje
                </ButtonToday>
              </Stack>
            </Box>

            <Box width={"100%"}>
              <Box pb={1}>
                <MultiSelectFilterBases
                  basesDisponiveis={basesDisponiveis}
                  basesSelecionadas={basesSelecionadas}
                  onChange={filtrarBases}
                  disabled={isLoading}
                />
                <Typography variant="caption" component={"span"}>
                  *Atenção: Quando nenhuma base estiver selecionada, todas as
                  bases serão retornadas.*
                </Typography>
              </Box>

              <Box>
                <Typography
                  textAlign={"initial"}
                  fontWeight={"bold"}
                  component={"span"}
                >
                  Lojas
                </Typography>
                <Select
                  options={lojasDisponiveis.map((item) => ({
                    label: item,
                    value: item.split(" ")[1],
                  }))}
                  isMulti
                  onChange={filtrarPorLoja}
                  placeholder="Selecione uma loja"
                  styles={colourStyles}
                  isDisabled={false}
                />
                <Typography variant="caption" component={"span"}>
                  *Atenção: Quando nenhuma loja estiver selecionada, todas as
                  lojas serão retornadas.*
                </Typography>
              </Box>
            </Box>
          </Stack>
        </>

        {isLoading ? (
          <Box
            width={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={"90vh"}
          >
            <LoadingTable isLoading={isLoading} />
          </Box>
        ) : (
          <>
            <>
              {dadosDisponiveis && (
                <Box component={Paper} variant="outlined" m={2} px={1} pb={1}>
                  <Bar
                    data={dadosGraficoAgrupado}
                    options={optionsChartBarVendas}
                    getElementAtEvent={getElementAtEvent}
                  />
                  <ButtonVoltar fVoltar={fVoltar} />
                </Box>
              )}
            </>
            <>
              {dadosDisponiveis && exibeTabela ? (
                <>
                  <TituloTabela>VENDAS GLOBAIS ANO ATUAL</TituloTabela>
                  <Box mx={2} variant="outlined" m={2}>
                    <TabelaNext
                      dadosParaTabela={dadosParaTabela}
                      colunas={colunasInputMesAnos2(
                        tipoPeriodo,
                        anosDisponiveis,
                        filtrosAnosSelecionados
                      )}
                    />
                    {/* <LegendaTabela /> */}
                  </Box>
                </>
              ) : (
                <></>
              )}
            </>
          </>
        )}
      </>
    </>
  );
}

export default QuantidadePedidos;
