import api, { apiProd } from "./api";

export const metasService = {
  listaMetas: async ({
    periodo,
    nivelAcesso,
    baseAcesso,
    periodoInputInicio,
    periodoInputFim
  }) => {

    const response = api.get('/powerbi/request.php',
      {
        params: {
          entity: 'metas', entityData: JSON.stringify({ periodo, nivelAcesso, baseAcesso, periodoInputInicio, periodoInputFim }), entityFunction: 'metaLojaV2'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response).finally((item) => item)

    return response;
  },

  listaMetasVendedores: async ({
    periodo,
    nivelAcesso,
    baseAcesso,
    periodoInputInicio,
    periodoInputFim
  }) => {
    const response = api.get('/powerbi/request.php',
      {
        params: {
          entity: 'metas', entityData: JSON.stringify({ periodo, nivelAcesso, baseAcesso, periodoInputInicio, periodoInputFim }), entityFunction: 'metaVendedorV2'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;

  },

  listaMetasVendedoresAws: async ({
    periodo,
    nivelAcesso,
    baseAcesso,
    periodoInputInicio,
    periodoInputFim
  }) => {
    const response = apiProd.get('/bi/metas/meta-vendedor.php',
      {
        params: {
          entityData: JSON.stringify({ periodo, nivelAcesso, baseAcesso, periodoInputInicio, periodoInputFim })
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;

  },

  indicadorVendedorasAbaixoMeta: async ({
    periodo,
    nivelAcesso,
    baseAcesso,
    periodoInputInicio,
    periodoInputFim,
    lojas
  }) => {

    const response = await api.get('/powerbi/request.php',
      {
        params: {
          entity: 'metas', entityData: JSON.stringify({ periodo, nivelAcesso, baseAcesso, periodoInputInicio, periodoInputFim, lojas }), entityFunction: 'verificarIndicadoresVendedorasAbaixoMeta'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    console.log(response);
    return response;
  },
  indicadorVendedorasAbaixoMetaAws: async ({
    periodo,
    nivelAcesso,
    baseAcesso,
    periodoInputInicio,
    periodoInputFim,
    lojas
  }) => {

    const response = await apiProd.get('/bi/metas/vendedoras-abaixo-da-meta.php',
      {
        params: {
          entityData: JSON.stringify({ periodo, nivelAcesso, baseAcesso, periodoInputInicio, periodoInputFim, lojas })
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);
    console.log(response);
    return response;
  },
  periodosDisponiveisVendedores: async ({ idLoja }) => {
    const response = api.get('/powerbi/request.php',
      {
        params: {
          entity: 'metas', entityData: JSON.stringify({ idLoja }), entityFunction: 'periodosDisponiveisVendedoresV2'
        }
      })
      .then(({ ...response }) => response)
      .catch(({ ...response }) => response);

    return response;

  },
  periodosDisponiveisVendedoresAws: async ({ idLoja }) => {
    const response = apiProd.get('/bi/metas/periodos-disponiveis-vendedores.php', {
      params: {
        entityData: JSON.stringify({ idLoja })
      }
    })
      .then(response => {
        return response
      }).catch(error => {
        console.log(error);
        return error
      })
    return response

  },

  periodosDisponiveis: async () => {
    const response = apiProd.get('/bi/metas/periodos-disponiveis.php')
      .then(response => {
        return response
      }).catch(error => {
        console.log(error);
        return error
      })
    return response

  },

  listarMetas: async ({
    periodo,
    nivelAcesso,
    baseAcesso,
    periodoInputInicio,
    periodoInputFim
  }) => {
    const response = apiProd.get('/bi/metas/meta-loja.php', {
      params: {
        entityData: JSON.stringify({ periodo, nivelAcesso, baseAcesso, periodoInputInicio, periodoInputFim })
      }
    })
      .then(response => {
        return response
      }).catch(error => {
        console.log(error);
        return error
      })
    return response

  },
}
