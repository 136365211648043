import React, { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { Bar } from "react-chartjs-2";

import "chartjs-plugin-datalabels";

import ReactFullscreen from "react-easyfullscreen";
import { useLocation } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import { metasService } from "../../services/metasServices";

import { FilterLoja } from "../../components/FilterLojaApp";
import Loading from "../../components/Loading/Loading";
import AlertaLogin from "../../components/AlertaLogin";
import { Alert, Container } from "react-bootstrap";

import Alerta from "../../components/Alerta";
import FilterBasesApp from "../../components/filterBasesApp";

function MetasVendedorApp({ match }) {
  const [nivelAcesso, setNivelAcesso] = useState([]);
  const [dataMetasFromApi, setDataMetasFromApi] = useState([]);
  const [labelsChart, setLabelsChart] = useState([]);
  const [labelsTable, setLabelsTable] = useState([]);
  const [baseAcesso, setBaseAcesso] = useState([]);
  const [dataChartBarFromApi, setDataChartBarFromApi] = useState([]);
  const [dataChartProjetadaFromApi, setDataChartProjetadaFromApi] = useState(
    []
  );
  const [dataChartLineFromApi, setDataChartLineFromApi] = useState([]);
  const [dataDescontoAplicadoFromApi, setDataDescontoAplicadoFromApi] =
    useState([]);
  const [lojaAtiva, setLojaAtiva] = useState({ codLoja: -1, codBase: -1 });
  const [periodoReferencia, setPeriodoReferencia] = useState("");
  const [dadosDisponiveis, setDadosDisponiveis] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [periodosDisponiveis, setPeriodosDisponiveis] = useState([]);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [vendedoraSelecionada, setVendedoraSelecionada] = useState("");
  const [dadosGerais, setDadosGerais] = useState([]);
  const [dataMetaOuro, setDataMetaOuro] = useState([]);
  const [dataMetaDiamante, setDataMetaDiamante] = useState([]);
  const [listaColunasVendedores, setListaColunasVendedores] = useState([]);
  const [listaTabelasVendedores, setListaTabelasVendedores] = useState([]);
  const [permissoes, setPermissoes] = useState(false);

  const [periodoInputInicio, setPeriodoInputInicio] = useState(false);
  const [periodoInputFim, setPeriodoInputFim] = useState(false);

  const { search } = useLocation();

  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [showVendedorasAbaixoMeta, setShowVendedorasAbaixoMeta] =
    useState(false);

  const direcionarParaLoja = new URLSearchParams(search).get("loja")
    ? new URLSearchParams(search).get("loja")
    : false;

  useEffect(() => {
    let id = match.params.id;
    let token = match.params.token;
    window.location.href = `https://bi.aprincipalbb.com.br/metas/metas-vendedor/${id}/${token}?app=y`;
  }, []);

  async function preencherPeriodo(idLoja) {
    try {
      const { data } = await metasService.periodosDisponiveisVendedores({
        idLoja,
      });
      console.log(data);
      if (
        data.status === "success" &&
        data.type &&
        data.data?.periodos.length > 0
      ) {
        let periodos = [];
        console.log("here");
        let dataAux = data.data.periodos;
        let periodoAtual = "";
        let dataAtual = new Date();
        dataAux.map((item, index) => {
          let dataInicioTemp = new Date(item.periodoInicio);
          if (
            periodoAtual === "" &&
            dataAtual.getFullYear() === dataInicioTemp.getFullYear() &&
            dataAtual.getMonth() === dataInicioTemp.getMonth()
          ) {
            periodoAtual = item.id;
          }
          periodos.push({ periodo: item.periodo, id: item.id });
        });
        console.log(periodoAtual);
        if (periodoAtual === "") {
          periodoAtual = dataAux[0].id;
        }
        console.log(periodoAtual);
        setPeriodoReferencia(periodoAtual);
        setPeriodosDisponiveis(periodos);
      } else {
        preencherPeriodosCasoErro();
      }
    } catch (error) {
      console.log(error);
      preencherPeriodosCasoErro();
    }
  }

  function preencherPeriodosCasoErro() {
    var periodos = [];
    var dataAtual = new Date();
    for (let index = 0; index < 12; index++) {
      var mes = dataAtual.getMonth() + 1;
      var ano = dataAtual.getFullYear();
      if (mes < 10) {
        mes = "0" + mes;
      }
      var periodo = ano + "/" + mes;
      periodos.push(periodo);
      dataAtual.setMonth(dataAtual.getMonth() - 1);
    }
    setPeriodoReferencia(periodos[0]);
    setPeriodosDisponiveis(periodos);
  }

  function preencherDiasUteis(
    inicio,
    fim,
    diasRestantes = false,
    diasRemovidos = false
  ) {
    if (diasRestantes && (!periodoInputInicio || !periodoInputFim)) {
      var dataInicio = new Date();
      var dataFim = new Date(fim);
    } else if (diasRestantes && periodoInputInicio && periodoInputFim) {
      var dataInicio = new Date(
        periodoInputFim.replace("-", "/").replace("-", "/").replace("-", "/")
      );
      var dataFim = new Date(fim);
    } else if (diasRestantes) {
      var dataInicio = new Date();
      var dataFim = new Date(fim);
    } else {
      if (periodoInputInicio && periodoInputFim) {
        var dataInicio = new Date(
          periodoInputInicio
            .replace("-", "/")
            .replace("-", "/")
            .replace("-", "/")
        );
        var dataFimAux = new Date(periodoInputFim);
      } else {
        var dataInicio = new Date(inicio);
        var dataFimAux = new Date(fim);
      }
      var dataFim = new Date();
      if (dataFim > dataFimAux) dataFim = dataFimAux;
      if (!periodoInputInicio || !periodoInputFim) {
        dataFim.setDate(dataFim.getDate() - 1);
      }
    }
    dataFim.setHours(23);
    dataFim.setMinutes(59);
    dataFim.setSeconds(59);
    var dataAtual = dataInicio;
    var diasUteis = 0;

    while (dataAtual <= dataFim) {
      let ano = dataAtual.getFullYear();
      let mes =
        dataAtual.getMonth() + 1 < 10
          ? "0" + (dataAtual.getMonth() + 1)
          : dataAtual.getMonth() + 1;
      let dia =
        dataAtual.getDate() < 10
          ? "0" + dataAtual.getDate()
          : dataAtual.getDate();
      let dataAtualTemp = ano + "" + mes + "" + dia;
      if (diasRemovidos && diasRemovidos.indexOf(dataAtualTemp) < 0) {
        diasUteis++;
      }
      dataAtual.setDate(dataAtual.getDate() + 1);
    }
    return diasUteis;
  }

  function preencherDadosGrafico(dataMetas, inicio, fim, vendedora = false) {
    let labelNomesLojas = [];
    let arrayLojasPresentes = [];
    let vendaRealizadaPorVendedor = [];
    if (dataMetas.length > 0) {
      if (dataMetas && dataMetas.length > 0) {
        dataMetas.map(async (item) => {
          arrayLojasPresentes.push(item.CodLoja);
          labelNomesLojas.push({
            codLoja: item.CodLoja,
            CodBase: item.CodBase,
            nomeLoja: item.NomeLoja.replace("A PRINCIPAL", "")
              .replace("A PRINCIPAL ", "")
              .replace("A PRINCIPAL  ", "")
              .replace("APRINCIPAL", "")
              .replace("APRINCIPAL ", "")
              .replace("APRINCIPAL  ", "")
              .replace("(", "")
              .replace(")", "")
              .trim(),
            quantidadeVendedorasAbaixoMeta: [],
          });
        });
        var unique = arrayLojasPresentes.filter(function (value, index, self) {
          return self.indexOf(value) === index;
        });
        //console.log(unique);

        unique.map((item) => {
          var vendedorasPorLoja = dataMetas.filter((a) => {
            return a.CodLoja === item;
          });
          if (vendedorasPorLoja.length > 0) {
            vendaRealizadaPorVendedor.push(vendedorasPorLoja);
          }
        });
        var auxLabelNomes = {};
        labelNomesLojas.map((itemLabel) => {
          auxLabelNomes[`${itemLabel.codLoja}`] = {
            nomeLoja: itemLabel.nomeLoja,
            codBase: itemLabel.codBase,
          };
        });
        labelNomesLojas = [];
        Object.keys(auxLabelNomes).forEach(function (item) {
          labelNomesLojas.push({
            codLoja: item,
            nomeLoja: auxLabelNomes[item].nomeLoja,
            codBase: auxLabelNomes[item].codBase,
            metaAlcancada: [],
            quantidadeVendedorasAbaixoMeta: [],
          });
        });
        labelNomesLojas = labelNomesLojas.sort((a, b) => {
          return a.CodLoja - b.CodLoja;
        });

        setLabelsTable(labelNomesLojas);
        setCarregando(false);
        setDadosDisponiveis(true);
        setIsLoading(false);
        let lojaInicial;
        console.log(lojaAtiva);
        if (lojaAtiva.codLoja < 1) {
          console.log(lojaAtiva);
          if (direcionarParaLoja) {
            setLojaAtiva({ codLoja: direcionarParaLoja, codBase: -1 });
            vendaRealizadaPorVendedor.map((item, index) => {
              if (item[0].CodLoja === direcionarParaLoja) {
                lojaInicial = index;
              }
            });
          } else {
            setLojaAtiva({
              codLoja: vendaRealizadaPorVendedor[0][0].CodLoja,
              codBase: vendaRealizadaPorVendedor[0][0].CodBase,
            });
            lojaInicial = 0;
          }
        } else {
          console.log("hhheerre");
          console.log(lojaAtiva);
          console.log(vendaRealizadaPorVendedor);
          vendaRealizadaPorVendedor.map((item, index) => {
            if (
              basesSelecionadas?.indexOf(lojaAtiva.codBase) &&
              item[0].CodLoja === lojaAtiva.codLoja
            ) {
              lojaInicial = index;
            }
          });
        }
        if (!lojaInicial) {
          lojaInicial = 0;
        }
        console.log(vendaRealizadaPorVendedor);
        console.log(lojaInicial);
        setLojaAtiva({
          codLoja: vendaRealizadaPorVendedor[lojaInicial][0].CodLoja,
          codBase: vendaRealizadaPorVendedor[lojaInicial][0].CodBase,
        });

        console.log(lojaInicial);
        if (!vendedora) {
          preencherDataVendaRealizadaPorVendedor(
            vendaRealizadaPorVendedor[lojaInicial],
            inicio,
            fim,
            labelNomesLojas
          );
        } else {
        }
        preencherVendedoresAbaixoDaMeta(
          vendaRealizadaPorVendedor,
          inicio,
          fim,
          labelNomesLojas
        );

        setDataMetasFromApi(vendaRealizadaPorVendedor);
        setCarregando(false);
        setIsLoading(false);
      }
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  }

  async function preencherDataVendaRealizadaPorVendedor(
    data,
    inicio,
    fim,
    labelNomesLojas
  ) {
    let labelNomesLojasTemp = labelNomesLojas.filter((a) => true);
    console.log(labelNomesLojasTemp);
    setListaTabelasVendedores([]);
    console.log(data);
    let vendedoras = [];
    let meta = [];
    let metaOuroSugerida = [];
    let metaDiamanteSugerida = [];
    let realizada = [];
    let desconto = [];
    let projetada = [];
    let metaSugerida = [];
    let metaRealizada = [];
    let totalMeta = 0;
    let totalRealizada = 0;
    let totalPorcentagem = 0;
    let totalDefictMensal = 0;
    let totalDeficitDiario = 0;
    let totalProjecao = 0;
    let resumoChart = [];
    let listaTabelas = [];
    let listaColunas = [];
    //console.log(data);
    data = data.sort((a, b) => {
      return parseFloat(b.MetaRealizada) - parseFloat(a.MetaRealizada);
    });
    // console.log(data);
    const promise = data.map(async (item, i) => {
      let dadosTabela = [];
      let diasRemovidos = [];
      if (item.diasRemovidos) {
        diasRemovidos = item.diasRemovidos.split(",");
      }
      console.log(item);
      if (item.CodLoja === 5 && item.CodFunc === 4) {
        return;
      }
      vendedoras.push(item.NomeFuncionario);
      meta.push(item.MetaProjetada);
      metaOuroSugerida.push(item.MetaProjetada * 1.15);
      metaDiamanteSugerida.push(item.MetaProjetada * 1.3);
      realizada.push(item.MetaRealizada);
      desconto.push(
        parseFloat(item.MetaRealizada) + parseFloat(item.DescontoAplicado)
      );
      metaSugerida.push(parseFloat(item.MetaProjetada));
      metaRealizada.push(item.MetaRealizada);
      // let metaSugeridaTabela = !isNaN(parseFloat(item.MetaProjetada)) ? parseFloat(item.MetaProjetada) : 0;

      let metaRealizadaTabela = !isNaN(parseFloat(item.MetaRealizada))
        ? parseFloat(item.MetaRealizada)
        : 0;

      let metaProjetadaTabela = !isNaN(parseFloat(item.MetaProjetada))
        ? parseFloat(item.MetaProjetada)
        : 0;

      let porcentagemMeta =
        metaProjetadaTabela > 0
          ? ((metaRealizadaTabela / metaProjetadaTabela) * 100).toFixed(2)
          : "*";

      let metaTicketProjetadaTabela = !isNaN(parseFloat(item.TicketProjetado))
        ? parseFloat(item.TicketProjetado)
        : 0;

      let metaItensPorVendaProjetadaTabela = !isNaN(
        parseFloat(item.ItensPorVendaProjetado)
      )
        ? parseFloat(item.ItensPorVendaProjetado)
        : 0;

      let metaQuantidadePedido = !isNaN(
        parseInt(item.QuantidadePedidosProjetado)
      )
        ? parseInt(item.QuantidadePedidosProjetado)
        : 0;

      let metaItensPorVenda = !isNaN(parseFloat(item.ItensPorVendaProjetado))
        ? parseFloat(item.ItensPorVendaProjetado)
        : 0;

      let quantidadePedidos = !isNaN(parseFloat(item.QtdPedido))
        ? parseFloat(item.QtdPedido)
        : 0;

      let quantidadeProdutosVendidos = !isNaN(
        parseFloat(item.QtdProdutoVendido)
      )
        ? parseFloat(item.QtdProdutoVendido)
        : 0;

      let quantidadeProdutosPorPedido =
        quantidadePedidos > 0
          ? quantidadeProdutosVendidos / quantidadePedidos
          : 0;

      let metaTicketRealizadaTabela =
        quantidadePedidos > 0 ? metaRealizadaTabela / quantidadePedidos : 0;

      let deficitDiarioTicket =
        metaTicketProjetadaTabela - metaTicketRealizadaTabela > 0
          ? metaTicketProjetadaTabela +
            (metaTicketProjetadaTabela - metaTicketRealizadaTabela)
          : 0;

      let deficitQuantidadeItens =
        metaItensPorVenda - quantidadeProdutosPorPedido > 0
          ? metaItensPorVenda + metaItensPorVenda - quantidadeProdutosPorPedido
          : 0;

      let previstoTicket = 0;
      let previstoItensPorVenda = 0;

      totalMeta += metaProjetadaTabela;
      totalRealizada += metaRealizadaTabela;
      totalPorcentagem +=
        porcentagemMeta !== "*" && !isNaN(porcentagemMeta)
          ? parseFloat(porcentagemMeta)
          : 0;
      totalDefictMensal += 0;
      totalDeficitDiario += 0;
      totalProjecao += metaProjetadaTabela;
      let diasUteis = preencherDiasUteis(inicio, fim, false, diasRemovidos);
      let diasUteisRestantes = preencherDiasUteis(0, fim, true, diasRemovidos);
      console.log(diasUteis, diasUteisRestantes);
      let mediaAtual =
        diasUteis > 0 ? metaRealizadaTabela / diasUteis : metaRealizadaTabela;
      let projecaoFinal =
        mediaAtual && diasUteisRestantes > 0
          ? mediaAtual * diasUteisRestantes + metaRealizadaTabela
          : metaRealizadaTabela;
      let mediaAtualPedidos =
        diasUteis > 0 ? quantidadePedidos / diasUteis : false;
      let projecaoFinalPedidos =
        mediaAtualPedidos && diasUteisRestantes > 0
          ? mediaAtualPedidos * diasUteisRestantes + quantidadePedidos
          : quantidadePedidos;
      projetada.push(projecaoFinal);
      let deficitMensal =
        metaProjetadaTabela - metaRealizadaTabela > 0
          ? metaProjetadaTabela - metaRealizadaTabela
          : 0;
      let deficitDiario =
        diasUteisRestantes > 0 ? deficitMensal / diasUteisRestantes : 0;
      let deficitMensalPedidos =
        metaQuantidadePedido - quantidadePedidos > 0
          ? quantidadePedidos - quantidadePedidos
          : 0;
      let deficitDiarioPedidos =
        diasUteisRestantes > 0 ? deficitMensalPedidos / diasUteisRestantes : 0;
      labelNomesLojasTemp.map((d) => {
        if (d.codLoja === item.CodLoja) {
          d.diasUteis = diasUteis;
          d.diasFalta = diasUteisRestantes;
        }
      });

      let metaAlcancada = (projecaoFinal / metaProjetadaTabela) * 100;
      let customStyles = "#fff";
      console.log(metaAlcancada);
      if (metaAlcancada < 100) {
        metaAlcancada = 0;
        customStyles = "#ff0000";
      } else if (metaAlcancada >= 100 && metaAlcancada < 115) {
        metaAlcancada = 1;
        customStyles = "#00b14f";
      } else if (metaAlcancada >= 115 && metaAlcancada < 130) {
        metaAlcancada = 2;
        customStyles = "#f9ff00";
      } else if (metaAlcancada >= 130) {
        metaAlcancada = 3;
        customStyles = "#6395ec";
      }

      listaColunas.push([
        {
          name: "Funcionario",
          selector: "funcionario",
          sortable: false,
          omit: true,
        },
        { name: "Foto", selector: "foto", sortable: false, omit: true },
        {
          name: "Meta Alcançada",
          selector: "metaAlcancada",
          sortable: false,
          omit: true,
        },
        {
          name: "Custom Style",
          selector: "customStyle",
          sortable: false,
          omit: true,
        },
        { name: "Status", selector: "status", right: true, sortable: false },
        {
          name: "Vendas",
          selector: "meta",
          sortable: false,
          right: true,
          cell: (row) => {
            return row.status === "Previsto" ? (
              <div data-tag="allowRowEvents">
                <div
                  style={{
                    backgroundColor: customStyles,
                    fontWeight: "bold",
                    padding: 5,
                    borderRadius: 10,
                  }}
                >
                  {formatarNumero(row.meta)}
                </div>
              </div>
            ) : (
              <div data-tag="allowRowEvents">
                <div>{formatarNumero(row.meta)}</div>
              </div>
            );
          },
        },
        {
          name: "Ticket",
          selector: "ticket",
          sortable: false,
          right: true,
          cell: (row) => {
            return row.status !== "Défict Diário" ? (
              row.status === "Previsto" ? (
                <div data-tag="allowRowEvents">
                  <div>--//--</div>
                </div>
              ) : (
                <div data-tag="allowRowEvents">
                  <div>{formatarNumero(row.ticket)}</div>
                </div>
              )
            ) : row.ticket > 0 ? (
              <div data-tag="allowRowEvents">
                <div style={{ color: "#ff0000", fontWeight: "bold" }}>
                  {formatarNumero(row.ticket)}
                </div>
              </div>
            ) : (
              <div data-tag="allowRowEvents">
                <div>Parabéns</div>
              </div>
            );
          },
        },
        // {name: 'Ticket',selector: 'ticket',sortable: false,right: true,cell: row => row.abr < 0 ? <div data-tag="allowRowEvents"><div style={{ color: '#ff0000', fontWeight: 'bold' }}>{row.desempenho.toFixed(2)+'%'}</div></div> : <div data-tag="allowRowEvents"><div>{row.desempenho.toFixed(2)+'%'}</div></div>,
        {
          name: "Quantidade Pedidos",
          selector: "quantidadePedidos",
          sortable: false,
          right: true,
          format: (row, index) => {
            return formatarNumero(row.quantidadePedidos, false);
          },
        },
        {
          name: "Itens Por Venda",
          selector: "itensPorVenda",
          sortable: false,
          right: true,
          cell: (row) => {
            return row.status !== "Défict Diário" ? (
              row.status === "Previsto" ? (
                <div data-tag="allowRowEvents">
                  <div>--//--</div>
                </div>
              ) : (
                <div data-tag="allowRowEvents">
                  <div>{formatarNumero(row.itensPorVenda, false)}</div>
                </div>
              )
            ) : row.itensPorVenda > 0 ? (
              <div data-tag="allowRowEvents">
                <div style={{ color: "#ff0000", fontWeight: "bold" }}>
                  {formatarNumero(row.itensPorVenda, false)}
                </div>
              </div>
            ) : (
              <div data-tag="allowRowEvents">
                <div>Parabéns</div>
              </div>
            );
          },
        },
        {
          name: "Cadastro de Clientes",
          selector: "cadastroCliente",
          sortable: false,
          right: true,
          format: (row, index) => {
            return formatarNumero(row.cadastroCliente, false);
          },
        },
      ]);

      let fotoFuncionario = "";
      if (item.Foto && item.Foto !== "" && item.Foto.split("\\").length > 0) {
        let itemFoto = item.Foto.split("\\");
        fotoFuncionario = itemFoto[itemFoto.length - 1];
        fotoFuncionario =
          "https://aprincipalbb.com.br/api/powerbi/img/" + fotoFuncionario;
      } else {
        fotoFuncionario =
          "https://aprincipalbb.com.br/api/powerbi/img/noprofilepicture.png";
      }

      console.log(fotoFuncionario);

      dadosTabela.push({
        funcionario: item.NomeFuncionario,
        foto: fotoFuncionario,
        metaAlcancada: metaAlcancada,
        customStyles: customStyles,
        status: "Meta",
        meta: metaProjetadaTabela,
        ticket: metaTicketProjetadaTabela,
        quantidadePedidos: metaQuantidadePedido,
        itensPorVenda: metaItensPorVenda,
        cadastroCliente: 0,
      });
      dadosTabela.push({
        funcionario: item.NomeFuncionario,
        foto: fotoFuncionario,
        status: "Realizado",
        meta: metaRealizadaTabela,
        ticket: metaTicketRealizadaTabela,
        quantidadePedidos: quantidadePedidos,
        itensPorVenda: quantidadeProdutosPorPedido,
        cadastroCliente: 0,
      });
      dadosTabela.push({
        funcionario: item.NomeFuncionario,
        foto: fotoFuncionario,
        status: "Défict Diário",
        meta: deficitDiario,
        ticket: deficitDiarioTicket,
        quantidadePedidos: deficitDiarioPedidos,
        itensPorVenda: deficitQuantidadeItens,
        cadastroCliente: 0,
      });
      dadosTabela.push({
        funcionario: item.NomeFuncionario,
        foto: fotoFuncionario,
        status: "Previsto",
        meta: projecaoFinal,
        ticket: previstoTicket,
        quantidadePedidos: projecaoFinalPedidos,
        itensPorVenda: previstoItensPorVenda,
        cadastroCliente: 0,
      });
      //selector: 'funcionario', selector: 'status', selector: 'meta', selector: 'ticket', selector: 'quantidadePedidos', selector: 'itensPorVenda', selector: 'cadastroCliente',
      console.log(dadosTabela);
      listaTabelas.push(dadosTabela);
    });

    await Promise.all(promise);

    totalPorcentagem = (totalPorcentagem / 6).toFixed(2);
    totalDefictMensal += 0;
    totalDeficitDiario += 0;

    setLabelsChart(vendedoras);
    setDataChartBarFromApi(meta);
    setDataMetaOuro(metaOuroSugerida);
    setDataMetaDiamante(metaDiamanteSugerida);

    setDataChartLineFromApi(realizada);
    setDataDescontoAplicadoFromApi(desconto);

    setDataChartProjetadaFromApi(projetada);

    setListaColunasVendedores(listaColunas);
    setListaTabelasVendedores(listaTabelas);

    // buscarIndicadoresVendedoras(labelNomesLojasTemp);
  }

  async function preencherVendedoresAbaixoDaMeta(
    data,
    inicio,
    fim,
    labelNomesLojas
  ) {
    let labelNomesLojasTemp = labelNomesLojas.filter((a) => true);
    console.log(labelNomesLojasTemp);
    setListaTabelasVendedores([]);
    console.log(data);
    let vendedoras = [];
    let meta = [];
    let metaOuroSugerida = [];
    let metaDiamanteSugerida = [];
    let realizada = [];
    let desconto = [];
    let metaSugerida = [];
    let metaRealizada = [];
    data = data.sort((a, b) => {
      return parseFloat(b.MetaRealizada) - parseFloat(a.MetaRealizada);
    });
    console.log(data);
    let promise = null;
    const promiseGeral = data.map(async (item, i) => {
      promise = item.map(async (item, i) => {
        let dadosTabela = [];
        let diasRemovidos = [];
        if (item.diasRemovidos) {
          diasRemovidos = item.diasRemovidos.split(",");
        }
        // console.log(item);
        if (item.CodLoja === 5 && item.CodFunc === 4) {
          return;
        }
        vendedoras.push(item.NomeFuncionario);
        meta.push(item.MetaProjetada);
        metaOuroSugerida.push(item.MetaProjetada * 1.15);
        metaDiamanteSugerida.push(item.MetaProjetada * 1.3);
        realizada.push(item.MetaRealizada);
        desconto.push(
          parseFloat(item.MetaRealizada) + parseFloat(item.DescontoAplicado)
        );
        metaSugerida.push(parseFloat(item.MetaProjetada));
        metaRealizada.push(item.MetaRealizada);
        let metaRealizadaTabela = !isNaN(parseFloat(item.MetaRealizada))
          ? parseFloat(item.MetaRealizada)
          : 0;

        let metaProjetadaTabela = !isNaN(parseFloat(item.MetaProjetada))
          ? parseFloat(item.MetaProjetada)
          : 0;

        let diasUteis = preencherDiasUteis(inicio, fim, false, diasRemovidos);
        let diasUteisRestantes = preencherDiasUteis(
          0,
          fim,
          true,
          diasRemovidos
        );
        // console.log(diasUteis, diasUteisRestantes);
        let mediaAtual =
          diasUteis > 0 ? metaRealizadaTabela / diasUteis : metaRealizadaTabela;
        let projecaoFinal =
          mediaAtual && diasUteisRestantes > 0
            ? mediaAtual * diasUteisRestantes + metaRealizadaTabela
            : metaRealizadaTabela;

        let metaAlcancada = (projecaoFinal / metaProjetadaTabela) * 100;
        // console.log(metaAlcancada);
        if (metaAlcancada < 100) {
          metaAlcancada = 0;
        } else if (metaAlcancada >= 100 && metaAlcancada < 115) {
          metaAlcancada = 1;
        } else if (metaAlcancada >= 115 && metaAlcancada < 130) {
          metaAlcancada = 2;
        } else if (metaAlcancada >= 130) {
          metaAlcancada = 3;
        }

        labelNomesLojasTemp.map((d) => {
          if (d.codLoja === item.CodLoja) {
            d.diasUteis = diasUteis;
            d.diasFalta = diasUteisRestantes;
            // console.log(d);
            d.metaAlcancada.push(metaAlcancada);
          }
        });
      });
    });

    await Promise.all([promise, promiseGeral]);
    console.log(labelNomesLojasTemp);

    buscarIndicadoresVendedoras(labelNomesLojasTemp);
  }

  async function buscarIndicadoresVendedoras(
    lojasDisponiveisParaAdicionarIndicadores
  ) {
    try {
      const { data } = await metasService.indicadorVendedorasAbaixoMeta({
        baseAcesso,
        nivelAcesso,
        periodo: periodoReferencia,
        periodoInputFim,
        periodoInputInicio,
        periodoInputFim,
        lojas: lojasDisponiveisParaAdicionarIndicadores,
      });
      console.log(data);
      ////console.log(data);
      if (data.status === "success") {
        let dataTemp = data.data;
        console.log(dataTemp);
        let dataTodasLojasTemp = [];
        dataTemp.map((a) => {
          console.log(a);
          if (a.response) {
            dataTodasLojasTemp.push(Object.entries(a.response));
          }
        });
        console.log(dataTodasLojasTemp);
        let dataTodasLojas = [];
        dataTodasLojasTemp.map((a) => {
          a.map((b) => {
            dataTodasLojas.push(b);
          });
        });
        console.log("dataTodasLojas -> ", dataTodasLojas);
        let vendedorasAbaixaMetaTemp = [];

        if (dataTodasLojas?.length > 0) {
          // dataTodasLojas.map( item => {
          //     vendedorasAbaixaMetaTemp.push({'loja': converterLoja(item[0]), 'qtd': item[1]});
          // })
          let vendedoresAbaixoDaMeta = dataTodasLojas; //Object.entries(dataTodasLojas);
          let labelNomesParaFiltroTemp =
            lojasDisponiveisParaAdicionarIndicadores.filter((a) => true);
          console.log(vendedoresAbaixoDaMeta);
          vendedoresAbaixoDaMeta.map((a) => {
            console.log(a);
            console.log(labelNomesParaFiltroTemp);
            let codLoja = a[0];
            let quantidadeVendedorasAbaixoMeta = a[1];
            console.log(codLoja);
            console.log(quantidadeVendedorasAbaixoMeta);
            labelNomesParaFiltroTemp.map((b) => {
              console.log(b);
              if (b.codLoja === codLoja) {
                for (let i = 0; i < quantidadeVendedorasAbaixoMeta; i++) {
                  b.quantidadeVendedorasAbaixoMeta.push(1);
                }
              }
            });
          });

          console.log(labelNomesParaFiltroTemp);
          setLabelsTable(labelNomesParaFiltroTemp);
        }
        console.log(vendedorasAbaixaMetaTemp);
        // setVendedorasAbaixoMeta(vendedorasAbaixaMetaTemp);
      }
    } catch (error) {}
  }

  function formatarNumero(numero, money = true, decimal = 2) {
    if (money) {
      return numero.toLocaleString("pt-BR", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
        style: "currency",
        currency: "BRL",
      });
    } else {
      return numero.toLocaleString("pt-BR", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
    }
  }

  async function selecionarEmpresa(index, codLoja, codBase) {
    //console.log(index);
    console.log(index);
    console.log(codLoja);
    console.log(dataMetasFromApi);
    setCarregando(true);
    setIsLoading(true);
    window.scrollTo(0, 0);
    preencherDataVendaRealizadaPorVendedor(
      dataMetasFromApi[index],
      dataInicio,
      dataFim,
      labelsTable
    );
    preencherPeriodo(codLoja);
    setLojaAtiva({ codLoja: codLoja, codBase });
    setCarregando(false);
    setIsLoading(false);
  }

  function selecionarVendedora(nomeVendedora) {
    let dadosVendedora = dadosGerais.filter(
      (a) =>
        a.CodLoja === lojaAtiva.codLoja && a.NomeFuncionario === nomeVendedora
    );
    preencherDadosGrafico(dadosVendedora, dataInicio, dataFim, true);
    preencherDataVendaRealizadaPorVendedor(
      dadosVendedora,
      dataInicio,
      dataFim,
      labelsTable
    );
  }

  function resetarGraficos() {
    preencherDadosGrafico(dadosGerais, dataInicio, dataFim);
  }

  function filtrarBases(codBase) {
    console.log(codBase);
    // return;
    let basesSelecionadasTemp = [codBase];
    console.log(basesSelecionadasTemp);
    // if (basesSelecionadasTemp.filter(a => a === codBase).length > 0) {
    //     console.log('ok');
    //     basesSelecionadasTemp = basesSelecionadasTemp.filter(a => a != codBase);
    //     console.log(basesSelecionadasTemp);
    // } else {
    //     basesSelecionadasTemp.push(codBase)
    //     console.log(basesSelecionadasTemp);
    // }
    // console.log(basesSelecionadasTemp);
    setBasesSelecionadas([codBase]);
    console.log(dadosGerais);
    let dadosCompletosFiltrados = [];
    if (basesSelecionadasTemp.length > 0) {
      dadosCompletosFiltrados = dadosGerais.filter(
        (a) => basesSelecionadasTemp.indexOf(a.CodBase) >= 0
      );
    } else {
      dadosCompletosFiltrados = dadosGerais;
    }
    console.log(dadosCompletosFiltrados, dataInicio, dataFim);
    preencherDadosGrafico(dadosCompletosFiltrados, dataInicio, dataFim);
  }

  const dataChart = {
    labels: labelsChart,
    datasets: [
      {
        type: "line",
        label: "Projetada",
        borderColor: "rgb(00, 00, 00)",
        borderWidth: 2,
        fill: false,
        clip: true,
        data: dataChartProjetadaFromApi,
        tension: 0,
        borderDash: [10, 5],
        xAxisID: "A",
        datalabels: {
          anchor: "start",
          display: "auto",
          color: "#000",
          // rotation: 45,
          align: (context) => {
            // console.log(dataChartBarFromApi[context.dataIndex], dataChartProjetadaFromApi[context.dataIndex]);
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "-45";
            } else {
              return "135";
            }
            // console.log(context);
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
      {
        type: "line",
        label: "Meta",
        borderColor: "#ff0000",
        borderWidth: 2,
        fill: false,
        data: dataChartBarFromApi,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        // pointStyle: 'triangle',
        datalabels: {
          anchor: "start",
          display: true,
          color: "#ff0000",
          rotation: 45,
          // usePointStyle: true,
          align: (context) => {
            // console.log(dataChartBarFromApi[context.dataIndex], dataChartProjetadaFromApi[context.dataIndex]);
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return 90;
            } else {
              return -90;
            }
            // console.log(context);
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
      {
        type: "line",
        label: "Diamante",
        borderColor: "#6395ec",
        borderWidth: 5,
        fill: false,
        data: dataMetaDiamante,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: false,
          color: "#6395ec",
          rotation: 45,
          align: (context) => {
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "90";
            } else {
              return "-90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "line",
        label: "Ouro",
        borderColor: "#f9ff00",
        borderWidth: 5,
        fill: false,
        data: dataMetaOuro,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: false,
          color: "#f9ff00",
          rotation: 45,
          align: (context) => {
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "90";
            } else {
              return "-90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "bar",
        label: "Realizada",
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderColor: "white",
        borderWidth: 0,
        data: dataChartLineFromApi,
        xAxisID: "B",
        datalabels: {
          anchor: "start",
          display: true,
          color: "rgba(54, 162, 235, 1)",
          align: "start",
          rotation: 325,
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
          formatter: function (value, context) {
            // console.log(value, context);
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
      {
        type: "bar",
        label: "Desconto",
        backgroundColor: "rgba(255, 102, 0, 0.3)",
        borderColor: "rgba(255, 102, 0, 0.3)",
        borderWidth: 1,
        borderDash: [10, 5],
        data: dataDescontoAplicadoFromApi,
        xAxisID: "B",
        datalabels: {
          anchor: "end",
          display: "auto",
          color: "rgba(255, 255, 255, 1)",
          align: "start",
          rotation: 45,
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 12,
              },
              padding: 0,
            },
          },
          formatter: function (value, context) {
            // console.log(dataDescontoAplicadoFromApi[context.dataIndex], dataChartLineFromApi[context.dataIndex]);
            // dataDescontoAplicadoFromApi
            let valorDesconto =
              dataDescontoAplicadoFromApi[context.dataIndex] -
              dataChartLineFromApi[context.dataIndex];
            let valorDescontoPorcentagem =
              (valorDesconto / dataDescontoAplicadoFromApi[context.dataIndex]) *
              100;
            return ""; // 'Desc '+formatarNumero(valorDescontoPorcentagem, false)+'% '+formatarNumero(valorDesconto)+'';
          },
        },
      },
    ],
  };

  const optionsChartBarMetas = {
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 15,
        left: 5,
        right: 5,
      },
    },
    legend: {
      position: "bottom",
      reverse: true,
      labels: {
        filter: function (item, chart) {
          return (
            !item.text.includes("Desconto") &&
            !item.text.includes("Ouro") &&
            !item.text.includes("Diamante")
          );
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: false,
          },
        },
      ],
      xAxes: [
        {
          id: "A",
          ticks: {
            padding: 50,
            display: true,
          },
        },
        {
          id: "B",
          stacked: true,
          ticks: {
            padding: 0,
            display: false,
          },
          offset: true,
          offsetGridLines: false,
        },
      ],
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (tooltipItem, data) => {
          // console.log(tooltipItem, data);
          tooltipItem = tooltipItem[0];
          // return 'ok 1';
          if (tooltipItem.datasetIndex === 0) {
            return "Valor Projetado Para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 1) {
            return "Meta Para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 2) {
            return "Meta Diamante para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 3) {
            return "Meta Ouro para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 4) {
            return "Valor Realizado Por " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 5) {
            let valorDesconto = formatarNumero(
              dataDescontoAplicadoFromApi[tooltipItem.index]
            );
            return "Total sem desconto " + valorDesconto;
          }
          //return (`Metas alcançadas: ${parseFloat(metaParaLabel).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'BRL' })}`);
        },
        label: (tooltipItem, data) => {
          // console.log(tooltipItem, data);
          if (tooltipItem.datasetIndex !== 5) {
            return (
              "Valor Total: " + formatarNumero(parseFloat(tooltipItem.value))
            );
          } else {
            let valorDesconto =
              dataDescontoAplicadoFromApi[tooltipItem.index] -
              dataChartLineFromApi[tooltipItem.index];
            let valorDescontoPorcentagem =
              (valorDesconto / dataDescontoAplicadoFromApi[tooltipItem.index]) *
              100;
            return "Valor do Desconto: " + formatarNumero(valorDesconto);
          }
          return "";
        },
        footer: (tooltipItem, data) => {
          // alert(1);
          tooltipItem = tooltipItem[0];
          if (tooltipItem.datasetIndex === 5) {
            let valorDesconto =
              dataDescontoAplicadoFromApi[tooltipItem.index] -
              dataChartLineFromApi[tooltipItem.index];
            let valorDescontoPorcentagem =
              (valorDesconto / dataDescontoAplicadoFromApi[tooltipItem.index]) *
              100;
            return (
              "Porcentagem " +
              formatarNumero(valorDescontoPorcentagem, false) +
              "%"
            );
          } else if (
            tooltipItem.datasetIndex === 3 ||
            tooltipItem.datasetIndex === 2
          ) {
            let totalRealizado = dataChartLineFromApi[tooltipItem.index];
            return (
              "Valor realizado " + formatarNumero(parseFloat(totalRealizado))
            );
          }
        },
      },
    },
  };

  const getElementAtEvent = (element) => {
    if (!element.length) return;
    const { _index: index, _xScale: eixoX } = element[0];
    var nomeVendedora = eixoX.ticks[index];
    if (nomeVendedora === vendedoraSelecionada) {
      resetarGraficos();
      setVendedoraSelecionada("");
    } else {
      setVendedoraSelecionada(nomeVendedora);
      selecionarVendedora(nomeVendedora);
    }
  };

  createTheme("solarized", {
    divider: {
      default: "transparent",
    },
  });

  return (
    <>
      <div className="container-app container-webview-rn">
        {isLoading && <Loading estado={isLoading} />}

        {nivelAcesso.length > 0 && permissoes.mvendas2 ? (
          <>
            <Container className="container-metas-app">
              <div className="container-conteudo">
                <Container>
                  <FilterBasesApp
                    basesDisponiveis={basesDisponiveis}
                    basesSelecionadas={basesSelecionadas}
                    filtrarBases={filtrarBases}
                    required={true}
                  />
                </Container>
                <Container>
                  <div className="container-lojas-disponiveis">
                    <div className="container-tabela-nomes-lojas">
                      <FilterLoja
                        labelsTable={labelsTable}
                        functionEmpresa={selecionarEmpresa}
                        infoSelect={lojaAtiva}
                        required
                      />

                      <div className="alert-filterloja">
                        <Alert
                          className="fInfo"
                          onClick={() =>
                            setShowVendedorasAbaixoMeta(
                              !showVendedorasAbaixoMeta
                            )
                          }
                        >
                          Vendedora abaixo da meta{" "}
                          <FontAwesomeIcon
                            color="#f00"
                            styled={{ width: "8px" }}
                            size="sm"
                            icon={faChevronDown}
                          />{" "}
                        </Alert>
                      </div>
                      {showVendedorasAbaixoMeta && (
                        <div className="container-vendedoras-abaixo-meta">
                          {labelsTable.map((item) => {
                            if (
                              item?.quantidadeVendedorasAbaixoMeta?.length &&
                              item.quantidadeVendedorasAbaixoMeta.length > 0
                            ) {
                              return (
                                <div className="item-vendedora">
                                  <span>Loja {item.nomeLoja}</span> / Quantidade{" "}
                                  <span className="qtd">
                                    {" "}
                                    {item.quantidadeVendedorasAbaixoMeta.length}
                                  </span>
                                </div>
                              );
                            }
                            return <></>;
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </Container>
                <div className="container-metas">
                  <>
                    <div className="container-metas-interno-superior">
                      {dadosDisponiveis ? (
                        <Container>
                          <>
                            <ReactFullscreen>
                              {({ ref, onRequest, onExit }) => (
                                <div
                                  ref={ref}
                                  style={{ backgroundColor: "#fff" }}
                                >
                                  <div className="wrapper container-botao-filtrar container-botao-filtrar-vendedor">
                                    <Bar
                                      data={dataChart}
                                      options={optionsChartBarMetas}
                                      getElementAtEvent={getElementAtEvent}
                                    />
                                    {/* <button className='botaoTelaCheia' onClick={() => onRequest()}>Tela Cheia</button>
                                                                        <button className='botaoTelaCheia' onClick={() => onExit()}>Sair</button> */}
                                  </div>
                                </div>
                              )}
                            </ReactFullscreen>
                          </>
                        </Container>
                      ) : carregando ? (
                        <></>
                      ) : (
                        <Alerta />
                      )}
                    </div>
                    <Container>
                      <div className="container-legenda-tabelas legenda-tabela">
                        <small className="legenda-meta cor-diamante">
                          Diamante
                        </small>
                        <small className="legenda-meta cor-ouro">Ouro</small>
                        <small className="legenda-meta cor-meta">
                          Atingiu a Meta
                        </small>
                        <small className="legenda-meta cor-abaixo-meta">
                          Não atingiu
                        </small>
                      </div>
                      <div className="">
                        <div className="container-metas-interno-inferior metas-vendedor">
                          {dadosDisponiveis ? (
                            <div className="container-metas-interno-inferior-superior">
                              {listaTabelasVendedores.length > 0 ? (
                                listaTabelasVendedores.map((item, index) => {
                                  return (
                                    <div className="wrapper wpp-vendedor">
                                      <div
                                        className="container-grafico-tabela"
                                        key={index}
                                      >
                                        <div>
                                          <div
                                            className="div-nome-funcionario"
                                            style={{
                                              backgroundColor:
                                                item[0].customStyles,
                                            }}
                                          >
                                            {item[0].funcionario}
                                            {/* {item[0].metaAlcancada == 0 ? ('🤷🏻‍♀️') : (item[0].metaAlcancada == 1 ? ('🚀') : (item[0].metaAlcancada == 2 ? ('🥇') : (item[0].metaAlcancada == 3 ? ('💎') : ('🤗'))))} */}
                                          </div>
                                          <div className="div-imagem-funcionario">
                                            <img
                                              className="img-login"
                                              src={item[0].foto}
                                              alt="Imagem de perfil"
                                              onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src =
                                                  "https://aprincipalbb.com.br/api/powerbi/img/noprofilepicture.png";
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <DataTable
                                          columns={
                                            listaColunasVendedores[index]
                                          }
                                          data={item}
                                          noHeader={true}
                                          dense={true}
                                          striped={true}
                                          theme="solarized"
                                          customStyles={{
                                            headCells: {
                                              style: {
                                                backgroundColor:
                                                  item[0].customStyles,
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <span>Nenhum vendedor encontrado</span>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </Container>
                  </>
                </div>
              </div>
            </Container>
          </>
        ) : (
          <>
            {carregando ? <Loading carregando={carregando} /> : <AlertaLogin />}
          </>
        )}
      </div>
    </>
  );
}

export default MetasVendedorApp;
