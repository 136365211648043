import { createContext, useEffect, useState } from "react";
import { LoadingTable } from "../../../components/Loading/LoadingTable";
import Title from "../../../components/title";
import { PermissionsPage } from "../../../util/permissionsPage";
import metas from "../../../services/metas";
import { Button, Stack } from "@mui/material";
import ErrorToastHandler from "../../../util/ErrorToastHandler";
import { addDays } from "date-fns";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { DateRages } from "../../../components/componentsCadastroMetas/DateRanges";
import { CardStore } from "../../../components/componentsCadastroMetas/CardStore";
import { storageDataCadastroMetasSave } from "../../../storage/storageCadastroMetas";
import { api } from "../../../services/api.v2";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
export const FormContext = createContext();
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");

// Adiciona os plugins necessários ao dayjs
dayjs.extend(utc);
dayjs.extend(timezone);
function converterFormato(dataString) {
  const data = dayjs(dataString).add(3, "hours");
  const dataFormatada = data.toDate();
  return dataFormatada;
}

function getWeeksInMonth(year, month) {
  const weeks = [];
  const firstDay = dayjs(`${year}-${month}-01`);
  const lastDay = firstDay.endOf("month");

  let currentDay = firstDay;
  let currentWeekEnd = currentDay.add(6, "day");

  for (let i = 0; i < 3; i++) {
    weeks.push({
      start: currentDay.format("YYYY-MM-DD"),
      end: currentWeekEnd.format("YYYY-MM-DD"),
    });

    currentDay = currentWeekEnd.add(1, "day");
    currentWeekEnd = currentDay.add(6, "day");
  }

  weeks.push({
    start: currentDay.format("YYYY-MM-DD"),
    end: lastDay.format("YYYY-MM-DD"),
  });

  return weeks;
}

const getInitWeeks = () => {
  const currentDate = dayjs(); // Obtém a data atual

  const currentYear = currentDate.year(); // Obtém o ano atual
  const currentMonth = currentDate.month() + 1;

  const weeks = getWeeksInMonth(currentYear, currentMonth);
  const weeksSelect = {};

  weeks.forEach((item, index) => {
    weeksSelect[`selection${index + 1}`] = {
      startDate: addDays(new Date(item.start), 1),
      endDate: addDays(new Date(item.end), 1),
      key: `selection${index + 1}`,
    };
  });
  return weeksSelect;
};
function isObjectOrArray(value) {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}
export const CadastroMetas = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);

  const methods = useForm();
  const { reset, handleSubmit } = methods;
  const params = useParams();

  const [periodsSelects, setPeriodsSelects] = useState(getInitWeeks);
  const [funcionariosNaoSelecionados, setFuncionariosNaoSelecionados] =
    useState([]);
  const [dataPage, setDataPage] = useState([]);

  const formatterDataPage = (data, fristLoad = true) => {
    const dataReset = {};
    if (fristLoad) {
    }
    data.forEach((store) => {
      Object.keys(fristLoad ? periodsSelects : store.periodos).forEach(
        (key) => {
          const copyPeriod = { ...store };
          let peridosNaoEspecial = 0;
          let metaVendaEspecial = 0;
          const copyAuxPeriod = { ...copyPeriod };
          if (copyAuxPeriod?.periodos) {
            Object.keys(copyAuxPeriod?.periodos).forEach((keyPeriod) => {
              if (copyAuxPeriod?.periodos[keyPeriod]?.metaEspecial) {
                peridosNaoEspecial = peridosNaoEspecial + 1;
                metaVendaEspecial =
                  +metaVendaEspecial +
                  +copyAuxPeriod?.periodos[keyPeriod].metaVenda;
              }
            });
          }
          if (!!copyAuxPeriod?.periodos?.[key]?.metaEspecial) {
            store.periodos = {
              ...store.periodos,
              [key]: {
                ...copyAuxPeriod?.periodos?.[key],
              },
            };
            return;
          }

          delete copyPeriod.periodos;

          store.periodos = {
            ...store.periodos,
            [key]: {
              ...copyPeriod,
              metaVenda:
                (store.metaVenda - metaVendaEspecial) /
                (Object.values(periodsSelects).length - peridosNaoEspecial),
              metaQtdPedidos: Math.ceil(
                store.metaQtdPedidos /
                  (Object.values(periodsSelects).length - peridosNaoEspecial)
              ),
              metaTM:
                store.metaTM /
                (Object.values(periodsSelects).length - peridosNaoEspecial),
              metaEspecial: false,
            },
          };
        }
      );

      const funcionariosDaLojaArray = isObjectOrArray(store?.funcionariosDaLoja)
        ? Object.values(store?.funcionariosDaLoja)
        : store?.funcionariosDaLoja;
      store.funcionariosDaLoja = funcionariosDaLojaArray.map((funcionario) => {
        funcionario.metaVenda =
          store.metaVenda / funcionariosDaLojaArray.length;
        funcionario.metaQtdPedidos = Math.ceil(
          store.metaQtdPedidos / funcionariosDaLojaArray.length
        );
        funcionario.metaTM = funcionario.metaVenda / funcionario.metaQtdPedidos;
        Object.keys(periodsSelects).forEach((key) => {
          const copyPeriod = { ...funcionario };
          const copyPeriodAux = { ...copyPeriod };
          delete copyPeriod.periodos;
          if (copyPeriodAux?.periodos?.[key]?.metaEspecial) {
            console.log(copyPeriodAux?.periodos?.[key]);
          }
          funcionario.periodos = {
            ...funcionario.periodos,
            [key]: {
              ...copyPeriod,
              metaVenda:
                funcionario.metaVenda / Object.values(periodsSelects).length,
              metaQtdPedidos: Math.ceil(
                funcionario.metaQtdPedidos /
                  Object.values(periodsSelects).length
              ),
              metaTM: funcionario.metaTM / Object.values(periodsSelects).length,
              metaEspecial: false,
            },
          };
        });
        return funcionario;
      });
      let funcionariosDaLoja = {};
      store.funcionariosDaLoja.forEach((item) => {
        funcionariosDaLoja = {
          ...funcionariosDaLoja,
          [item.CodFunc]: item,
        };
      });

      store.funcionariosDaLoja = funcionariosDaLoja;
      dataReset[store.CodLoja] = store;
    });
    reset(dataReset);
    storageDataCadastroMetasSave(Object.values(dataReset));
  };
  const getDataPage = async () => {
    setIsLoadingData(true);
    try {
      const response = await metas.getDataPage();
      let response2;
      if (params?.id) {
        response2 = await metas.getEditData(params?.id);
        const dataAux = {};
        Object.keys(response2.data.metas).forEach((key) => {
          dataAux[response2.data.metas[key].CodLoja] =
            response2.data.metas[key];
        });
        reset(dataAux);
        storageDataCadastroMetasSave(response2.data.metas);
        const peridosAux = {};
        response2.data.periodos.forEach((item, index) => {
          peridosAux[`selection${index + 1}`] = {
            ...item,
            endDate: converterFormato(item.dataFim),
            startDate: converterFormato(item.dataInicio),
            key: `selection${index + 1}`,
          };
        });
        console.log({ peridosAux });
        setPeriodsSelects(peridosAux);
      } else {
        response2 = await metas.getData();
        formatterDataPage(response2.data.metas);
      }

      setDataPage(response2.data.metas);

      setFuncionariosNaoSelecionados(
        response.data.data.funcionariosNaoSelecionados
      );
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoadingData(false);
    }
  };

  const onSubmit = async (data) => {
    setIsLoadingData(true);
    const periodos = {};
    Object.keys(periodsSelects).forEach((key) => {
      console.log(periodsSelects[key]);
      periodos[key] = {
        ...periodsSelects[key],
        dataInicio: dayjs(new Date(periodsSelects[key].startDate)).format(
          "YYYY-MM-DD"
        ),
        dataFim: dayjs(new Date(periodsSelects[key].endDate)).format(
          "YYYY-MM-DD"
        ),
      };
    });
    if (params?.id) {
      const formattedData = {
        metas: data,
        periodoInicio: dayjs().startOf("month").format("YYYY-MM-DD"),
        periodoFim: dayjs().endOf("month").format("YYYY-MM-DD"),
        periodos,
        descricao: `${dayjs().format("MMM").toUpperCase()}/${dayjs().format(
          "YY"
        )}`,
      };
      try {
        const response = await api.put(
          `/targets/sales/${params.id}`,
          formattedData
        );
        toast.success(response.data.msg);
      } catch (error) {
        new ErrorToastHandler(error).showErrorToast();
      } finally {
        setIsLoadingData(false);
      }
    } else {
      const formattedData = {
        metas: data,
        periodoInicio: dayjs().startOf("month").format("YYYY-MM-DD"),
        periodoFim: dayjs().endOf("month").format("YYYY-MM-DD"),
        periodos,
        descricao: `${dayjs().format("MMM").toUpperCase()}/${dayjs().format(
          "YY"
        )}`,
      };
      try {
        const response = await api.post("/targets/sales", formattedData);
        toast.success(response.data.msg);
      } catch (error) {
        new ErrorToastHandler(error).showErrorToast();
      } finally {
        setIsLoadingData(false);
      }
    }
  };

  useEffect(() => {
    formatterDataPage(dataPage);
  }, [periodsSelects]);

  useEffect(() => {
    getDataPage();
  }, []);

  return (
    <FormContext.Provider
      value={{
        funcionariosNaoSelecionados,
        setFuncionariosNaoSelecionados,
        setDataPage,
        dataPage,
        periodsSelects,
        formatterDataPage,
        setPeriodsSelects,
        isLoadingData,
      }}
    >
      <Title title="Cadastro de Metas" />
      <FormProvider {...methods}>
        {PermissionsPage("list_interested") && (
          <>
            <>
              <DateRages />
              {isLoadingData ? (
                <LoadingTable />
              ) : (
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack gap={2}>
                    {dataPage.map((item) => (
                      <CardStore key={item.CodLoja} item={item} />
                    ))}
                  </Stack>
                  <Stack py={2} direction={"row"} justifyContent={"flex-end"}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="aprincipal"
                    >
                      Salvar e enviar
                    </Button>
                  </Stack>
                </form>
              )}
            </>
          </>
        )}
      </FormProvider>
    </FormContext.Provider>
  );
};
