import React, { useEffect, useState } from "react";
import { createTheme } from "react-data-table-component";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import Title from "../../components/title";
import { useLocation } from "react-router";
import { arrayBases } from "../../util/loja";
import queryString from "query-string";

import { PermissionsPage } from "../../util/permissionsPage";
import { useUserData } from "../../hooks/useUserData";
import {
  Box,
  Paper,
  Skeleton,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SelectFilterMonth } from "../../components/FilterLojaMetas/SelectMes";
import { SelectLoja } from "../../components/FilterLojaMetas/SelectLoja";
import { isEmptyObject } from "../../util/isEmptyObject";
import ErrorToastHandler from "../../util/ErrorToastHandler";
import { ListSeller } from "../../components/componentsMetaVendedor/ListSeller/ListSeller";
import { dataChart, optionsChartBar } from "../../dataCharts/metas-vendedor";
import { SelectBases } from "../../components/SelectBases/SelectBases";
import { formatShopAvailable } from "../../formatdata/formatter-shop-available";
import { DatePikerFilter } from "../../components/DatePikerFilter/DatePikerFilter";
import dayjs from "dayjs";
import { storageStoresGet } from "../../storage/storageStores";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import metasVendedorPeriodo from "../../services/metasVendedorPeriodo";

function compararPorMetaRealizada(a, b) {
  if (a.metaRealizada < b.metaRealizada) {
    return 1;
  } else if (a.metaRealizada > b.metaRealizada) {
    return -1;
  } else {
    return 0;
  }
}

function MetasVendedorPeriodo() {
  const theme = useTheme();
  const defaultValues = {
    base: null,
    periodo: null,
    first_period: dayjs().startOf("month").format("YYYY-MM-DD"),
    final_period: dayjs().format("YYYY-MM-DD"),
  };
  const [paramsConfig, setParamsConfig] = useState(defaultValues);
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [availableStore, setAvailableStore] = useState();
  const [storesSelect, setStoreSelect] = useState(null);
  const [dataBackup, setDataBackup] = useState({});
  const [periodosDisponiveis, setPeriodosDisponiveis] = useState([]);
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataPage, setDataPage] = useState([]);

  const history = useHistory();

  const { search } = useLocation();
  const { userData } = useUserData();

  const storeId = new URLSearchParams(search).get("loja")
    ? new URLSearchParams(search).get("loja")
    : false;
  const isApp = new URLSearchParams(search).get("app")
    ? new URLSearchParams(search).get("app")
    : false;

  function selecionarPeriodo(value) {
    if (value && value.periodo && typeof value.periodo === "string") {
      const periodArray = value.periodo.split(" à ").map((item) => item.trim());

      if (Array.isArray(periodArray) && periodArray.length === 2) {
        const [startDayMonth, endDayMonth] = periodArray;

        // Convertendo o formato de "dd/mm" para "mm/dd" usando Day.js
        const [startMonth, startDay] = startDayMonth.split("/").map(Number);
        const [endMonth, endDay] = endDayMonth.split("/").map(Number);

        const start = dayjs()
          .set("month", startMonth - 1)
          .set("date", startDay);
        const end = dayjs()
          .set("month", endMonth - 1)
          .set("date", endDay);

        setParamsConfig((old) => ({
          ...old,
          periodo: periodArray,
          periodoInicio: start.toISOString(),
          periodoFim: end.toISOString(),
        }));
      } else {
        console.error("O formato do período não é válido.");
      }
    } else {
      console.error("O valor do período é inválido ou está ausente.");
      console.log(value);
      console.log(typeof value);
      console.log(value.periodo);
      console.log(typeof value.periodo);
      console.log(paramsConfig.periodo);
    }
  }

  const getElementAtEvent = (element) => {
    if (!element.length) return;
    //const { _index: index, _xScale: eixoX } = element[0];
    //var nomeVendedora = eixoX.ticks[index];
    //if (nomeVendedora === vendedoraSelecionada) {
    //resetarGraficos();
    // }
  };

  createTheme("solarized", {
    divider: {
      default: "transparent",
    },
  });

  const getDataPage = async () => {
    try {
      setIsLoading(true);
      const response = await metasVendedorPeriodo.get({
        ...paramsConfig,
        periodoInputFim: paramsConfig.final_period,
        periodoInputInicio: paramsConfig.first_period,
      });
      setDataBackup(response.data?.metas);

      setDataPage(
        response.data?.metas[storesSelect?.value].sort(compararPorMetaRealizada)
      );
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    } finally {
      setIsLoading(false);
    }
  };
  const getAvailablePeriods = async (idLoja) => {
    try {
      return await metasVendedorPeriodo.getAvailablePeriods(idLoja);
    } catch (error) {
      new ErrorToastHandler(error).showErrorToast();
    }
  };
  const loadAvailableStores = (value) => {
    const shopsAvailableForBase = formatShopAvailable(
      [value],
      userData.nivelAcesso
    );
    setAvailableStore(shopsAvailableForBase);
    return shopsAvailableForBase;
  };
  const onChangeParamsConfigBase = (value) => {
    const stores = loadAvailableStores(value);
    setStoreSelect(stores[0]);
    setParamsConfig((old) => ({
      ...old,
      base: value,
    }));
  };
  const onChangeLojas = (data) => {
    setStoreSelect(data);
    Object.keys(dataBackup).forEach((key) => {
      if (+key === data.value) {
        setDataPage(dataBackup[key].sort(compararPorMetaRealizada));
      }
    });
  };
  const onChangeDatePicker = (value) => {
    setParamsConfig((old) => ({
      ...old,
      ...value,
    }));
  };

  useEffect(() => {
    if (
      !isEmptyObject(userData) &&
      !!paramsConfig.base &&
      !!paramsConfig.periodo
    ) {
      getDataPage();
    }
  }, [userData, paramsConfig]);
  const getInfoInitialDataPage = async () => {
    const idLoja = userData.nivelAcesso[0];
    const idBase = userData.baseAcesso[0];
    const periods = await getAvailablePeriods(idLoja);
    setPeriodosDisponiveis(periods.data);
    const basesDisp = arrayBases(userData.baseAcesso);
    setBasesDisponiveis(basesDisp);
    if (!!storeId) {
      const allShop = storageStoresGet();

      const shopCurrency = allShop.find((item) => +item.id === +storeId);

      if (!userData.nivelAcesso.find((item) => item === +storeId)) {
        toast.error("Você não possui permissão para acessar essa Loja");
        history.goBack();
        return;
      }
      if (shopCurrency) {
        setStoreSelect({
          value: shopCurrency.id,
          label: `${shopCurrency.id} ${shopCurrency.sigla}`,
        });
        setParamsConfig((old) => ({
          ...old,
          periodo: periods.data[0]?.id,
          base: shopCurrency.base,
        }));
        const newSearch = queryString.stringify({});
        history.replace({ search: newSearch });
      }
    } else {
      const stores = loadAvailableStores(idBase);
      setStoreSelect(stores[0]);
      setParamsConfig((old) => ({
        ...old,
        periodo: periods.data[0]?.id,
        base: userData.baseAcesso[0],
      }));
    }
  };

  useEffect(() => {
    const subscribe = async () => {
      if (!isEmptyObject(userData)) {
        getInfoInitialDataPage();
      }
    };
    subscribe();
  }, [userData]);

  return (
    <>
      {PermissionsPage("metasVendasVendedor") && (
        <>
          {!isApp && <Title title="Metas de Vendedor por Periodo" />}
          {!isApp && (
            <Box
              pl={[0, 2]}
              display={"flex"}
              flexDirection={["column", "row"]}
              width={"100%"}>
              <Box
                width={["100%", "auto"]}
                display={["flex", "block"]}
                alignItems={"center"}
                justifyContent={"center"}>
                <DatePikerFilter
                  paramsConfig={paramsConfig}
                  onChange={onChangeDatePicker}
                  isLoading={isLoading}
                />
              </Box>
              <Box width={"100%"} px={2}>
                <SelectBases
                  basesDisponiveis={basesDisponiveis}
                  onChange={onChangeParamsConfigBase}
                  paramsConfig={paramsConfig}
                  isLoading={isLoading}
                />

                <SelectLoja
                  options={availableStore}
                  isDisabled={isLoading}
                  onChange={onChangeLojas}
                  value={storesSelect}
                />
                <Box mt={1} width={"100%"}>
                  <SelectFilterMonth
                    isDisabled={isLoading}
                    periodoReferencia={paramsConfig.periodo}
                    periodosDisponiveis={periodosDisponiveis}
                    selecionarPeriodo={selecionarPeriodo}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <>
            <Stack
              alignItems={"center"}
              justifyContent={"center"}
              m={2}
              overflow={"hidden"}>
              {!isLoading ? (
                <Box
                  my={[2, 0]}
                  pt={2}
                  maxWidth={"80vw"}
                  sx={{ overflowX: ["scroll", "auto"] }}
                  height={["auto"]}
                  component={Paper}
                  variant="outlined"
                  elevation={isSm ? 2 : 0}>
                  <Bar
                    height={!isApp || !isSm ? 500 : undefined}
                    width={!isApp || !isSm ? 600 : undefined}
                    data={dataChart({
                      isApp: isApp || !isSm,
                      dataPage,
                    })}
                    options={optionsChartBar({
                      dataPage,
                      isApp: isApp || !isSm,
                    })}
                    getElementAtEvent={getElementAtEvent}
                    redraw
                  />
                </Box>
              ) : (
                <Skeleton variant="rounded" width={600} height={500} />
              )}
            </Stack>
            <ListSeller dataPage={dataPage} isLoading={isLoading} />
          </>
        </>
      )}
    </>
  );
}

export default MetasVendedorPeriodo;
