import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Bar, Doughnut } from "react-chartjs-2";
// import api from '../../services/api';
import "chartjs-plugin-datalabels";
import ScaleLoader from "react-spinners/ScaleLoader";
import Title from "../../components/title";
import api from "../../services/api";
import MenuLateral from "../../components/menu";
import ReactFullscreen from "react-easyfullscreen";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
// import GaugeChart from 'react-gauge-chart'
// import FotoPerfil from '../../assets/noprofilepicture.png';
import ReactSpeedometer from "react-d3-speedometer";
import { useHistory } from "react-router";
import { metasService } from "../../services/metasServices";
import FilterBases from "../../components/filterBases";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { FilterLoja } from "../../components/FilterLojaApp";
import FilterMes from "../../components/FilterMes";
import { arrayBases, converterLoja } from "../../util/loja";
import {
  colunasAnaliseMetasGerais,
  colunasMetasGerais,
  customStylesTable,
} from "../../util/tables";
import Loading from "../../components/Loading/Loading";
import NavBarAll from "../../components/NavBarAll/NavBarAll";
import Alerta from "../../components/Alerta";
import AlertaLogin from "../../components/AlertaLogin";
import { bNivelAcesso } from "../../util/login";
import FilterBasesApp from "../../components/filterBasesApp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";

function MetasApp({ match, navigation }) {
  const [nivelAcesso, setNivelAcesso] = useState([]);
  const [baseAcesso, setBaseAcesso] = useState([]);
  const [dataMetasFromApi, setDataMetasFromApi] = useState([]);
  const [dataDescontoParaGrafico, setDataDescontoParaGrafico] = useState([]);
  const [labelsChart, setlabelsChart] = useState([]);
  const [labelsChartCod, setlabelsChartCod] = useState([]);
  const [labelsChartDesconto, setlabelsChartDesconto] = useState([]);
  const [labelsChartDescontoCod, setlabelsChartDescontoCod] = useState([]);
  const [resumoTable, setResumoTable] = useState([]);
  const [resumoAnaliseTable, setResumoAnaliseTable] = useState([]);
  const [metaParaLabel, setMetaParaLabel] = useState(0);
  const [totalRealizadoParaLabel, setTotalRealizadoParaLabel] = useState(0);
  const [metaValorFormatadoSufixo, setMetaValorFormatadoSufixo] = useState(0);
  const [realizadoValorFormatadoSufixo, setRealizadoValorFormatadoSufixo] =
    useState(0);
  const [zeroValorFormatadoSufixo, setZeroValorFormatadoSufixo] = useState(0);
  const [dataDescontoAplicadoFromApi, setDataDescontoAplicadoFromApi] =
    useState([]);
  const [dataChartBarFromApi, setDataChartBarFromApi] = useState([]);
  const [dataMetaOuro, setDataMetaOuro] = useState([]);
  const [dataMetaDiamante, setDataMetaDiamante] = useState([]);
  const [dataChartLineFromApi, setDataChartLineFromApi] = useState([]);
  const [metaTotal, setMetaTotal] = useState(0);
  const [realizadoTotal, setRealizadoTotal] = useState(0);
  const [dataChartProjetadaFromApi, setDataChartProjetadaFromApi] = useState(
    []
  );
  const [dataTableFromApi, setDataTableFromApi] = useState([]);
  const [periodoReferencia, setPeriodoReferencia] = useState("");
  const [dadosDisponiveis, setDadosDisponiveis] = useState(false);
  const [carregando, setCarregando] = useState(true);
  const [periodosDisponiveis, setPeriodosDisponiveis] = useState([]);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [permissoes, setPermissoes] = useState(false);
  const [periodoInputInicio, setPeriodoInputInicio] = useState(false);
  const [periodoInputFim, setPeriodoInputFim] = useState(false);
  const [periodoInputInicioAux, setPeriodoInputInicioAux] = useState("");
  const [periodoInputFimAux, setPeriodoInputFimAux] = useState("");
  const [periodoMinimo, setPeriodoMinimo] = useState("");
  const [periodoMaximo, setPeriodoMaximo] = useState("");
  const [labelsTable, setLabelsTable] = useState([]);
  const [empresasSelecionadas, setEmpresasSelecionadas] = useState([]);
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);
  const [dadosCompletos, setDadosCompletos] = useState([]);
  const [vendedorasAbaixaMeta, setVendedorasAbaixoMeta] = useState([]);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [showVendedorasAbaixoMeta, setShowVendedorasAbaixoMeta] =
    useState(false);
  const [userInfo, setUserInfo] = useState("");

  useEffect(() => {
    let id = match.params.id;
    let token = match.params.token;
    window.location.href = `https://bi.aprincipalbb.com.br/metas/metas-gerais/${id}/${token}?app=y`;
  }, []);

  function buscarNivelAcesso() {
    let id = match.params.id;
    let token = match.params.token;
    const response = api
      .get("/powerbi/request.php", {
        params: {
          entity: "login",
          entityData: JSON.stringify({ id, token }),
          entityFunction: "getNivelAcesso",
        },
      })
      .then(async (data) => {
        console.log(data);
        if (data.data[0] && data.data[0].nivelAcesso) {
          setNivelAcesso(data.data[0].nivelAcesso);
          setBaseAcesso(data.data[0].baseAcesso);
          let infoUser = [];
          infoUser.push(data.data[0].nome);
          infoUser.push(data.data[0].setor);
          setUserInfo(infoUser);
        } else {
          setCarregando(false);
          setIsLoading(false);
        }
        if (data.data[0].siglasLojasCadastradas) {
          localStorage.setItem(
            "siglasLojasCadastradas",
            JSON.stringify(data.data[0].siglasLojasCadastradas)
          );
        }
        if (data.data[0] && data.data[0].root) {
          setPermissoes(bNivelAcesso(JSON.parse(data.data[0].root)));
        }
      })
      .catch((error) => {
        //console.log(error);
      });
  }
  function preencherPeriodo() {
    api
      .get("/powerbi/request.php", {
        params: {
          entity: "metas",
          entityData: "periodos",
          entityFunction: "periodosDisponiveisV2",
        },
      })
      .then((data) => {
        //console.log(data);
        var periodos = [];
        if (
          data &&
          data.data.status === "success" &&
          data.data.type &&
          data.data.data.periodos &&
          data.data.data.periodos.length > 0
        ) {
          //console.log('here');
          var dataAux = data.data.data.periodos;
          var periodoAtual = "";
          let dataAtual = new Date();
          dataAux.map((item, index) => {
            let dataInicioTemp = new Date(item.periodoInicio);
            if (
              periodoAtual === "" &&
              dataAtual.getFullYear() === dataInicioTemp.getFullYear() &&
              dataAtual.getMonth() === dataInicioTemp.getMonth()
            ) {
              periodoAtual = item.id;
            }
            periodos.push({ periodo: item.periodo, id: item.id });
          });
          //console.log(periodoAtual);
          if (periodoAtual === "") {
            periodoAtual = dataAux[0].id;
          }
          //console.log(periodoAtual);
          setPeriodoReferencia(periodoAtual);
          setPeriodosDisponiveis(periodos);
        } else {
          preencherPeriodosCasoErro();
        }
      })
      .catch((error) => {
        //console.log('there');
        //console.log(error);
        preencherPeriodosCasoErro();
      });
  }

  function direcionarParaMetasPorVendedorasLojaEspecifica(loja) {
    let id = match.params.id;
    let token = match.params.token;
    history.push(`/metas/metas-vendedor/${id}/${token}?loja=${loja}`);
  }

  //função que retorna o dia da semana

  function preencherPeriodosCasoErro() {
    //console.log('here 2');
    var periodos = [];
    var dataAtual = new Date();
    for (let index = 0; index < 12; index++) {
      var mes = dataAtual.getMonth() + 1;
      var ano = dataAtual.getFullYear();
      if (mes < 10) {
        mes = "0" + mes;
      }
      var periodo = ano + "/" + mes;

      periodos.push({ periodo, id: index });
      dataAtual.setMonth(dataAtual.getMonth() - 1);
    }
    setPeriodoReferencia(periodos[0].id);
    setPeriodosDisponiveis(periodos);
  }

  function preencherDiasUteis(
    inicio,
    fim,
    diasRestantes = false,
    diasRemovidos = false
  ) {
    if (diasRestantes && (!periodoInputInicio || !periodoInputFim)) {
      var dataInicio = new Date();
      var dataFim = new Date(fim);
    } else if (diasRestantes && periodoInputInicio && periodoInputFim) {
      var dataInicio = new Date(
        periodoInputFim.replace("-", "/").replace("-", "/").replace("-", "/")
      );
      var dataFim = new Date(fim);
    } else if (diasRestantes) {
      var dataInicio = new Date();
      var dataFim = new Date(fim);
    } else {
      if (periodoInputInicio && periodoInputFim) {
        var dataInicio = new Date(
          periodoInputInicio
            .replace("-", "/")
            .replace("-", "/")
            .replace("-", "/")
        );
        var dataFimAux = new Date(periodoInputFim);
      } else {
        var dataInicio = new Date(inicio);
        var dataFimAux = new Date(fim);
      }
      var dataFim = new Date();
      if (dataFim > dataFimAux) dataFim = dataFimAux;
      if (!periodoInputInicio || !periodoInputFim) {
        dataFim.setDate(dataFim.getDate() - 1);
      }
    }
    dataFim.setHours(23);
    dataFim.setMinutes(59);
    dataFim.setSeconds(59);
    var dataAtual = dataInicio;
    var diasUteis = 0;

    while (dataAtual <= dataFim) {
      // if (dataAtual.getDay() != 0) {
      //     diasUteis++
      // }
      let ano = dataAtual.getFullYear();
      let mes =
        dataAtual.getMonth() + 1 < 10
          ? "0" + (dataAtual.getMonth() + 1)
          : dataAtual.getMonth() + 1;
      let dia =
        dataAtual.getDate() < 10
          ? "0" + dataAtual.getDate()
          : dataAtual.getDate();
      let dataAtualTemp = ano + "" + mes + "" + dia;
      if (diasRemovidos && diasRemovidos.indexOf(dataAtualTemp) < 0) {
        diasUteis++;
      }
      dataAtual.setDate(dataAtual.getDate() + 1);
    }
    return diasUteis;
  }

  async function preencherDados() {
    setCarregando(true);
    setIsLoading(true);
    window.scrollTo(0, 0);
    try {
      const { data } = await metasService.listaMetas({
        periodo: periodoReferencia,
        nivelAcesso,
        baseAcesso,
        periodoInputInicio,
        periodoInputFim,
      });
      // return;
      if (data?.status === "success" && data?.type) {
        let dataTodasLojas = [];
        let dataInicioTemp = "";
        let dataFimTemp = "";
        let basesDisponiveisAux = [];

        data.data.map((a) => {
          let respostaLojas = a.response;
          if (respostaLojas) {
            let dataRespostaLojas = respostaLojas.data?.realizadaPorLoja;
            dataInicioTemp =
              dataInicioTemp !== "" ? dataInicioTemp : respostaLojas.inicio;
            dataFimTemp = dataFimTemp !== "" ? dataFimTemp : respostaLojas.fim;
            if (dataRespostaLojas?.length > 0) {
              dataRespostaLojas.map((b) => {
                dataTodasLojas.push(b);
              });
            }
          }
        });

        setBasesDisponiveis(arrayBases(data.data));
        if (dataInicioTemp && dataFimTemp) {
          let inicioFormatado = dataInicioTemp.split(" ")[0];
          let fimFormatado = dataFimTemp.split(" ")[0];
          if (!periodoInputInicio || !periodoInputFim) {
            setPeriodoInputInicioAux(inicioFormatado);
            setPeriodoInputFimAux(fimFormatado);
          }
          setDataInicio(dataInicioTemp);
          setDataFim(dataFimTemp);

          setPeriodoMinimo(inicioFormatado);
          setPeriodoMaximo(fimFormatado);
        }

        processaDadosLoja(dataTodasLojas, dataInicioTemp, dataFimTemp);
        setDadosCompletos(dataTodasLojas);
      } else {
        setCarregando(false);
        setDadosDisponiveis(false);
        setIsLoading(false);
      }
      //})
    } catch (error) {}
  }

  function processaDadosLoja(dataTodasLojas, dataInicioTemp, dataFimTemp) {
    console.log(dataTodasLojas);
    console.log(nivelAcesso);
    let metaSugerida = [];
    let metaOuroSugerida = [];
    let metaDiamanteSugerida = [];
    let metaRealizada = [];
    let labelNomes = [];
    let labelNomesParaFiltro = [];
    let empresasSelecionadasTemp = [];
    let basesSelecionadasTemp = [];
    let dadosTabela = [];
    let diasUteisGeral = 0;
    let diasRestantesGeral = 0;
    let qtdLojas = 0;
    let projetada = [];
    let desconto = [];
    let labelCod = [];
    let descontoParaGrafico = [];
    let totalMeta = 0;
    let totalRealizada = 0;
    let totalPorcentagem = 0;
    let totalDefictMensal = 0;
    let totalDeficitDiario = 0;
    let totalProjecao = 0;
    let resumoChart = [];
    let resumoAnaliseChart = [];
    let auxTotalRealizado = 0;
    var dataMetas = dataTodasLojas;
    console.log(dataTodasLojas);
    dataMetas = dataMetas.sort((a, b) => a.CodLoja - b.CodLoja);
    //let realizadaPorLoja = data.data.data.realizadaPorLoja;
    dataMetas = dataMetas.sort((a, b) => a.CodLoja - b.CodLoja);
    if (dataMetas.length > 0) {
      dataMetas.map((item) => {
        auxTotalRealizado += parseFloat(item.MetaRealizada);
      });
      auxTotalRealizado = auxTotalRealizado > 0 ? auxTotalRealizado : 1;
      setDataMetasFromApi(dataMetas);
      //// console.log(dataMetas);
      if (dataMetas && dataMetas.length > 0) {
        dataMetas = dataMetas.sort((a, b) => b.MetaRealizada - a.MetaRealizada);
        dataMetas.map(async (item) => {
          console.log(item);
          basesSelecionadasTemp.push(item.CodBase);
          labelNomes.push(converterLoja(item.CodLoja));
          labelCod.push(item.CodLoja);
          metaSugerida.push(parseFloat(item.MetaProjetada));
          metaOuroSugerida.push(parseFloat(item.MetaProjetada) * 1.15);
          metaDiamanteSugerida.push(parseFloat(item.MetaProjetada) * 1.3);
          metaRealizada.push(item.MetaRealizada);
          let diasRemovidos = [];
          if (item.diasRemovidos) {
            diasRemovidos = item.diasRemovidos.split(",");
          }

          var metaSugeridaTabela = !isNaN(parseFloat(item.MetaProjetada))
            ? parseFloat(item.MetaProjetada)
            : 0;

          var metaRealizadaTabela = !isNaN(parseFloat(item.MetaRealizada))
            ? parseFloat(item.MetaRealizada)
            : 0;

          var metaProjetadaTabela = !isNaN(parseFloat(item.MetaProjetada))
            ? parseFloat(item.MetaProjetada)
            : 0;

          var porcentagemMeta =
            metaSugeridaTabela > 0
              ? ((metaRealizadaTabela / metaSugeridaTabela) * 100).toFixed(2)
              : "*";

          desconto.push(
            parseFloat(item.MetaRealizada) + parseFloat(item.DescontoAplicado)
          );
          descontoParaGrafico.push({
            loja: item.nomeLoja,
            valor: parseFloat(item.DescontoAplicado),
            codLoja: item.CodLoja,
          });
          totalMeta += metaSugeridaTabela;
          totalRealizada += metaRealizadaTabela;
          totalPorcentagem +=
            porcentagemMeta !== "*" && !isNaN(porcentagemMeta)
              ? parseFloat(porcentagemMeta)
              : 0;
          totalDefictMensal += 0;
          totalDeficitDiario += 0;
          totalProjecao += metaProjetadaTabela;
          //let diasUteis = preencherDiasUteis(dataInicioTemp, dataFimTemp);
          //let diasUteis = item.diasUteis;
          let diasUteis = preencherDiasUteis(
            dataInicioTemp,
            dataFimTemp,
            false,
            diasRemovidos
          );
          diasUteisGeral += parseInt(diasUteis);
          qtdLojas++;
          let diasUteisRestantes = preencherDiasUteis(
            0,
            dataFimTemp,
            true,
            diasRemovidos
          );
          diasRestantesGeral += parseInt(diasUteisRestantes);
          let mediaAtual =
            diasUteis > 0
              ? metaRealizadaTabela / diasUteis
              : metaRealizadaTabela;
          let projecaoFinal =
            mediaAtual && diasUteisRestantes > 0
              ? mediaAtual * diasUteisRestantes + metaRealizadaTabela
              : metaRealizadaTabela;
          let deficitMensal =
            metaSugeridaTabela - metaRealizadaTabela > 0
              ? metaSugeridaTabela - metaRealizadaTabela
              : 0;
          let deficitDiario =
            diasUteisRestantes > 0 ? deficitMensal / diasUteisRestantes : 0;
          let representatividade = parseFloat(
            ((metaRealizadaTabela / auxTotalRealizado) * 100).toFixed(2)
          );
          projetada.push(projecaoFinal);

          let metaAlcancada = (projecaoFinal / metaProjetadaTabela) * 100;
          let customStyles = "#fff";

          if (metaAlcancada < 100) {
            metaAlcancada = 0;
            customStyles = "#ff0000";
          } else if (metaAlcancada >= 100 && metaAlcancada < 115) {
            metaAlcancada = 1;
            customStyles = "#00b14f";
          } else if (metaAlcancada >= 115 && metaAlcancada < 130) {
            metaAlcancada = 2;
            customStyles = "#f9ff00";
          } else if (metaAlcancada >= 130) {
            metaAlcancada = 3;
            customStyles = "#6395ec";
          }
          dadosTabela.push({
            loja: item.CodLoja,
            meta: metaSugeridaTabela,
            realizada: metaRealizadaTabela,
            porcentagem: porcentagemMeta,
            diasUteis: diasUteis,
            diasFalta: diasUteisRestantes,
            dMensal: deficitMensal,
            dDiario: deficitDiario,
            projecao: projecaoFinal,
            representatividade: representatividade,
            cor: customStyles,
          });
          labelNomesParaFiltro.push({
            codLoja: item.CodLoja,
            nomeLoja: item.nomeLoja,
            diasUteis: diasUteis,
            diasFalta: diasUteisRestantes,
            quantidadeVendedorasAbaixoMeta: [],
          });
          empresasSelecionadasTemp.push(item.CodLoja);
        });

        var metaChartDoughnut;
        var realizadaChartDoughnut;

        // if (totalRealizada > totalMeta) {
        metaChartDoughnut = totalMeta;
        realizadaChartDoughnut = 0;
        setMetaTotal(totalMeta);
        setRealizadoTotal(totalRealizada);

        var metaValorFormatadoSufixo;
        var realizadoValorFormatadoSufixo;
        var zeroValorFormatadoSufixo;
        if (totalMeta / 1000000 >= 1) {
          metaValorFormatadoSufixo = (totalMeta / 1000000).toFixed(2) + " Mi";
          zeroValorFormatadoSufixo = "0,00 Mi";
        } else {
          metaValorFormatadoSufixo = (totalMeta / 1000).toFixed(2) + " Mil";
          zeroValorFormatadoSufixo = "0,00 Mil";
        }

        if (totalRealizada / 1000000 >= 1) {
          realizadoValorFormatadoSufixo =
            (totalRealizada / 1000000).toFixed(2) + " Mi";
        } else {
          realizadoValorFormatadoSufixo =
            (totalRealizada / 1000).toFixed(2) + " Mil";
        }

        totalDefictMensal += 0;
        totalDeficitDiario += 0;
        totalProjecao = totalProjecao.toLocaleString("pt-BR", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: "currency",
          currency: "BRL",
        });
        diasUteisGeral = diasUteisGeral / qtdLojas;
        diasRestantesGeral = diasRestantesGeral / qtdLojas;
        //let diasUteisRestantes = preencherDiasUteis(0, data.data.fim, true);
        let mediaAtual =
          diasUteisGeral > 0 ? totalRealizada / diasUteisGeral : totalRealizada;
        let projecaoFinal =
          mediaAtual && diasRestantesGeral > 0
            ? mediaAtual * diasRestantesGeral + totalRealizada
            : totalRealizada;
        let deficitMensal =
          totalMeta - totalRealizada > 0 ? totalMeta - totalRealizada : 0;
        let deficitDiario =
          diasRestantesGeral > 0 ? deficitMensal / diasRestantesGeral : 0;
        totalPorcentagem = (totalRealizada / totalMeta) * 100;
        diasUteisGeral = diasUteisGeral.toFixed(2);

        diasRestantesGeral = diasRestantesGeral.toFixed(2);
        resumoChart.push({
          loja: "Total",
          meta: totalMeta,
          realizada: totalRealizada,
          porcentagem: totalPorcentagem,
          diasUteis: diasUteisGeral,
          diasFalta: diasRestantesGeral,
          dMensal: deficitMensal,
          dDiario: deficitDiario,
          projecao: projecaoFinal,
          representatividade: 100,
        });
        ////console.log('meta realizada -> ', metaRealizada);
        let porcentagemAbaixoDaMeta =
          ((totalRealizada - totalMeta) / totalMeta) * 100;
        let porcentagemProjetadoAbaixoDaMeta =
          ((projecaoFinal - totalMeta) / totalMeta) * 100;

        let corAbaixoDaMeta = "#fff";

        if (porcentagemAbaixoDaMeta < 0) {
          corAbaixoDaMeta = "#ff0000";
        } else if (
          porcentagemAbaixoDaMeta >= 0 &&
          porcentagemAbaixoDaMeta < 15
        ) {
          corAbaixoDaMeta = "#00b14f";
        } else if (
          porcentagemAbaixoDaMeta >= 15 &&
          porcentagemAbaixoDaMeta < 30
        ) {
          corAbaixoDaMeta = "#f9ff00";
        } else if (porcentagemAbaixoDaMeta >= 30) {
          corAbaixoDaMeta = "#6395ec";
        }
        let corProjetadoAbaixoDaMeta = "#fff";

        if (porcentagemProjetadoAbaixoDaMeta < 0) {
          corProjetadoAbaixoDaMeta = "#ff0000";
        } else if (
          porcentagemProjetadoAbaixoDaMeta >= 0 &&
          porcentagemProjetadoAbaixoDaMeta < 15
        ) {
          corProjetadoAbaixoDaMeta = "#00b14f";
        } else if (
          porcentagemProjetadoAbaixoDaMeta >= 15 &&
          porcentagemProjetadoAbaixoDaMeta < 30
        ) {
          corProjetadoAbaixoDaMeta = "#f9ff00";
        } else if (porcentagemProjetadoAbaixoDaMeta >= 30) {
          corProjetadoAbaixoDaMeta = "#6395ec";
        }

        resumoAnaliseChart.push({
          loja: "",
          meta: "",
          cor: corAbaixoDaMeta,
          corProjetado: corProjetadoAbaixoDaMeta,
          realizada: porcentagemAbaixoDaMeta,
          porcentagem: "",
          diasUteis: "",
          diasFalta: "",
          dMensal: "",
          dDiario: "",
          projecao: porcentagemProjetadoAbaixoDaMeta,
          representatividade: "",
        });
        labelNomesParaFiltro = labelNomesParaFiltro.sort(
          (a, b) => a.codLoja - b.codLoja
        );
        //// console.log(labelNomesParaFiltro);
        descontoParaGrafico = descontoParaGrafico.sort((a, b) => {
          return b.valor - a.valor;
        });
        let descontoParaGraficoTemp = [];
        let labelsChartDesconto = [];
        let labelsChartDescontoCod = [];
        descontoParaGrafico.map(async (a) => {
          descontoParaGraficoTemp.push(a.valor);
          //let itemCodLoja= await converterLoja(a.codLoja, nivelAcesso)
          labelsChartDesconto.push(converterLoja(a.codLoja));
          labelsChartDescontoCod.push(a.codLoja);
        });

        function unique(value, index, self) {
          return self.indexOf(value) === index;
        }

        basesSelecionadasTemp = basesSelecionadasTemp.filter(unique);
        console.log(basesSelecionadasTemp);

        setResumoTable(resumoChart);
        setResumoAnaliseTable(resumoAnaliseChart);
        setDataChartBarFromApi(metaSugerida);
        setDataMetaOuro(metaOuroSugerida);
        setDataMetaDiamante(metaDiamanteSugerida);
        setDataChartLineFromApi(metaRealizada);
        setDataTableFromApi(dadosTabela);
        setlabelsChart(labelNomes);
        setLabelsTable(labelNomesParaFiltro);
        setMetaParaLabel(totalMeta);
        setTotalRealizadoParaLabel(totalRealizada);
        setMetaValorFormatadoSufixo(metaValorFormatadoSufixo);
        setRealizadoValorFormatadoSufixo(realizadoValorFormatadoSufixo);
        setZeroValorFormatadoSufixo(zeroValorFormatadoSufixo);
        setDataChartProjetadaFromApi(projetada);
        setDataDescontoAplicadoFromApi(desconto);
        setlabelsChartCod(labelCod);
        setDataDescontoParaGrafico(descontoParaGraficoTemp);
        setlabelsChartDesconto(labelsChartDesconto);
        setlabelsChartDescontoCod(labelsChartDescontoCod);
        setDadosDisponiveis(true);
        setCarregando(false);
        setIsLoading(false);
        buscarIndicadoresVendedoras(labelNomesParaFiltro);
        setEmpresasSelecionadas(empresasSelecionadasTemp);
        setBasesSelecionadas(basesSelecionadasTemp);
      }
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  }
  async function buscarIndicadoresVendedoras(
    lojasDisponiveisParaAdicionarIndicadores
  ) {
    try {
      const { data } = await metasService.indicadorVendedorasAbaixoMeta({
        baseAcesso,
        nivelAcesso,
        periodo: periodoReferencia,
        periodoInputFim,
        periodoInputInicio,
        periodoInputFim,
        lojas: lojasDisponiveisParaAdicionarIndicadores,
      });
      console.log(data);
      ////console.log(data);
      if (data.status === "success") {
        let dataTemp = data.data;
        console.log(dataTemp);
        let dataTodasLojasTemp = [];
        dataTemp.map((a) => {
          console.log(a);
          if (a.response) {
            dataTodasLojasTemp.push(Object.entries(a.response));
          }
        });
        console.log(dataTodasLojasTemp);
        let dataTodasLojas = [];
        dataTodasLojasTemp.map((a) => {
          a.map((b) => {
            dataTodasLojas.push(b);
          });
        });
        console.log("dataTodasLojas -> ", dataTodasLojas);
        let vendedorasAbaixaMetaTemp = [];

        if (dataTodasLojas?.length > 0) {
          // dataTodasLojas.map( item => {
          //     vendedorasAbaixaMetaTemp.push({'loja': converterLoja(item[0]), 'qtd': item[1]});
          // })
          let vendedoresAbaixoDaMeta = dataTodasLojas; //Object.entries(dataTodasLojas);
          let labelNomesParaFiltroTemp =
            lojasDisponiveisParaAdicionarIndicadores.filter((a) => true);
          vendedoresAbaixoDaMeta.map((a) => {
            let codLoja = a[0];
            let quantidadeVendedorasAbaixoMeta = a[1];
            labelNomesParaFiltroTemp.map((b) => {
              if (b.codLoja === codLoja) {
                for (let i = 0; i < quantidadeVendedorasAbaixoMeta; i++) {
                  b.quantidadeVendedorasAbaixoMeta.push(1);
                }
              }
            });
          });

          console.log(labelNomesParaFiltroTemp);
          setLabelsTable(labelNomesParaFiltroTemp);
        }
        console.log(vendedorasAbaixaMetaTemp);
        // setVendedorasAbaixoMeta(vendedorasAbaixaMetaTemp);
      }
    } catch (error) {}
  }

  function formatarNumero(numero, money = true, decimal = 2) {
    if (money) {
      return numero.toLocaleString("pt-BR", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
        style: "currency",
        currency: "BRL",
      });
    } else {
      return numero.toLocaleString("pt-BR", {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal,
      });
    }
  }

  function filtrarBases(selecionadas) {
    let basesSelecionadasTemp = selecionadas;
    // if (basesSelecionadasTemp.filter(a => a == codBase).length > 0) {
    //     console.log('ok');
    //     basesSelecionadasTemp = basesSelecionadasTemp.filter(a => a != codBase);
    //     console.log(basesSelecionadasTemp);
    // } else {
    //     basesSelecionadasTemp.push(codBase)
    //     console.log(basesSelecionadasTemp);
    // }
    console.log(basesSelecionadasTemp);
    setBasesSelecionadas(basesSelecionadasTemp);
    console.log(dadosCompletos);
    let dadosCompletosFiltrados = [];
    if (basesSelecionadasTemp.length > 0) {
      dadosCompletosFiltrados = dadosCompletos.filter(
        (a) => basesSelecionadasTemp.indexOf(a.CodBase) >= 0
      );
    } else {
      dadosCompletosFiltrados = dadosCompletos;
    }
    console.log(dadosCompletosFiltrados, dataInicio, dataFim);
    processaDadosLoja(dadosCompletosFiltrados, dataInicio, dataFim);
  }

  const localidades = [{ id: 1, localidade: "maraba" }];
  function filtrarEmpresa(selecionadas) {
    setCarregando(true);
    setIsLoading(true);
    window.scrollTo(0, 0);
    console.log(empresasSelecionadas);
    // console.log(codLoja);
    let empresasSelecionadasAux = selecionadas;
    // if (empresasSelecionadasAux.indexOf(codLoja) >= 0) {
    //     empresasSelecionadasAux.splice(empresasSelecionadasAux.indexOf(codLoja), 1);
    // } else {
    //     empresasSelecionadasAux.push(codLoja);
    // }
    setEmpresasSelecionadas(empresasSelecionadasAux);
    let dataMetas = dataMetasFromApi;
    let auxTotalRealizado = 0;
    dataMetas.map((item) => {
      auxTotalRealizado += parseFloat(item.MetaRealizada);
    });
    if (empresasSelecionadasAux.length > 0) {
      dataMetas = dataMetas.filter((a) => {
        return empresasSelecionadasAux.indexOf(a.CodLoja) >= 0 ? true : false;
      });
    }
    let labelsTableAux = labelsTable;
    labelsTableAux = labelsTableAux.filter((a) => true);
    setLabelsTable(labelsTableAux);

    let metaSugerida = [];
    let metaOuroSugerida = [];
    let metaDiamanteSugerida = [];
    let metaRealizada = [];
    let labelNomes = [];
    let dadosTabela = [];
    let projetada = [];
    let desconto = [];
    let labelCod = [];
    let descontoParaGrafico = [];
    let totalMeta = 0;
    let totalRealizada = 0;
    let totalPorcentagem = 0;
    let totalDefictMensal = 0;
    let totalDeficitDiario = 0;
    let totalProjecao = 0;
    let resumoChart = [];
    let resumoAnaliseChart = [];
    let diasUteisGeral = 0;
    let diasRestantesGeral = 0;
    let qtdLojas = 0;

    if (dataMetas && dataMetas.length > 0) {
      dataMetas = dataMetas.sort((a, b) => b.MetaRealizada - a.MetaRealizada);
      dataMetas.map((item) => {
        //let itemCodLoja= await converterLoja(item.CodLoja, nivelAcesso)

        labelNomes.push(converterLoja(item.CodLoja));
        labelCod.push(item.CodLoja);
        //// console.log(diasRemovidos);
        let diasRemovidos = [];
        if (item.diasRemovidos) {
          diasRemovidos = item.diasRemovidos.split(",");
        }
        // labelNomesParaFiltro.push({ codLoja: item.CodLoja, nomeLoja: item.nomeLoja });
        metaSugerida.push(parseFloat(item.MetaProjetada));
        metaOuroSugerida.push(parseFloat(item.MetaProjetada) * 1.15);
        metaDiamanteSugerida.push(parseFloat(item.MetaProjetada) * 1.3);
        metaRealizada.push(item.MetaRealizada);

        var metaSugeridaTabela = !isNaN(parseFloat(item.MetaProjetada))
          ? parseFloat(item.MetaProjetada)
          : 0;

        var metaRealizadaTabela = !isNaN(parseFloat(item.MetaRealizada))
          ? parseFloat(item.MetaRealizada)
          : 0;

        var metaProjetadaTabela = !isNaN(parseFloat(item.MetaProjetada))
          ? parseFloat(item.MetaProjetada)
          : 0;

        var porcentagemMeta =
          metaSugeridaTabela > 0
            ? ((metaRealizadaTabela / metaSugeridaTabela) * 100).toFixed(2)
            : "*";

        desconto.push(
          parseFloat(item.MetaRealizada) + parseFloat(item.DescontoAplicado)
        );
        descontoParaGrafico.push({
          loja: item.nomeLoja,
          valor: parseFloat(item.DescontoAplicado),
          codLoja: item.CodLoja,
        });
        totalMeta += metaSugeridaTabela;
        totalRealizada += metaRealizadaTabela;
        totalPorcentagem +=
          porcentagemMeta !== "*" && !isNaN(porcentagemMeta)
            ? parseFloat(porcentagemMeta)
            : 0;
        totalDefictMensal += 0;
        totalDeficitDiario += 0;
        totalProjecao += metaProjetadaTabela;
        let diasUteis = preencherDiasUteis(
          dataInicio,
          dataFim,
          false,
          diasRemovidos
        );
        diasUteisGeral += parseInt(diasUteis);
        qtdLojas++;
        let diasUteisRestantes = preencherDiasUteis(
          0,
          dataFim,
          true,
          diasRemovidos
        );
        diasRestantesGeral += parseInt(diasUteisRestantes);
        let mediaAtual =
          diasUteis > 0 ? metaRealizadaTabela / diasUteis : metaRealizadaTabela;
        let projecaoFinal =
          mediaAtual && diasUteisRestantes > 0
            ? mediaAtual * diasUteisRestantes + metaRealizadaTabela
            : metaRealizadaTabela;
        let deficitMensal =
          metaSugeridaTabela - metaRealizadaTabela > 0
            ? metaSugeridaTabela - metaRealizadaTabela
            : 0;
        let deficitDiario =
          diasUteisRestantes > 0 ? deficitMensal / diasUteisRestantes : 0;
        let representatividade = parseFloat(
          ((metaRealizadaTabela / auxTotalRealizado) * 100).toFixed(2)
        );
        projetada.push(projecaoFinal);
        let metaAlcancada = (projecaoFinal / metaProjetadaTabela) * 100;
        let customStyles = "#fff";

        if (metaAlcancada < 100) {
          metaAlcancada = 0;
          customStyles = "#ff0000";
        } else if (metaAlcancada >= 100 && metaAlcancada < 115) {
          metaAlcancada = 1;
          customStyles = "#00b14f";
        } else if (metaAlcancada >= 115 && metaAlcancada < 130) {
          metaAlcancada = 2;
          customStyles = "#f9ff00";
        } else if (metaAlcancada >= 130) {
          metaAlcancada = 3;
          customStyles = "#6395ec";
        }
        dadosTabela.push({
          loja: item.CodLoja,
          meta: metaSugeridaTabela,
          realizada: metaRealizadaTabela,
          porcentagem: porcentagemMeta,
          diasUteis: diasUteis,
          diasFalta: diasUteisRestantes,
          dMensal: deficitMensal,
          dDiario: deficitDiario,
          projecao: projecaoFinal,
          representatividade: representatividade,
          cor: customStyles,
        });
      });

      setMetaTotal(totalMeta);
      setRealizadoTotal(totalRealizada);

      var metaValorFormatadoSufixo;
      var realizadoValorFormatadoSufixo;
      var zeroValorFormatadoSufixo;
      if (totalMeta / 1000000 >= 1) {
        metaValorFormatadoSufixo = (totalMeta / 1000000).toFixed(2) + " Mi";
        zeroValorFormatadoSufixo = "0,00 Mi";
      } else {
        metaValorFormatadoSufixo = (totalMeta / 1000).toFixed(2) + " Mil";
        zeroValorFormatadoSufixo = "0,00 Mil";
      }

      if (totalRealizada / 1000000 >= 1) {
        realizadoValorFormatadoSufixo =
          (totalRealizada / 1000000).toFixed(2) + " Mi";
      } else {
        realizadoValorFormatadoSufixo =
          (totalRealizada / 1000).toFixed(2) + " Mil";
      }

      totalProjecao = totalProjecao.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      });
      //let diasUteis = preencherDiasUteis(dataInicio, dataFim);
      //let diasUteisRestantes = preencherDiasUteis(0, dataFim, true);
      diasUteisGeral = diasUteisGeral / qtdLojas;
      diasRestantesGeral = diasRestantesGeral / qtdLojas;
      let mediaAtual =
        diasUteisGeral > 0 ? totalRealizada / diasUteisGeral : totalRealizada;
      let projecaoFinal =
        mediaAtual && diasRestantesGeral > 0
          ? mediaAtual * diasRestantesGeral + totalRealizada
          : totalRealizada;
      let deficitMensal =
        totalMeta - totalRealizada > 0 ? totalMeta - totalRealizada : 0;
      let deficitDiario =
        diasRestantesGeral > 0 ? deficitMensal / diasRestantesGeral : 0;
      totalPorcentagem = (totalRealizada / totalMeta) * 100;

      diasUteisGeral = diasUteisGeral.toFixed(2);
      diasRestantesGeral = diasRestantesGeral.toFixed(2);

      resumoChart.push({
        loja: "Total",
        meta: totalMeta,
        realizada: totalRealizada,
        porcentagem: totalPorcentagem,
        diasUteis: diasUteisGeral,
        diasFalta: diasRestantesGeral,
        dMensal: deficitMensal,
        dDiario: deficitDiario,
        projecao: projecaoFinal,
        representatividade: 100,
      });

      let porcentagemAbaixoDaMeta =
        ((totalRealizada - totalMeta) / totalMeta) * 100;
      let porcentagemProjetadoAbaixoDaMeta =
        ((projecaoFinal - totalMeta) / totalMeta) * 100;
      let corAbaixoDaMeta = "#fff";

      if (porcentagemAbaixoDaMeta < 0) {
        corAbaixoDaMeta = "#ff0000";
      } else if (porcentagemAbaixoDaMeta >= 0 && porcentagemAbaixoDaMeta < 15) {
        corAbaixoDaMeta = "#00b14f";
      } else if (
        porcentagemAbaixoDaMeta >= 15 &&
        porcentagemAbaixoDaMeta < 30
      ) {
        corAbaixoDaMeta = "#f9ff00";
      } else if (porcentagemAbaixoDaMeta >= 30) {
        corAbaixoDaMeta = "#6395ec";
      }
      let corProjetadoAbaixoDaMeta = "#fff";

      if (porcentagemProjetadoAbaixoDaMeta < 0) {
        corProjetadoAbaixoDaMeta = "#ff0000";
      } else if (
        porcentagemProjetadoAbaixoDaMeta >= 0 &&
        porcentagemProjetadoAbaixoDaMeta < 15
      ) {
        corProjetadoAbaixoDaMeta = "#00b14f";
      } else if (
        porcentagemProjetadoAbaixoDaMeta >= 15 &&
        porcentagemProjetadoAbaixoDaMeta < 30
      ) {
        corProjetadoAbaixoDaMeta = "#f9ff00";
      } else if (porcentagemProjetadoAbaixoDaMeta >= 30) {
        corProjetadoAbaixoDaMeta = "#6395ec";
      }

      resumoAnaliseChart.push({
        loja: "",
        meta: "",
        cor: corAbaixoDaMeta,
        corProjetado: corProjetadoAbaixoDaMeta,
        realizada: porcentagemAbaixoDaMeta,
        porcentagem: "",
        diasUteis: "",
        diasFalta: "",
        dMensal: "",
        dDiario: "",
        projecao: porcentagemProjetadoAbaixoDaMeta,
        representatividade: "",
      });
      descontoParaGrafico = descontoParaGrafico.sort((a, b) => {
        return b.valor - a.valor;
      });
      let descontoParaGraficoTemp = [];
      let labelsChartDesconto = [];
      let labelsChartDescontoCod = [];
      descontoParaGrafico.map((a) => {
        descontoParaGraficoTemp.push(a.valor);
        labelsChartDesconto.push(a.loja);
        labelsChartDescontoCod.push(a.codLoja);
      });
      setResumoTable(resumoChart);
      setResumoAnaliseTable(resumoAnaliseChart);
      setDataChartBarFromApi(metaSugerida);
      setDataMetaOuro(metaOuroSugerida);
      setDataMetaDiamante(metaDiamanteSugerida);
      setDataChartLineFromApi(metaRealizada);
      setDataTableFromApi(dadosTabela);
      setlabelsChart(labelNomes);
      setMetaParaLabel(totalMeta);
      setTotalRealizadoParaLabel(totalRealizada);
      setMetaValorFormatadoSufixo(metaValorFormatadoSufixo);
      setRealizadoValorFormatadoSufixo(realizadoValorFormatadoSufixo);
      setZeroValorFormatadoSufixo(zeroValorFormatadoSufixo);
      setDataChartProjetadaFromApi(projetada);
      setDataDescontoAplicadoFromApi(desconto);
      setlabelsChartCod(labelCod);
      setDataDescontoParaGrafico(descontoParaGraficoTemp);
      setlabelsChartDesconto(labelsChartDesconto);
      setlabelsChartDescontoCod(labelsChartDescontoCod);
      // setDataDescontoParaGrafico(descontoParaGrafico);
      setDadosDisponiveis(true);
      setCarregando(false);
      setIsLoading(false);
    }
  }

  function selecionarPeriodo(item) {
    setPeriodoInputInicio(false);
    setPeriodoInputFim(false);
    setPeriodoReferencia(item);
  }

  const dataChart = {
    labels: labelsChart,
    datasets: [
      {
        type: "line",
        label: "Projetada",
        borderColor: "rgb(00, 00, 00)",
        borderWidth: 2,
        fill: false,
        data: dataChartProjetadaFromApi,
        tension: 0,
        borderDash: [10, 5],
        clip: true,
        clamp: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: "auto",
          color: "#000",
          align: (context) => {
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "-90";
            } else {
              return "90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          },
        },
      },
      {
        type: "line",
        label: "Meta",
        borderColor: "#ff0000",
        borderWidth: 2,
        fill: false,
        data: dataChartBarFromApi,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: true,
          color: "#ff0000",
          align: (context) => {
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "90";
            } else {
              return "-90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "line",
        label: "Diamante",
        borderColor: "#6395ec",
        borderWidth: 5,
        fill: false,
        data: dataMetaDiamante,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: false,
          color: "#6395ec",
          align: (context) => {
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "90";
            } else {
              return "-90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "line",
        label: "Ouro",
        borderColor: "#f9ff00",
        borderWidth: 5,
        fill: false,
        data: dataMetaOuro,
        tension: 0,
        showLine: false,
        clip: true,
        xAxisID: "A",
        datalabels: {
          anchor: "end",
          display: false,
          color: "#f9ff00",
          align: (context) => {
            if (
              dataChartBarFromApi[context.dataIndex] <
              dataChartProjetadaFromApi[context.dataIndex]
            ) {
              return "90";
            } else {
              return "-90";
            }
          },
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "bar",
        label: "Realizada",
        backgroundColor: "rgba(54, 162, 235, 1)",
        data: dataChartLineFromApi,
        borderColor: "transparent",
        borderWidth: 2,
        xAxisID: "B",
        datalabels: {
          anchor: "start",
          display: true,
          color: "rgba(54, 162, 235, 1)",
          align: "start",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 15,
              },
            },
          },
        },
      },
      {
        type: "bar",
        label: "Desconto",
        backgroundColor: "rgba(255, 102, 0, 0.3)",
        borderColor: "rgba(255, 102, 0, 0.3)",
        borderWidth: 1,
        borderDash: [10, 5],
        data: dataDescontoAplicadoFromApi,
        xAxisID: "B",
        datalabels: {
          anchor: "end",
          display: "auto",
          color: "rgba(255, 255, 255, 1)",
          align: "start",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 12,
              },
              padding: 0,
            },
          },
          formatter: function (value, context) {
            let valorDesconto =
              dataDescontoAplicadoFromApi[context.dataIndex] -
              dataChartLineFromApi[context.dataIndex];
            return "";
          },
        },
      },
    ],
  };

  const dataChartDesconto = {
    labels: labelsChartDesconto,
    datasets: [
      {
        type: "bar",
        label: "Desconto",
        backgroundColor: "rgba(255, 102, 0, 0.3)",
        borderColor: "rgba(255, 102, 0, 0.3)",
        borderWidth: 1,
        borderDash: [10, 5],
        data: dataDescontoParaGrafico,
        datalabels: {
          anchor: "start",
          display: "auto",
          color: "rgba(000, 000, 000, 1)",
          align: "start",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 12,
              },
              padding: 0,
            },
          },
          formatter: function (value, context) {
            // let valorDesconto = dataDescontoAplicadoFromApi[context.dataIndex] - dataChartLineFromApi[context.dataIndex];
            value = parseFloat(value) / 1000;
            return (
              parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }) + "K"
            );
          },
        },
      },
      {
        type: "line",
        label: "DescontoPorcentagem",
        backgroundColor: "rgba(255, 102, 0, 0.3)",
        borderColor: "rgba(255, 102, 0, 0.3)",
        borderWidth: 0,
        tension: 0,
        showLine: false,
        borderDash: [0, 0],
        data: dataDescontoParaGrafico,
        fill: false,
        datalabels: {
          anchor: "end",
          display: "auto",
          color: "rgba(000, 000, 000, 1)",
          align: "end",
          labels: {
            title: {
              font: {
                weight: "bold",
                size: 12,
              },
              padding: 0,
            },
          },
          formatter: function (value, context) {
            // let valorDesconto = dataDescontoAplicadoFromApi[context.dataIndex] - dataChartLineFromApi[context.dataIndex];
            //// console.log(value);
            let lojaAtual = labelsChartDescontoCod[context.dataIndex];
            let indexLojaGraficoPrincipal;
            labelsChartCod.map((a, i) => {
              if (a === lojaAtual) {
                indexLojaGraficoPrincipal = i;
              }
            });
            //// console.log(lojaAtual);
            let totalVenda = parseFloat(
              dataChartLineFromApi[indexLojaGraficoPrincipal]
            );
            //// console.log();
            //// console.log(dataChartLineFromApi[context.dataIndex]);
            // value = dataDescontoParaGrafico[context.dataIndex] - dataChartLineFromApi[context.dataIndex];
            let valorDescontoPorcentagem = (value / (totalVenda + value)) * 100;
            return formatarNumero(valorDescontoPorcentagem, false) + "%";
          },
        },
      },
    ],
  };

  const optionsChartBarMetas = {
    maintainAspectRatio: false,
    legend: {
      position: "bottom",
      reverse: true,
      labels: {
        filter: function (item, chart) {
          return (
            !item.text.includes("Desconto") &&
            !item.text.includes("Ouro") &&
            !item.text.includes("Diamante")
          );
        },
      },
    },
    layout: {
      padding: {
        top: 30,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            display: false,
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          id: "A",
          ticks: {
            padding: 25,
            display: false,
          },
        },
        {
          id: "B",
          stacked: true,
          ticks: {
            padding: 25,
            display: false,
          },
          offset: true,
          offsetGridLines: false,
        },
      ],
    },
    tooltips: {
      displayColors: false,
      callbacks: {
        title: (tooltipItem, data) => {
          tooltipItem = tooltipItem[0];
          if (tooltipItem.datasetIndex === 0) {
            return "Valor Projetado Para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 1) {
            return "Meta Para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 2) {
            return "Meta Diamante para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 3) {
            return "Meta Ouro para " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 4) {
            return "Valor Realizado Por " + tooltipItem.xLabel;
          } else if (tooltipItem.datasetIndex === 5) {
            let valorDesconto = formatarNumero(
              dataDescontoAplicadoFromApi[tooltipItem.index]
            );
            return "Total sem desconto " + valorDesconto;
          }
        },
        label: (tooltipItem, data) => {
          if (tooltipItem.datasetIndex !== 5) {
            return (
              "Valor Total: " + formatarNumero(parseFloat(tooltipItem.value))
            );
          } else {
            let valorDesconto =
              dataDescontoAplicadoFromApi[tooltipItem.index] -
              dataChartLineFromApi[tooltipItem.index];
            return "Valor do Desconto: " + formatarNumero(valorDesconto);
          }
        },
        footer: (tooltipItem, data) => {
          tooltipItem = tooltipItem[0];
          if (tooltipItem.datasetIndex === 5) {
            let valorDesconto =
              dataDescontoAplicadoFromApi[tooltipItem.index] -
              dataChartLineFromApi[tooltipItem.index];
            let valorDescontoPorcentagem =
              (valorDesconto / dataDescontoAplicadoFromApi[tooltipItem.index]) *
              100;
            return (
              "Porcentagem " +
              formatarNumero(valorDescontoPorcentagem, false) +
              "%"
            );
          } else if (
            tooltipItem.datasetIndex === 3 ||
            tooltipItem.datasetIndex === 2
          ) {
            let totalRealizado = dataChartLineFromApi[tooltipItem.index];
            return (
              "Valor realizado " + formatarNumero(parseFloat(totalRealizado))
            );
          }
        },
      },
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
        formatter: function (value, context) {
          return parseFloat(value).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
  };

  const optionsChartBarDesconto = {
    maintainAspectRatio: false,
    legend: {
      position: "bottom",
      reverse: true,
      labels: {
        filter: function (item, chart) {
          return (
            !item.text.includes("DescontoPorcentagem") &&
            !item.text.includes("Ouro") &&
            !item.text.includes("Diamante")
          );
        },
      },
    },
    layout: {
      padding: {
        top: 30,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            display: false,
          },
        },
      ],
      xAxes: [
        {
          id: "A",
          ticks: {
            padding: 25,
            display: true,
          },
        },
      ],
    },
    tooltips: {
      displayColors: false,
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
        formatter: function (value, context) {
          return parseFloat(value).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          });
        },
      },
    },
  };

  const getElementAtEvent = (element) => {
    if (!element.length) return;
    const { _index: index, _xScale: eixoX } = element[0];
    var nomeLoja = eixoX.ticks[index];
  };

  function iniciarFiltroPorPeriodo() {
    setPeriodoInputInicio(periodoInputInicioAux);
    setPeriodoInputFim(periodoInputFimAux);
  }

  const columns = colunasMetasGerais();
  const columnsAnalise = colunasAnaliseMetasGerais();
  const customStyles = customStylesTable;
  console.log(customStyles);

  return (
    <>
      <div className="container-app container-webview-rn">
        {isLoading && <Loading estado={isLoading} />}
        {nivelAcesso.length > 0 && permissoes.mvendas1 ? (
          <>
            <Container className="container-webview-rn">
              <div className="container-conteudo">
                <Container>
                  <FilterBasesApp
                    basesDisponiveis={basesDisponiveis}
                    basesSelecionadas={basesSelecionadas}
                    filtrarBases={filtrarBases}
                  />
                </Container>
                <div className="container-metas">
                  <div className="container-lojas-disponiveis">
                    <div className="container-tabela-nomes-lojas">
                      <FilterLoja
                        labelsTable={labelsTable}
                        infoSelect={empresasSelecionadas}
                        functionEmpresa={filtrarEmpresa}
                        direcionarParaMetasPorVendedorasLojaEspecifica={
                          direcionarParaMetasPorVendedorasLojaEspecifica
                        }
                      />

                      <div className="alert-filterloja">
                        <Alert
                          className="fInfo"
                          onClick={() =>
                            setShowVendedorasAbaixoMeta(
                              !showVendedorasAbaixoMeta
                            )
                          }
                        >
                          Vendedora abaixo da meta{" "}
                          <FontAwesomeIcon
                            color="#f00"
                            styled={{ width: "8px" }}
                            size="sm"
                            icon={faChevronDown}
                          />{" "}
                        </Alert>
                      </div>
                      {showVendedorasAbaixoMeta && (
                        <div className="container-vendedoras-abaixo-meta">
                          {labelsTable.map((item) => {
                            if (
                              item?.quantidadeVendedorasAbaixoMeta?.length &&
                              item.quantidadeVendedorasAbaixoMeta.length > 0
                            ) {
                              return (
                                <div className="item-vendedora">
                                  <span>Loja {item.nomeLoja}</span> / Quantidade{" "}
                                  <span className="qtd">
                                    {" "}
                                    {item.quantidadeVendedorasAbaixoMeta.length}
                                  </span>
                                </div>
                              );
                            }
                            return <></>;
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                  <>
                    <div className="container-metas-interno-superior-metas">
                      {dadosDisponiveis ? (
                        <Container>
                          <>
                            <ReactFullscreen>
                              {({ ref, onRequest, onExit }) => (
                                <div
                                  ref={ref}
                                  style={{ backgroundColor: "#fff" }}
                                >
                                  <div className="wrapper">
                                    <div className="container-botao-filtrar">
                                      <Bar
                                        data={dataChart}
                                        options={optionsChartBarMetas}
                                        getElementAtEvent={getElementAtEvent}
                                      />
                                      {/* <button className='botaoTelaCheia' onClick={() => onRequest()}>Tela Cheia</button>
                                                                                <button className='botaoTelaCheia' onClick={() => onExit()}>Sair</button> */}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </ReactFullscreen>

                            <div className="wrapper">
                              <Col
                                xsOffset={12}
                                xs={12}
                                className="colMetasGerais2"
                              >
                                <Bar
                                  data={dataChartDesconto}
                                  options={optionsChartBarDesconto}
                                />
                              </Col>
                            </div>

                            <div className="wrapper">
                              <Col className="colMetasGerais1">
                                <ReactSpeedometer
                                  width={document.body.offsetWidth - 30}
                                  height={200}
                                  forceRender={true}
                                  value={realizadoTotal}
                                  segments={4}
                                  minValue={0}
                                  maxValue={metaTotal + metaTotal * 0.6}
                                  customSegmentStops={[
                                    0,
                                    metaTotal,
                                    metaTotal + metaTotal * 0.15,
                                    metaTotal + metaTotal * 0.3,
                                    metaTotal + metaTotal * 0.6,
                                  ]}
                                  segmentColors={[
                                    "#ff0000",
                                    "#00b14f",
                                    "#f9ff00",
                                    "#6395ec",
                                  ]}
                                  currentValueText={realizadoTotal.toLocaleString(
                                    "pt-br",
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                      style: "currency",
                                      currency: "brl",
                                    }
                                  )}
                                  ringWidth={47}
                                  valueFormat={
                                    metaTotal > 1000000 ? ".3s" : ".5s"
                                  }
                                  needleTransitionDuration={3333}
                                  needleTransition="easeElastic"
                                  needleColor={"gray"}
                                  textColor={"#000"}
                                />
                              </Col>
                            </div>
                          </>
                        </Container>
                      ) : carregando ? (
                        <></>
                      ) : (
                        <Alerta />
                      )}
                    </div>
                    <div className="container-legenda-tabelas legenda-tabela">
                      <small className="legenda-meta cor-diamante">
                        Diamante
                      </small>
                      <small className="legenda-meta cor-ouro">Ouro</small>
                      <small className="legenda-meta cor-meta">
                        Atingiu a Meta
                      </small>
                      <small className="legenda-meta cor-abaixo-meta">
                        Não atingiu
                      </small>
                    </div>
                    <div className="container-metas-interno-superior">
                      {dadosDisponiveis ? (
                        <Container>
                          <div className="wrapper">
                            <DataTableExtensions
                              {...{
                                columns: columns,
                                data: dataTableFromApi,
                                customStyles: customStyles,
                              }}
                              filter={false}
                              exportHeaders={false}
                              export={false}
                              print={false}
                            >
                              <DataTable
                                noHeader={true}
                                dense={true}
                                striped={true}
                              />
                            </DataTableExtensions>
                            <DataTable
                              className="table-resume-header"
                              columns={columns}
                              data={resumoTable}
                              noHeader={true}
                              noTableHead={true}
                              dense={true}
                              striped={true}
                            />
                            <DataTable
                              className="table-resume-header"
                              columns={columnsAnalise}
                              data={resumoAnaliseTable}
                              noHeader={true}
                              noTableHead={true}
                              dense={true}
                              striped={true}
                            />
                          </div>
                        </Container>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </Container>
          </>
        ) : (
          <>
            {carregando ? <Loading carregando={carregando} /> : <AlertaLogin />}
          </>
        )}
      </div>
    </>
  );
}

export default MetasApp;
