import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/material";
import React from "react";
import Select from "react-select";
import "./styles.css";

export const MultiSelectFilterLoja = ({
  labelsTable,
  functionEmpresa,
  direcionarParaMetasPorVendedorasLojaEspecifica,
}) => {
  if (labelsTable.length === 0) {
    return " ";
  }

  const options = labelsTable.map((item) => ({
    value: item.codLoja,
    label: item.nomeLoja,
    id: item.codLoja,
    lowGoal: item.quantidadeVendedorasAbaixoMeta,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
     
    }),
    // Estilos personalizados para o ícone
    icon: {
      marginLeft: "4px",
    },
  };
  const handleStore = (id) => {
    direcionarParaMetasPorVendedorasLojaEspecifica(id);
  };
  const CustomOption = ({ innerProps, label, lowGoal = [], value }) => {
    return (
      <div {...innerProps}>
        {label}
        {lowGoal.length > 0 && (
          <span onClick={() => handleStore(value)}>
            {lowGoal.map(() => (
              <span style={customStyles.icon}>
                <FontAwesomeIcon
                  color="#f00"
                  styled={{ width: "8px" }}
                  size="sm"
                  icon={faExclamation}
                />
              </span>
            ))}
          </span>
        )}
      </div>
    );
  };
  const formatOptionLabel = ({ label, icon, value, lowGoal = [] }) => {
    return (
      <CustomOption label={label} icon={icon} value={value} lowGoal={lowGoal} />
    );
  };

  const handleSelectChange = (data) => {
    functionEmpresa(data.map((item) => item.value));
  };
  return (
    <Box display={"flex"} flexDirection={"column"} pt={2} component={'aside'}>
      <Typography textAlign={"initial"} fontWeight={"bold"} component={"span"}>
        Lojas
      </Typography>
      <Select
        isMulti
        options={options}
        onChange={handleSelectChange}
        formatOptionLabel={formatOptionLabel}
        styles={customStyles}
        placeholder="Selecione"
        classNames={{
            control: (state) => state.isFocused && "borderIsFocus",
          }}
      />
    </Box>
  );
};
