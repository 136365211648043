import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { TableMain } from "./TableMain";
import { TableSemanal } from "./TableSemanal";
import { TableFuncionarios } from "./TableFuncionarios";

import { useContext, useState } from "react";
import { FormEditarMetasSemanal } from "./FormEditarMetasSemanal";
import { FormEditarMetasMensal } from "./FormEditMetaMensal";
import { ButtonExpand } from "../ExpandMore";
import { Add } from "@mui/icons-material";
import { FormContext } from "../../pages/financeiro/metas/cadastro-metas";
import { useFormContext } from "react-hook-form";
import { Calendar } from "react-date-range";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
const formatterDiasNaoTrabalhados = (date) => {
  if (!date) {
    return [];
  }

  const dadosEntrada = date.split(",");

  const datas = dadosEntrada
    .filter((dd) => !!dd)
    .map((d) => {
      const year = Math.floor(d / 10000);
      const month = Math.floor((d % 10000) / 100);
      const day = d % 100;
      return dayjs()
        .year(year)
        .month(month - 1)
        .date(day);
    });

  return datas.map((d) => d.format("DD/MM/YYYY"));
};

export const CardStore = ({ item }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [mostrarDiasNaoTrabalhados, setMostrarDiasNaoTrabalhados] =
    useState(false);
  const [openEditMensal, setOpenEditMensal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [openAddFunc, setOpenAddFunc] = useState(false);
  const { getValues, setValue, watch } = useFormContext();
  const [valueSelect, setValueSelect] = useState(null);
  const [diasNaoTrabalhados, setDiasNaoTrabalhados] = useState(
    formatterDiasNaoTrabalhados(getValues(`${item.CodLoja}.diasRemovidos`))
  );
  const params = useParams();

  const {
    funcionariosNaoSelecionados,
    setFuncionariosNaoSelecionados,
    dataPage,
    formatterDataPage,
    setDataPage,
  } = useContext(FormContext);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const handleEditMensalClick = () => setOpenEditMensal((old) => !old);
  const handleEditSemanalClick = () => setOpenEdit((old) => !old);
  const handleOpenAddFunc = () => setOpenAddFunc((old) => !old);
  const optionsAuto = funcionariosNaoSelecionados.map((item) => ({
    label: `BASE: ${item?.base} LOJA: ${item?.CodLoja} - ${item?.NomeFunc}`,
  }));
  const handleAddFuncionario = () => {
    let indexValueFind = "";
    optionsAuto.forEach((item, index) => {
      if (item.label === valueSelect) {
        indexValueFind = index;
      }
    });
    const aux = [...funcionariosNaoSelecionados];

    const func = {
      ...aux.splice(indexValueFind, 1)[0],
    };
    const dataObj = [...dataPage];
    const dataAux = dataObj.map((store) => {
      if (store.CodLoja === item.CodLoja) {
        store.funcionariosDaLoja = {
          ...getValues(`${item.CodLoja}.funcionariosDaLoja`),
          [item.CodFunc]: func,
        };
      }
      return item;
    });
    formatterDataPage(dataAux, false);
    setDataPage(dataAux);

    setFuncionariosNaoSelecionados(aux);

    setValueSelect(null);
  };
  const handleRemoveFuncionario = (row) => {
    let funcRemoved = {};
    const dataObj = [...dataPage];
    const dataAux = dataObj.map((dataO) => {
      if (row.CodLoja === dataO.CodLoja) {
        funcRemoved = dataO.funcionariosDaLoja[row.CodFunc];
        delete dataO.funcionariosDaLoja[row.CodFunc];
      }
      return dataO;
    });

    formatterDataPage(dataAux, false);
    setDataPage(dataAux);
    setFuncionariosNaoSelecionados((old) => [...old, funcRemoved]);
  };

  const handleDelete = (value) => {
    const dateTemp = getValues(`${item.CodLoja}.diasRemovidos`).split(",");
    const valueSave = dateTemp.filter(
      (dateT) =>
        dateT.trim() !==
        value.split("/")[2].trim() +
          value.split("/")[1].trim() +
          value.split("/")[0]
    );

    let days = "";
    valueSave.forEach((day, index) => {
      if (index === 0) {
        days = `${day}`;
      } else {
        days = `${days}, ${day}`;
      }
    });
    setDiasNaoTrabalhados(formatterDiasNaoTrabalhados(days));
    setValue(`${item.CodLoja}.diasRemovidos`, days);
  };
  return (
    <Card variant="outlined">
      <CardActions
        sx={{
          pb: "4px",
          pr: "16px",
        }}
        disableSpacing
      >
        <ButtonExpand
          handleExpandClick={handleExpandClick}
          expanded={expanded}
        />

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography
            sx={{ fontSize: 14 }}
            color="text.primary"
            fontWeight={"500"}
            mb={0}
            component={"span"}
            s
            gutterBottom
          >
            Base {item.base} - {item.loja}
          </Typography>
          <Stack direction={"row"} gap={2}>
            <Button
              onClick={() => setMostrarDiasNaoTrabalhados((old) => !old)}
              size="small"
              variant="outlined"
            >
              Adicionar dias não trabalhados
            </Button>
          </Stack>
        </Stack>
      </CardActions>
      <CardContent
        sx={{
          pt: 0,
          pb: "4px",
        }}
      >
        <Stack direction={"row"} my={1} justifyContent={"space-between"}>
          <Box>
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              fontWeight={"500"}
              component={"p"}
              mb={0}
              gutterBottom
            >
              Dias removidos
            </Typography>
            <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
              {diasNaoTrabalhados.map((item) => (
                <Chip label={item} onDelete={() => handleDelete(item)} />
              ))}
            </Stack>
          </Box>
          <Collapse unmountOnExit in={mostrarDiasNaoTrabalhados}>
            <Box component={Paper} variant="outlined">
              <Calendar
                onChange={(change) => {
                  const date = !!getValues(`${item.CodLoja}.diasRemovidos`)
                    ? getValues(`${item.CodLoja}.diasRemovidos`)
                    : "";
                  if (!date) {
                    setValue(
                      `${item.CodLoja}.diasRemovidos`,
                      dayjs(new Date(change)).format("YYYYMMDD")
                    );
                    setDiasNaoTrabalhados(
                      formatterDiasNaoTrabalhados(
                        dayjs(new Date(change)).format("YYYYMMDD")
                      )
                    );
                  } else {
                    setValue(
                      `${item.CodLoja}.diasRemovidos`,
                      date + "," + dayjs(new Date(change)).format("YYYYMMDD")
                    );
                    setDiasNaoTrabalhados(
                      formatterDiasNaoTrabalhados(
                        date + "," + dayjs(new Date(change)).format("YYYYMMDD")
                      )
                    );
                  }
                }}
              />
            </Box>
          </Collapse>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"flex-end"}
        >
          <Typography
            sx={{ fontSize: 14 }}
            color="text.secondary"
            fontWeight={"500"}
            component={"span"}
            mb={0}
            gutterBottom
          >
            Meta mensal
          </Typography>
          <Stack direction={"row"} gap={2}>
            <Button
              onClick={handleEditMensalClick}
              size="small"
              variant="outlined"
              disabled={!!params?.id}
            >
              {openEditMensal ? "Voltar" : "Editar Meta mensal"}
            </Button>
          </Stack>
        </Stack>
        <TableMain data={item} />
        <FormEditarMetasMensal
          onClickSave={handleEditMensalClick}
          data={item}
          open={openEditMensal}
        />
      </CardContent>
      <Collapse in={expanded}>
        <CardContent
          sx={{
            pt: 0,
          }}
        >
          <Stack
            mt={2}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              fontWeight={"500"}
              component={"span"}
              mb={0}
              gutterBottom
            >
              Meta semanal
            </Typography>
            <Button
              onClick={() => setOpenEdit((old) => !old)}
              size="small"
              variant="outlined"
              disabled={!!params?.id}
            >
              {openEdit ? "Voltar" : "Editar metas semanal"}
            </Button>
          </Stack>

          <TableSemanal data={item} open={openEdit} />
          <FormEditarMetasSemanal
            data={item}
            open={openEdit}
            onClickSave={handleEditSemanalClick}
          />

          <Stack
            mt={2}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"flex-end"}
          >
            <Typography
              sx={{ fontSize: 14 }}
              color="text.secondary"
              fontWeight={"500"}
              component={"span"}
              mb={0}
              gutterBottom
            >
              Funcionarios
            </Typography>
            <Button
              onClick={handleOpenAddFunc}
              size="small"
              variant="outlined"
              color="info"
            >
              Adicionar funcionario
            </Button>
          </Stack>
          <Collapse in={openAddFunc}>
            <Box
              display={"flex"}
              gap={1}
              py={1}
              sx={{
                ".Mui-disabled": {
                  opacity: 0.7,
                  backgroundColor: "#ff7aae !important",
                  color: "white",
                },
              }}
            >
              <Autocomplete
                disablePortal
                value={valueSelect}
                options={optionsAuto}
                sx={{ width: "100%", maxWidth: 350 }}
                onChange={(e) => {
                  setValueSelect(e.currentTarget.textContent);
                }}
                noOptionsText="Nenhum resultado encontrado"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pesquise pelo nome do funcionario"
                    size="small"
                  />
                )}
              />
              <Button
                onClick={handleAddFuncionario}
                variant="contained"
                color="aprincipal"
                size="small"
                disabled={!valueSelect}
              >
                <Add fontSize="inherit" />
              </Button>
            </Box>
          </Collapse>
          <TableFuncionarios
            handleRemove={handleRemoveFuncionario}
            data={item.funcionariosDaLoja}
          />
        </CardContent>
      </Collapse>
    </Card>
  );
};
