import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import Title from "../../components/title";

import {
  arrayBases,
  converterData,
  converterDataFormatoPadrao,
  converterLoja,
  converterMes,
  converterNomeLoja,
  fAnosDisponiveis,
  fAnosPorLoja,
} from "../../util/loja";

import {
  colunasDescontoGlobal,
  colunasTabelasGlobaisNext,
} from "../../util/tables";
import TabelaNext from "../../components/componentteste/TabelaNext";
import { FiltroPeriodo } from "../../components/FiltroPeriodo/FiltroPeriodo";
import { TituloTabela } from "../../components/TituloTabela/TituloTabela";

import toast from "react-hot-toast";
import { PermissionsPage } from "../../util/permissionsPage";
import { vendasService } from "../../services/vendas";
import { useUserData } from "../../hooks/useUserData";
import { Box, Paper, Stack, Typography } from "@mui/material";
import Select from "react-select";
import { LoadingTable } from "../../components/Loading/LoadingTable";

import {
  ButtonFilter,
  ButtonToday,
} from "../../components/DatePikerFilter/styles";
import { MultiSelectFilterBases } from "../../components/FilterLojaMetas/MultiSelectFilterBases";

const colourStyles = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? " whitesmoke" : "white",
  }),
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: " #eb0058",
    };
  },
  multiValueLabel: (styles) => ({
    ...styles,
    color: "#FFF",
    fontWeight: "bold",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: "white",
    ":hover": {
      backgroundColor: "#ff0d6c",
      color: "white",
    },
  }),
};

function DescontoGlobal() {
  const [periodoInputInicio, setPeriodoInputInicio] = useState("");
  const [periodoInputFim, setPeriodoInputFim] = useState("");
  const [periodoInputInicioAux, setPeriodoInputInicioAux] = useState("");
  const [periodoInputFimAux, setPeriodoInputFimAux] = useState("");
  const [periodoAno, setPeriodoAno] = useState(false);

  const [mesesDisponiveis, setMesesDisponiveis] = useState([]);
  const [anosDisponiveis, setAnosDisponiveis] = useState([]);
  const [anosDisponiveisAuxiliar, setAnosDisponiveisAuxiliar] = useState([]);
  const [lojasDisponiveis, setLojasDisponiveis] = useState([]);
  const [dadosGraficoAgrupado, setDadosGraficoAgrupado] = useState({
    labels: [],
    datasets: [],
  });

  const [dadosGerais, setDadosGerais] = useState(0);

  const [dadosTabelasGlobais, setDadosTabelaGlobal] = useState([]);
  const [filtrosAtivosPeriodoTexto, setFiltrosAtivosPeriodoTexto] =
    useState("");

  const [filtrosMesesSelecionados, setFiltrosMesesSelecionados] = useState([]);
  const [filtrosAnosSelecionados, setFiltrosAnosSelecionados] = useState([]);
  const [codLojaAtiva, setCodLojaAtiva] = useState(false);

  const [dadosParaTabela, setDadosParaTabela] = useState([]);
  const [dadosParaTabelaGlobalAnoAtual, setDadosParaTabelaGlobalAnoAtual] =
    useState([]);
  const [
    dadosParaTabelaGlobalAnoAnterior,
    setDadosParaTabelaGlobalAnoAnterior,
  ] = useState([]);

  const [exibeTabela, setExibeTabela] = useState(false);
  const [exibeTabelaGlobal, setExibeTabelaGlobal] = useState(false);
  const [tipoPeriodo, setTipoPeriodo] = useState("");
  const [filtradoPorLoja, setFiltradoPorLoja] = useState(false);
  const [filtrosLojasSelecionadas, setFiltrosLojasSelecionadas] = useState([]);
  const [nivelAtivoDoGraficoTotalVendas, setNivelAtivoDoGraficoTotalVendas] =
    useState(0);

  const [auxAnoAtual, setAuxAnoAtual] = useState(0);
  const [auxAnoAnterior, setAuxAnoAnterior] = useState(0);
  const [base, setBase] = useState();
  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [basesSelecionadas, setBasesSelecionadas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dadosDisponiveis, setDadosDisponiveis] = useState(true);
  const [, setCarregando] = useState(false);
  const [nivelAcesso, setNivelAcesso] = useState([]);
  const [dadosUltimoPeriodo, setDadosUltimoPeriodo] = useState([]);

  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;
  useEffect(() => {
    if (nivelAcesso.length === 0) {
      buscarNivelAcesso();
      setIsLoading(true);
      setCarregando(true);
      return;
    }
  }, [userData, base]);
  useEffect(() => {
    if (
      periodoAno &&
      accessLevel?.length > 0 &&
      acessBase?.length > 0 &&
      base
    ) {
      preencherDados("ano");
    } else if (
      periodoInputInicio &&
      periodoInputInicio !== "" &&
      periodoInputFim &&
      periodoInputFim !== "" &&
      accessLevel?.length > 0 &&
      acessBase?.length > 0 &&
      base
    ) {
      preencherDados("input");
    }
  }, [periodoInputInicio, periodoInputFim, periodoAno, base, userData]);
  useEffect(() => {
    preencherInputPeriodo();
  }, []);
  useEffect(() => {
    if (codLojaAtiva) {
      preencherDadosVendasPorMes(codLojaAtiva);
    }
  }, [filtrosMesesSelecionados]);

  useEffect(() => {
    if (filtrosAnosSelecionados.length > 0) {
      iniciaFiltroPorAno();
    }
  }, [filtrosAnosSelecionados]);

  useEffect(() => {
    if (filtradoPorLoja) {
      iniciaFiltroPorLoja();
    }
  }, [filtrosLojasSelecionadas]);

  const LoadingTemp = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 2500);
  };
  const buscarNivelAcesso = () => {
    if (accessLevel) {
      setNivelAcesso(accessLevel);
      setBasesDisponiveis(arrayBases(acessBase));
      if (acessBase.length > 0) {
        setBase(acessBase[0]);
      }
    } else {
      setCarregando(false);
      setIsLoading(false);
    }
  };
  function preencherInputPeriodo() {
    var dataHoje = new Date();
    var [inicioAno, hoje] = converterDataFormatoPadrao(dataHoje);
    var { inicioAno: inicioAnoTexto, hoje: hojeTexto } =
      converterData(dataHoje);
    setPeriodoInputInicioAux(inicioAno);
    setPeriodoInputFimAux(hoje);
    setPeriodoInputInicio(inicioAno);
    setPeriodoInputFim(hoje);
    setFiltrosAtivosPeriodoTexto(" - " + inicioAnoTexto + " à " + hojeTexto);
  }

  async function preencherDados(tipo) {
    setTipoPeriodo(tipo);
    setFiltradoPorLoja(false);
    var lojasDisponiveisAux = [];
    let auxLojasSelecionadas = [];
    lojasDisponiveis.map((item) => {
      lojasDisponiveisAux.push(item);
    });
    setMesesDisponiveis(false);
    setLojasDisponiveis(lojasDisponiveisAux);
    if (tipo === "input") {
      let inicio = periodoInputInicio.split("-");
      inicio = inicio[2] + "/" + inicio[1] + "/" + inicio[0];
      let fim = periodoInputFim.split("-");
      fim = fim[2] + "/" + fim[1] + "/" + fim[0];
      setFiltrosAtivosPeriodoTexto(" - " + inicio + " à " + fim);
      setExibeTabela(true);
      var auxAnoAtual = periodoInputInicio.split("-")[0];
      var auxAnoAnterior = parseInt(auxAnoAtual) - 1;
      setAuxAnoAnterior(auxAnoAnterior);
      setAuxAnoAtual(auxAnoAtual);
    } else if (tipo === "ano") {
      var auxAnoAtual = periodoAno.split(",");
      auxAnoAtual = auxAnoAtual.sort((a, b) => a - b);
      var auxAnoAnterior;
      if (auxAnoAtual.length > 1) {
        auxAnoAnterior = auxAnoAtual[auxAnoAtual.length - 2];
      } else {
        auxAnoAnterior = 0;
      }
      auxAnoAtual = auxAnoAtual[auxAnoAtual.length - 1];
      setAuxAnoAnterior(auxAnoAnterior);
      setAuxAnoAtual(auxAnoAtual);
    }
    setExibeTabela(true);
    setCarregando(true);
    setIsLoading(true);
    const toastId = toast.loading("Buscando dados...");
    window.scrollTo(0, 0);

    try {
      const response = await vendasService.listaVendasDesconto(
        JSON.stringify({
          inicio: periodoInputInicio,
          fim: periodoInputFim,
          ano: periodoAno,

          tipo,
          nivelAcesso,
          base,
        })
      );

      if (!response?.data) {
        toast.error("Não há conexão com o RAD", {
          duration: 7000,
          id: toastId,
          style: {
            borderRadius: "0.25rem",
            background: "#333",
            color: "#fff",
          },
        });
        setCarregando(false);
        setIsLoading(false);
        setDadosDisponiveis(false);
        return;
      }
      if (response?.data?.success && response?.data?.type) {
        try {
          setAnosDisponiveis(fAnosDisponiveis(response.data.anos));
          setAnosDisponiveisAuxiliar(fAnosDisponiveis(response.data.anos));
        } catch (error) {
          setDadosDisponiveis(false);
          setCarregando(false);
          return;
        }
        toast.success("Dados recebidos", {
          id: toastId,
        });
        let dataVendas = response.data.data.vendas;
        let dataVendasPeriodoAnterior =
          response.data.data.vendasPeriodoAnterior;
        setDadosGerais(dataVendas);
        setDadosUltimoPeriodo(dataVendasPeriodoAnterior);
        let grupoDeAnos = agruparPor(dataVendas, "ano");
        let grupoDeAnosArray = Object.entries(grupoDeAnos);
        let grupoDeAnoLoja = [];
        grupoDeAnosArray.map((item) => {
          let grupoDeLoja = agruparPor(item[1], "CodLoja");
          grupoDeAnoLoja.push(grupoDeLoja);
        });
        let resumoGrupoDeAnoLoja = [];
        grupoDeAnoLoja.map((item) => {
          let item2 = Object.entries(item);
          item2.map((item3) => {
            let total = 0;
            let totalPedidos = 0;
            let ano;
            let codLoja;
            item3[1].map((item4, index) => {
              if (index === 0) {
                ano = item4.ano;
                codLoja = item4.CodLoja;
                if (
                  auxLojasSelecionadas.indexOf(converterLoja(codLoja)) === -1
                ) {
                  auxLojasSelecionadas.push(converterLoja(codLoja));
                }
              }
              totalPedidos += parseInt(item4.totalPedidos);
              total += parseFloat(item4.DescontoAplicado);
            });
            let resumoLojaAno = {
              ano: ano,
              codLoja: codLoja,
              total: total,
              totalPedidos: totalPedidos,
            };
            resumoGrupoDeAnoLoja.push(resumoLojaAno);
          });
        });
        var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");
        grupoDeAnosArray = Object.entries(agrupado);
        preencherDadosGraficoAgrupado(
          grupoDeAnosArray,
          auxAnoAnterior,
          auxAnoAtual,
          dataVendasPeriodoAnterior
        );
        setDadosTabelaGlobal(grupoDeAnos);
        preencherDadosTabelasGlobais(grupoDeAnos);
        setLojasDisponiveis(auxLojasSelecionadas);
        setDadosDisponiveis(true);
        setCarregando(false);
        setIsLoading(false);
      } else {
        setCarregando(false);
        setDadosDisponiveis(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function agruparPor(objetoArray, propriedade) {
    return objetoArray.reduce(function (acc, obj) {
      let key = obj[propriedade];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  }

  function iniciaFiltroPorAno() {
    var anosSelecionados = filtrosAnosSelecionados.join(",");
    setPeriodoAno(anosSelecionados);
  }

  function iniciaFiltroPorLoja() {
    preencherDadosVendasPorLoja();
  }

  async function preencherDadosGraficoAgrupado(
    dados,
    anoAnterior,
    anoAtual,
    dadosPeriodoAnterior = false
  ) {
    dados = dados.reverse();
    var label = [];
    var labelCompleta = [];
    var dataSets = [];
    var dadosTabelaAux = [];

    const promise = await dados.map((item, i) => {
      var dadosGrupo = [];
      item[1].map((itemGrupo) => {
        var nomeLoja = converterLoja(itemGrupo.codLoja);
        if (filtrosLojasSelecionadas.length > 0) {
          if (filtrosLojasSelecionadas.indexOf(nomeLoja) >= 0) {
            let jaExiste = label.indexOf(nomeLoja);
            if (jaExiste < 0) {
              label.push(nomeLoja);
              labelCompleta.push(nomeLoja);
            }
            dadosGrupo.push(parseFloat(itemGrupo.total));
            dadosTabelaAux.push(itemGrupo);
          } else {
            let jaExiste = labelCompleta.indexOf(nomeLoja);
            if (jaExiste < 0) {
              labelCompleta.push(nomeLoja);
            }
          }
        } else {
          let jaExiste = label.indexOf(nomeLoja);
          if (jaExiste < 0) {
            label.push(nomeLoja);
            labelCompleta.push(nomeLoja);
          }
          dadosGrupo.push(parseFloat(itemGrupo.total));
          dadosTabelaAux.push(itemGrupo);
        }
      });
      var cor =
        item[0] === anoAnterior
          ? "rgba(255, 194, 218, 1)"
          : item[0] === anoAtual
          ? "rgba(91, 139, 252, 1)"
          : i === dados.length - 3
          ? "#5B8BFC"
          : i === dados.length - 4
          ? "#FFC2DA"
          : i === dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (item[0] === anoAnterior) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else if (item[0] === anoAtual) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          rotation: 0,
          formatter: function (value, context) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else {
        datalabels = {
          formatter: function (value, context) {
            return "";
          },
        };
      }
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });

    if (dadosPeriodoAnterior && dadosPeriodoAnterior?.length > 0) {
      let periodoAnteriorAux = [];
      if (filtrosLojasSelecionadas.length > 0) {
        await dadosPeriodoAnterior.map((item) => {
          var nomeLoja = converterLoja(item.CodLoja);
          if (filtrosLojasSelecionadas.indexOf(nomeLoja) >= 0) {
            periodoAnteriorAux.push(parseFloat(item.DescontoAplicado));
          }
        });
        dataSets.push({
          label: "Periodo anterior",
          data: periodoAnteriorAux,
          backgroundColor: "#FFDB8A",
          datalabels: {
            anchor: "start",
            display: true,
            align: "start",
            formatter: function (value, context) {
              return parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            },
          },
        });
      } else {
        const dadosPeriodoAnteriorTemp = dadosPeriodoAnterior.map((item) => {
          return parseFloat(item.DescontoAplicado);
        });

        periodoAnteriorAux = dadosPeriodoAnteriorTemp;
        dataSets.push({
          label: "Periodo anterior",
          data: periodoAnteriorAux,
          backgroundColor: "#FFDB8A",
          datalabels: {
            formatter: function (value, context) {
              return parseFloat(value).toLocaleString("pt-BR", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              });
            },
          },
        });
      }
    }

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "codLoja");
    dadosTabelaAux = Object.entries(dadosTabelaAux);

    preencherDadosTabela(
      dadosTabelaAux,
      anoAnterior,
      anoAtual,
      dadosPeriodoAnterior
    );
    setDadosGraficoAgrupado(data);
    setNivelAtivoDoGraficoTotalVendas(0);
  }

  function geraCorAleatoria() {
    var hexadecimais = "0123456789ABCDEF";
    var cor = "#";
    for (var i = 0; i < 6; i++) {
      cor += hexadecimais[Math.floor(Math.random() * 16)];
    }
    return cor;
  }

  function preencherDadosTabela(
    dados,
    anoAnterior,
    anoAtual,
    dadosPeriodoAnterior = false
  ) {
    var dadosTabela = [];
    var dadosGraficoDesempenho = [];
    var dadosResumoTabela = [];
    var quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;

    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      vlrMedioAnoAtualResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      vlrMedioAnoAnteriorResumo = 0,
      desempenhoResumo = 0,
      desempenhoPAResumo = 0,
      rprAnoAtual = 0,
      rprAnoAnterior = 0,
      qtdPeriodoAnteriorResumo = 0,
      rprPeriodoAnterior = 0,
      vlrTotalPeriodoAnteriorResumo = 0,
      vlrMedioPeriodoAnteriorResumo = 0;

    var totalPedidosAnoAtual = 0;
    var totalPedidosAnoAnterior = 0;
    var totalPedidosPeriodoAnterior = 0;

    if (dados.length > 0) {
      dados.forEach((item) => {
        item[1].forEach((item2) => {
          if (item2.ano === anoAtual) {
            totalPedidosAnoAtual += parseInt(item2.total);
          } else if (item2.ano === anoAnterior) {
            totalPedidosAnoAnterior += parseInt(item2.total);
          }
        });
      });

      if (dadosPeriodoAnterior?.length) {
        dadosPeriodoAnterior?.forEach((item) => {
          totalPedidosPeriodoAnterior += Number(item.totalPedidos);
        });
      }

      dados.forEach((item) => {
        let loja = parseInt(item[0]);
        let qtdAnoAtual = 0,
          vlrAnoAtual = 0,
          vlrMedioAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrTotalAnoAnterior = 0,
          vlrMedioAnoAnterior = 0,
          desempenho = 0,
          desempPeriodoAnterior = 0,
          representatividadeAtual = 0,
          representatividadeAnterior = 0;

        const periodoAnteriorItem = dadosPeriodoAnterior?.find(
          (item) => Number(item?.CodLoja) === loja
        );
        let vlrPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.DescontoAplicado)
          : 0;
        let qtdPeriodoAnterior = periodoAnteriorItem
          ? Number(periodoAnteriorItem?.totalPedidos)
          : 0;
        let vlrMedioPeriodoAnterior =
          periodoAnteriorItem && qtdPeriodoAnterior
            ? vlrPeriodoAnterior / qtdPeriodoAnterior
            : 0;
        let representatividadePeriodoAnterior =
          periodoAnteriorItem && totalPedidosPeriodoAnterior
            ? Number(
                (
                  (qtdPeriodoAnterior / totalPedidosPeriodoAnterior) *
                  100
                ).toFixed(2)
              )
            : 0;
        qtdPeriodoAnteriorResumo += qtdPeriodoAnterior;
        vlrTotalPeriodoAnteriorResumo += vlrPeriodoAnterior;

        rprPeriodoAnterior += Number(
          representatividadePeriodoAnterior.toFixed(2)
        );

        let auxValorAnoAnterior = 0,
          auxValorAnoAtual = 0;
        var separaDoisUltimosAnos = [];
        if (item[1][item[1].length - 1]) {
          separaDoisUltimosAnos.push(item[1][item[1].length - 1]);
        }
        if (item[1][item[1].length - 2]) {
          separaDoisUltimosAnos.push(item[1][item[1].length - 2]);
        } else {
          let ano = (parseInt(item[1][item[1].length - 1].ano) - 1).toString();
          let codLoja = item[1][item[1].length - 1].codLoja;
          let total = 0;
          let totalPedidos = 0;
          separaDoisUltimosAnos.push({ ano, codLoja, total, totalPedidos });
        }
        separaDoisUltimosAnos.map((item2, index2) => {
          if (item2.ano === anoAtual) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = parseFloat(parseFloat(item2.total).toFixed(2));
            auxValorAnoAtual += parseFloat(item2.total);
            vlrMedioAnoAtual = parseFloat(
              (vlrAnoAtual / qtdAnoAtual).toFixed(2)
            );
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            vlrMedioAnoAtualResumo += vlrMedioAnoAtual;
            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else if (item2.ano === anoAnterior) {
            qtdAnoAnterior = parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = parseFloat(
              parseFloat(item2.total).toFixed(2)
            );
            auxValorAnoAnterior += parseFloat(item2.total);
            vlrMedioAnoAnterior =
              qtdAnoAnterior === 0
                ? 0
                : parseFloat((vlrTotalAnoAnterior / qtdAnoAnterior).toFixed(2));
            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            vlrMedioAnoAnteriorResumo += vlrMedioAnoAnterior;
            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (qtdAnoAnterior > 0) {
          desempenho = parseFloat(
            ((auxValorAnoAtual / auxValorAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempenho = 0;
        }

        if (qtdPeriodoAnterior > 0) {
          desempPeriodoAnterior = parseFloat(
            ((auxValorAnoAtual / vlrPeriodoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempPeriodoAnterior = 0;
        }
        dadosGraficoDesempenho.push({ loja, desempenho });
        desempenhoResumo += desempenho;
        representatividadeAtual =
          totalPedidosAnoAtual > 0
            ? parseFloat(
                ((vlrAnoAtual / totalPedidosAnoAtual) * 100).toFixed(2)
              )
            : 0;
        representatividadeAnterior =
          totalPedidosAnoAnterior > 0
            ? parseFloat(
                ((vlrTotalAnoAnterior / totalPedidosAnoAnterior) * 100).toFixed(
                  2
                )
              )
            : 0;
        rprAnoAtual += representatividadeAtual;
        rprAnoAnterior += representatividadeAnterior;
        dadosTabela.push({
          id: loja,
          loja: loja,
          qtdAnoAtual: qtdAnoAtual,
          vlrAnoAtual: vlrAnoAtual,
          vlrMedioAnoAtual: vlrMedioAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          vlrMedioAnoAnterior: vlrMedioAnoAnterior,
          desempenho: desempenho,
          representatividadeAtual: representatividadeAtual,
          representatividadeAnterior: representatividadeAnterior,
          vlrPeriodoAnterior: vlrPeriodoAnterior,
          qtdPeriodoAnterior: qtdPeriodoAnterior,
          desempPeriodoAnterior: desempPeriodoAnterior,
          vlrMedioPeriodoAnterior: vlrMedioPeriodoAnterior,
          representatividadePeriodoAnterior: representatividadePeriodoAnterior,
        });
      });
    }
    vlrMedioAnoAtualResumo = parseFloat(
      (vlrMedioAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    vlrMedioAnoAnteriorResumo = parseFloat(
      (vlrMedioAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(2)
    );

    vlrMedioPeriodoAnteriorResumo = parseFloat(
      (vlrTotalPeriodoAnteriorResumo / qtdPeriodoAnteriorResumo).toFixed(2)
    );

    desempenhoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );

    desempenhoPAResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalPeriodoAnteriorResumo - 1) * 100).toFixed(2)
    );

    dadosResumoTabela.push({
      total: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,
      desempenho: desempenhoResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
      vlrPeriodoAnterior: vlrTotalPeriodoAnteriorResumo,
      qtdPeriodoAnterior: qtdPeriodoAnteriorResumo,
      desempPeriodoAnterior: desempenhoPAResumo,
      vlrMedioPeriodoAnterior: vlrMedioPeriodoAnteriorResumo,
      representatividadePeriodoAnterior: rprPeriodoAnterior,
    });
    dadosTabela.push({
      loja: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,
      desempenho: desempenhoResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
      vlrPeriodoAnterior: vlrTotalPeriodoAnteriorResumo,
      qtdPeriodoAnterior: qtdPeriodoAnteriorResumo,
      vlrMedioPeriodoAnterior: vlrMedioPeriodoAnteriorResumo,
      desempPeriodoAnterior: desempenhoPAResumo,
      representatividadePeriodoAnterior: rprPeriodoAnterior,
    });
    setDadosParaTabela(dadosTabela);
    preencherDadosGraficoDesempenho(dadosGraficoDesempenho);
  }

  function preencherDadosTabelasGlobais(dados) {
    let grupoDeAnosArray = Object.entries(dados);
    if (grupoDeAnosArray.length > 1) {
      var separaDoisUltimosAnos = [
        grupoDeAnosArray[grupoDeAnosArray.length - 1],
        grupoDeAnosArray[grupoDeAnosArray.length - 2],
      ];
      var dadosTabelaGlobalAnoAtual = [];
      var dadosTabelaGlobalAnoAnterior = [];
      var dadosTabelaGlobalDesempenho = [];
      let janTotalAnoAtual = 0,
        fevTotalAnoAtual = 0,
        marTotalAnoAtual = 0,
        abrTotalAnoAtual = 0,
        maiTotalAnoAtual = 0,
        junTotalAnoAtual = 0,
        julTotalAnoAtual = 0,
        agoTotalAnoAtual = 0,
        setTotalAnoAtual = 0,
        outTotalAnoAtual = 0,
        novTotalAnoAtual = 0,
        dezTotalAnoAtual = 0,
        totalAnoAtual = 0;
      let janTotalAnoAnterior = 0,
        fevTotalAnoAnterior = 0,
        marTotalAnoAnterior = 0,
        abrTotalAnoAnterior = 0,
        maiTotalAnoAnterior = 0,
        junTotalAnoAnterior = 0,
        julTotalAnoAnterior = 0,
        agoTotalAnoAnterior = 0,
        setTotalAnoAnterior = 0,
        outTotalAnoAnterior = 0,
        novTotalAnoAnterior = 0,
        dezTotalAnoAnterior = 0,
        totalAnoAnterior = 0;
      let janDesempenho = 0,
        fevDesempenho = 0,
        marDesempenho = 0,
        abrDesempenho = 0,
        maiDesempenho = 0,
        junDesempenho = 0,
        julDesempenho = 0,
        agoDesempenho = 0,
        setDesempenho = 0,
        outDesempenho = 0,
        novDesempenho = 0,
        dezDesempenho = 0;
      if (separaDoisUltimosAnos && separaDoisUltimosAnos.length > 0) {
        separaDoisUltimosAnos.forEach((item, index) => {
          if (item[1].length > 0) {
            let linha = [];
            if (filtrosLojasSelecionadas.length > 0) {
              var lojasAux = item[1].filter((a) =>
                filtrosLojasSelecionadas.indexOf(converterLoja(a.CodLoja)) > -1
                  ? true
                  : false
              );
            } else {
              var lojasAux = item[1];
            }
            let grupoDeLojaPorAno = agruparPor(lojasAux, "CodLoja");
            let grupoDeLojaPorAnoArray = Object.entries(grupoDeLojaPorAno);
            var dadosDesempenho = [];
            grupoDeLojaPorAnoArray.forEach((item2) => {
              if (item2.length === 2 && item2[1].length > 0) {
                var dadosLojaPorMes = item2[1].sort((a, b) => {
                  return parseInt(a.mes) - parseInt(b.mes);
                });
                let jan = 0,
                  fev = 0,
                  mar = 0,
                  abr = 0,
                  mai = 0,
                  jun = 0,
                  jul = 0,
                  ago = 0,
                  set = 0,
                  out = 0,
                  nov = 0,
                  dez = 0,
                  total = 0;
                let codLoja;
                dadosLojaPorMes.forEach((item3) => {
                  // eslint-disable-next-line default-case
                  switch (item3.mes) {
                    case "1":
                      jan = parseFloat(item3.DescontoAplicado);
                      break;
                    case "2":
                      fev = parseFloat(item3.DescontoAplicado);
                      break;
                    case "3":
                      mar = parseFloat(item3.DescontoAplicado);
                      break;
                    case "4":
                      abr = parseFloat(item3.DescontoAplicado);
                      break;
                    case "5":
                      mai = parseFloat(item3.DescontoAplicado);
                      break;
                    case "6":
                      jun = parseFloat(item3.DescontoAplicado);
                      break;
                    case "7":
                      jul = parseFloat(item3.DescontoAplicado);
                      break;
                    case "8":
                      ago = parseFloat(item3.DescontoAplicado);
                      break;
                    case "9":
                      set = parseFloat(item3.DescontoAplicado);
                      break;
                    case "10":
                      out = parseFloat(item3.DescontoAplicado);
                      break;
                    case "11":
                      nov = parseFloat(item3.DescontoAplicado);
                      break;
                    case "12":
                      dez = parseFloat(item3.DescontoAplicado);
                      break;
                  }
                  codLoja = item3.CodLoja;
                });
                total =
                  jan +
                  fev +
                  mar +
                  abr +
                  mai +
                  jun +
                  jul +
                  ago +
                  set +
                  out +
                  nov +
                  dez;
                if (+index === 0) {
                  totalAnoAtual += total;
                  linha = {
                    loja: codLoja,
                    jan,
                    fev,
                    mar,
                    abr,
                    mai,
                    jun,
                    jul,
                    ago,
                    set,
                    out,
                    nov,
                    dez,
                    total,
                  };
                  janTotalAnoAtual += jan;
                  fevTotalAnoAtual += fev;
                  marTotalAnoAtual += mar;
                  abrTotalAnoAtual += abr;
                  maiTotalAnoAtual += mai;
                  junTotalAnoAtual += jun;
                  julTotalAnoAtual += jul;
                  agoTotalAnoAtual += ago;
                  setTotalAnoAtual += set;
                  outTotalAnoAtual += out;
                  novTotalAnoAtual += nov;
                  dezTotalAnoAtual += dez;
                  dadosDesempenho.push(linha);
                  dadosTabelaGlobalAnoAtual.push(linha);
                } else if (+index === 1) {
                  totalAnoAnterior += total;
                  linha = {
                    loja: codLoja,
                    jan,
                    fev,
                    mar,
                    abr,
                    mai,
                    jun,
                    jul,
                    ago,
                    set,
                    out,
                    nov,
                    dez,
                    total,
                  };
                  janTotalAnoAnterior += jan;
                  fevTotalAnoAnterior += fev;
                  marTotalAnoAnterior += mar;
                  abrTotalAnoAnterior += abr;
                  maiTotalAnoAnterior += mai;
                  junTotalAnoAnterior += jun;
                  julTotalAnoAnterior += jul;
                  agoTotalAnoAnterior += ago;
                  setTotalAnoAnterior += set;
                  outTotalAnoAnterior += out;
                  novTotalAnoAnterior += nov;
                  dezTotalAnoAnterior += dez;
                  dadosDesempenho.push(linha);
                  dadosTabelaGlobalAnoAnterior.push(linha);
                }
              }
            });
            dadosTabelaGlobalDesempenho.push(dadosDesempenho);
          }
        });
        var auxDadosTabelaGlobalDesempenho = [];
        dadosTabelaGlobalDesempenho[0].forEach((item) => {
          dadosTabelaGlobalDesempenho[1].forEach((item2) => {
            if (item.loja === item2.loja) {
              let janDes,
                fevDes,
                marDes,
                abrDes,
                maiDes,
                junDes,
                julDes,
                agoDes,
                setDes,
                outDes,
                novDes,
                dezDes,
                totalDes = 0;
              janDes = +item2.jan === 0 ? 0 : (item.jan / item2.jan - 1) * 100;
              fevDes = +item2.fev === 0 ? 0 : (item.fev / item2.fev - 1) * 100;
              marDes = +item2.mar === 0 ? 0 : (item.mar / item2.mar - 1) * 100;
              abrDes = +item2.abr === 0 ? 0 : (item.abr / item2.abr - 1) * 100;
              maiDes = +item2.mai === 0 ? 0 : (item.mai / item2.mai - 1) * 100;
              junDes = +item2.jun === 0 ? 0 : (item.jun / item2.jun - 1) * 100;
              julDes = +item2.jul === 0 ? 0 : (item.jul / item2.jul - 1) * 100;
              agoDes = +item2.ago === 0 ? 0 : (item.ago / item2.ago - 1) * 100;
              setDes = +item2.set === 0 ? 0 : (item.set / item2.set - 1) * 100;
              outDes = +item2.out === 0 ? 0 : (item.out / item2.out - 1) * 100;
              novDes = +item2.nov === 0 ? 0 : (item.nov / item2.nov - 1) * 100;
              dezDes = +item2.dez === 0 ? 0 : (item.dez / item2.dez - 1) * 100;
              totalDes =
                janDes +
                fevDes +
                marDes +
                abrDes +
                maiDes +
                junDes +
                julDes +
                agoDes +
                setDes +
                outDes +
                novDes +
                dezDes;
              auxDadosTabelaGlobalDesempenho.push({
                loja: item.loja,
                jan: parseFloat(janDes.toFixed(2)),
                fev: parseFloat(fevDes.toFixed(2)),
                mar: parseFloat(marDes.toFixed(2)),
                abr: parseFloat(abrDes.toFixed(2)),
                mai: parseFloat(maiDes.toFixed(2)),
                jun: parseFloat(junDes.toFixed(2)),
                jul: parseFloat(julDes.toFixed(2)),
                ago: parseFloat(agoDes.toFixed(2)),
                set: parseFloat(setDes.toFixed(2)),
                out: parseFloat(outDes.toFixed(2)),
                nov: parseFloat(novDes.toFixed(2)),
                dez: parseFloat(dezDes.toFixed(2)),
                total: parseFloat((totalDes / 12).toFixed(2)),
              });
              janDesempenho += janDes;
              fevDesempenho += fevDes;
              marDesempenho += marDes;
              abrDesempenho += abrDes;
              maiDesempenho += maiDes;
              junDesempenho += junDes;
              julDesempenho += julDes;
              agoDesempenho += agoDes;
              setDesempenho += setDes;
              outDesempenho += outDes;
              novDesempenho += novDes;
              dezDesempenho += dezDes;
            }
          });
        });
        var totalLojasReferencia =
          dadosTabelaGlobalDesempenho[0].length <
          dadosTabelaGlobalDesempenho[1].length
            ? dadosTabelaGlobalDesempenho[0].length
            : dadosTabelaGlobalDesempenho[1].length;
        var desempenhoTotal = (totalAnoAtual / totalAnoAnterior - 1) * 100;
        auxDadosTabelaGlobalDesempenho.push({
          loja: "Total",
          jan: parseFloat((janDesempenho / totalLojasReferencia).toFixed(2)),
          fev: parseFloat((fevDesempenho / totalLojasReferencia).toFixed(2)),
          mar: parseFloat((marDesempenho / totalLojasReferencia).toFixed(2)),
          abr: parseFloat((abrDesempenho / totalLojasReferencia).toFixed(2)),
          mai: parseFloat((maiDesempenho / totalLojasReferencia).toFixed(2)),
          jun: parseFloat((junDesempenho / totalLojasReferencia).toFixed(2)),
          jul: parseFloat((julDesempenho / totalLojasReferencia).toFixed(2)),
          ago: parseFloat((agoDesempenho / totalLojasReferencia).toFixed(2)),
          set: parseFloat((setDesempenho / totalLojasReferencia).toFixed(2)),
          out: parseFloat((outDesempenho / totalLojasReferencia).toFixed(2)),
          nov: parseFloat((novDesempenho / totalLojasReferencia).toFixed(2)),
          dez: parseFloat((dezDesempenho / totalLojasReferencia).toFixed(2)),
          total: parseFloat(desempenhoTotal.toFixed(2)),
        });
        dadosTabelaGlobalAnoAtual.push({
          loja: "Total",
          jan: janTotalAnoAtual,
          fev: fevTotalAnoAtual,
          mar: marTotalAnoAtual,
          abr: abrTotalAnoAtual,
          mai: maiTotalAnoAtual,
          jun: junTotalAnoAtual,
          jul: julTotalAnoAtual,
          ago: agoTotalAnoAtual,
          set: setTotalAnoAtual,
          out: outTotalAnoAtual,
          nov: novTotalAnoAtual,
          dez: dezTotalAnoAtual,
          total: totalAnoAtual,
        });
        dadosTabelaGlobalAnoAnterior.push({
          loja: "Total",
          jan: janTotalAnoAnterior,
          fev: fevTotalAnoAnterior,
          mar: marTotalAnoAnterior,
          abr: abrTotalAnoAnterior,
          mai: maiTotalAnoAnterior,
          jun: junTotalAnoAnterior,
          jul: julTotalAnoAnterior,
          ago: agoTotalAnoAnterior,
          set: setTotalAnoAnterior,
          out: outTotalAnoAnterior,
          nov: novTotalAnoAnterior,
          dez: dezTotalAnoAnterior,
          total: totalAnoAnterior,
        });

        setDadosParaTabelaGlobalAnoAtual(dadosTabelaGlobalAnoAtual);
        setDadosParaTabelaGlobalAnoAnterior(dadosTabelaGlobalAnoAnterior);
        setExibeTabelaGlobal(true);
      } else {
        setExibeTabelaGlobal(false);
      }
    } else {
      setExibeTabelaGlobal(false);
    }
  }

  function preencherDadosTabelaMes(dados) {
    var dadosTabela = [];
    var dadosResumoTabela = [];

    var quantidadeLojasAnoAtual = 0,
      quantidadeLojasAnoAnterior = 0;
    let qtdAnoAtualResumo = 0,
      vlrAnoAtualResumo = 0,
      vlrMedioAnoAtualResumo = 0,
      qtdAnoAnteriorResumo = 0,
      vlrTotalAnoAnteriorResumo = 0,
      vlrMedioAnoAnteriorResumo = 0,
      desempenhoResumo = 0,
      rprAnoAtual = 0,
      rprAnoAnterior = 0;

    var valorTotalAnoAtual = 0;
    var totalPedidosAnoAnterior = 0;

    if (dados.length > 0) {
      dados.forEach((item) => {
        item[1].forEach((item2, index2) => {
          if (index2 === item[1].length - 1) {
            valorTotalAnoAtual += parseInt(item2.DescontoAplicado);
          } else {
            totalPedidosAnoAnterior += parseInt(item2.DescontoAplicado);
          }
        });
      });
      dados.forEach((item) => {
        let mes = parseInt(item[0]);
        let qtdAnoAtual = 0,
          vlrAnoAtual = 0,
          vlrMedioAnoAtual = 0,
          qtdAnoAnterior = 0,
          vlrTotalAnoAnterior = 0,
          vlrMedioAnoAnterior = 0,
          desempenho = 0,
          representatividadeAtual = 0,
          representatividadeAnterior = 0;
        item[1].forEach((item2, index2) => {
          if (index2 === item[1].length - 1) {
            qtdAnoAtual = parseInt(item2.totalPedidos);
            vlrAnoAtual = parseFloat(
              parseFloat(item2.DescontoAplicado).toFixed(2)
            );
            vlrMedioAnoAtual = parseFloat(
              (vlrAnoAtual / qtdAnoAtual).toFixed(2)
            );
            qtdAnoAtualResumo += qtdAnoAtual;
            vlrAnoAtualResumo += vlrAnoAtual;
            vlrMedioAnoAtualResumo += vlrMedioAnoAtual;
            if (qtdAnoAtual > 0) {
              quantidadeLojasAnoAtual++;
            }
          } else {
            qtdAnoAnterior = parseInt(item2.totalPedidos);
            vlrTotalAnoAnterior = parseFloat(
              parseFloat(item2.DescontoAplicado).toFixed(2)
            );
            vlrMedioAnoAnterior = parseFloat(
              (vlrTotalAnoAnterior / qtdAnoAnterior).toFixed(2)
            );
            qtdAnoAnteriorResumo += qtdAnoAnterior;
            vlrTotalAnoAnteriorResumo += vlrTotalAnoAnterior;
            vlrMedioAnoAnteriorResumo += vlrMedioAnoAnterior;
            if (qtdAnoAnterior > 0) {
              quantidadeLojasAnoAnterior++;
            }
          }
        });
        if (qtdAnoAnterior > 0) {
          desempenho = parseFloat(
            ((vlrAnoAtual / vlrTotalAnoAnterior - 1) * 100).toFixed(2)
          );
        } else {
          desempenho = 0;
        }
        desempenhoResumo += desempenho;
        representatividadeAtual = parseFloat(
          ((vlrAnoAtual / valorTotalAnoAtual) * 100).toFixed(2)
        );
        representatividadeAnterior = parseFloat(
          ((vlrTotalAnoAnterior / totalPedidosAnoAnterior) * 100).toFixed(2)
        );
        rprAnoAtual += representatividadeAtual;
        rprAnoAnterior += representatividadeAnterior;
        dadosTabela.push({
          mes: mes,
          qtdAnoAtual: qtdAnoAtual,
          vlrAnoAtual: vlrAnoAtual,
          vlrMedioAnoAtual: vlrMedioAnoAtual,
          qtdAnoAnterior: qtdAnoAnterior,
          vlrTotalAnoAnterior: vlrTotalAnoAnterior,
          vlrMedioAnoAnterior: vlrMedioAnoAnterior,
          desempenho: desempenho,
          representatividadeAtual: representatividadeAtual,
          representatividadeAnterior: representatividadeAnterior,
        });
      });
    }

    vlrMedioAnoAtualResumo = parseFloat(
      (vlrMedioAnoAtualResumo / quantidadeLojasAnoAtual).toFixed(2)
    );
    vlrMedioAnoAnteriorResumo = parseFloat(
      (vlrMedioAnoAnteriorResumo / quantidadeLojasAnoAnterior).toFixed(2)
    );

    desempenhoResumo = parseFloat(
      ((vlrAnoAtualResumo / vlrTotalAnoAnteriorResumo - 1) * 100).toFixed(2)
    );
    dadosResumoTabela.push({
      total: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,
      desempenho: desempenhoResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
    });
    dadosTabela.push({
      mes: "Total",
      qtdAnoAtual: qtdAnoAtualResumo,
      vlrAnoAtual: vlrAnoAtualResumo,
      vlrMedioAnoAtual: vlrMedioAnoAtualResumo,
      qtdAnoAnterior: qtdAnoAnteriorResumo,
      vlrTotalAnoAnterior: vlrTotalAnoAnteriorResumo,
      vlrMedioAnoAnterior: vlrMedioAnoAnteriorResumo,
      desempenho: desempenhoResumo,
      representatividadeAtual: rprAnoAtual,
      representatividadeAnterior: rprAnoAnterior,
    });

    setDadosParaTabela(dadosTabela);
  }

  async function preencherDadosGraficoAgrupadoMes(dados) {
    var label = [];
    var labelCompleta = [];
    var dataSets = [];
    var dadosTabelaAux = [];

    const promise = await dados.forEach((item, i) => {
      var dadosGrupo = [];
      item[1] = item[1].sort((a, b) => {
        return a.mes - b.mes;
      });

      item[1].forEach((itemGrupo) => {
        var nomeMes = converterMes(itemGrupo.mes);
        if (filtrosMesesSelecionados.length > 0) {
          if (filtrosMesesSelecionados.indexOf(nomeMes) >= 0) {
            let jaExiste = label.indexOf(nomeMes);
            if (jaExiste < 0) {
              label.push(nomeMes);
              labelCompleta.push(nomeMes);
            }
            dadosGrupo.push(parseFloat(itemGrupo.DescontoAplicado));
            dadosTabelaAux.push(itemGrupo);
          } else {
            let jaExiste = labelCompleta.indexOf(nomeMes);
            if (jaExiste < 0) {
              labelCompleta.push(nomeMes);
            }
          }
        } else {
          let jaExiste = label.indexOf(nomeMes);
          if (jaExiste < 0) {
            label.push(nomeMes);
            labelCompleta.push(nomeMes);
          }
          dadosGrupo.push(parseFloat(itemGrupo.DescontoAplicado));
          dadosTabelaAux.push(itemGrupo);
        }
      });
      var cor =
        +i === dados.length - 1
          ? "rgba(255, 194, 218, 1)"
          : +i === dados.length - 2
          ? "rgba(91, 139, 252, 1)"
          : +i === dados.length - 3
          ? "#5B8BFC"
          : +i === dados.length - 4
          ? "#FFC2DA"
          : +i === dados.length - 5
          ? ""
          : geraCorAleatoria();
      var datalabels;
      if (+i === dados.length - 1) {
        datalabels = {
          anchor: "end",
          display: true,
          color: "#000",
          align: "end",
          rotation: 0,
          formatter: function (value) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      } else if (+i === dados.length - 2) {
        datalabels = {
          anchor: "start",
          display: true,
          color: cor,
          align: "start",
          rotation: 0,
          formatter: function (value) {
            return parseFloat(value).toLocaleString("pt-BR", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            });
          },
        };
      }
      dataSets.push({
        label: item[0],
        data: dadosGrupo,
        backgroundColor: cor,
        datalabels: datalabels,
      });
    });

    await Promise.all(promise);
    const data = {
      labels: label,
      datasets: dataSets,
    };
    dadosTabelaAux = agruparPor(dadosTabelaAux, "mes");
    dadosTabelaAux = Object.entries(dadosTabelaAux);
    preencherDadosTabelaMes(dadosTabelaAux);
    setDadosGraficoAgrupado(data);
    setMesesDisponiveis(labelCompleta);
    setNivelAtivoDoGraficoTotalVendas(1);
  }

  async function preencherDadosGraficoDesempenho(dados) {
    var label = [];
    var dadosDesempenho = [];
    var backgroundColor = [];

    const promise = await dados.forEach((item, i) => {
      var loja = converterLoja(item.loja.toString());
      label.push(loja);
      dadosDesempenho.push(item.desempenho);
      backgroundColor.push("rgba(255, 194, 218, 1)");
    });

    await Promise.all(promise);
  }

  function preencherDadosVendasPorMes(codLoja) {
    setCodLojaAtiva(codLoja);

    var dados = dadosGerais;
    dados = dados.filter((a) => {
      return a.CodLoja === codLoja ? true : false;
    });
    let grupoDeAnos = agruparPor(dados, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);

    preencherDadosGraficoAgrupadoMes(grupoDeAnosArray);
  }

  function preencherDadosVendasPorLoja() {
    var dados = dadosGerais;
    let dadosUltimoPeriodoTemp = [...dadosUltimoPeriodo];

    let grupoDeAnos = agruparPor(dados, "ano");
    let grupoDeAnosArray = Object.entries(grupoDeAnos);
    let grupoDeAnoLoja = [];
    grupoDeAnosArray.forEach((item) => {
      let grupoDeLoja = agruparPor(item[1], "CodLoja");
      grupoDeAnoLoja.push(grupoDeLoja);
    });
    let resumoGrupoDeAnoLoja = [];
    grupoDeAnoLoja.forEach((item) => {
      let item2 = Object.entries(item);
      item2.forEach((item3) => {
        let total = 0;
        let totalPedidos = 0;
        let ano;
        let codLoja;
        item3[1].forEach((item4, index) => {
          if (index === 0) {
            ano = item4.ano;
            codLoja = item4.CodLoja;
          }
          totalPedidos += parseInt(item4.totalPedidos);
          total += parseFloat(item4.DescontoAplicado);
        });
        let resumoLojaAno = {
          ano: ano,
          codLoja: codLoja,
          total: total,
          totalPedidos: totalPedidos,
        };
        resumoGrupoDeAnoLoja.push(resumoLojaAno);
      });
    });
    var agrupado = agruparPor(resumoGrupoDeAnoLoja, "ano");
    grupoDeAnosArray = Object.entries(agrupado);
    preencherDadosGraficoAgrupado(
      grupoDeAnosArray,
      auxAnoAnterior,
      auxAnoAtual,
      dadosUltimoPeriodoTemp
    );
    preencherDadosTabelasGlobais(dadosTabelasGlobais);
  }

  function iniciarFiltroPorPeriodo() {
    setPeriodoAno(false);
    setFiltrosAnosSelecionados([]);
    setPeriodoInputInicio(periodoInputInicioAux);
    setPeriodoInputFim(periodoInputFimAux);
  }

  function filtrarPorMes(mes) {
    setTipoPeriodo("mes");
    var mesesSelecionados = [];
    if (filtrosMesesSelecionados.length === 0) {
      mesesSelecionados.push(mes);
    } else {
      if (filtrosMesesSelecionados.indexOf(mes) >= 0) {
        filtrosMesesSelecionados.forEach((item) => {
          if (item !== mes) {
            mesesSelecionados.push(item);
          }
        });
      } else {
        filtrosMesesSelecionados.forEach((item) => {
          mesesSelecionados.push(item);
        });
        mesesSelecionados.push(mes);
      }
    }
    setFiltrosMesesSelecionados(mesesSelecionados);
    var mesesDisponiveisAux = [];
    mesesDisponiveis.forEach((item) => {
      mesesDisponiveisAux.push(item);
    });
    setMesesDisponiveis(mesesDisponiveisAux);
  }

  function filtrarPorAno(ano) {
    let filtrosLojasSelecionadasAuc = filtrosLojasSelecionadas;
    setTipoPeriodo("ano");
    let anosSelecionados = [];
    if (filtrosAnosSelecionados.length === 0) {
      anosSelecionados.push(ano);
    } else {
      if (filtrosAnosSelecionados.indexOf(ano) >= 0) {
        filtrosAnosSelecionados.forEach((item) => {
          if (item !== ano) {
            anosSelecionados.push(item);
          }
        });
      } else {
        filtrosAnosSelecionados.forEach((item) => {
          anosSelecionados.push(item);
        });
        anosSelecionados.push(ano);
      }
    }
    if (anosSelecionados.length === 0) {
      setPeriodoAno(false);
      preencherInputPeriodo();
      setFiltrosAnosSelecionados([]);
    } else {
      setFiltrosAnosSelecionados(anosSelecionados);
      setFiltrosLojasSelecionadas(filtrosLojasSelecionadasAuc);
    }
  }

  const iniciarFiltroPorLoja = (loja) => {
    const lojasSelecionadas = loja.map((item) => item.value);

    setFiltradoPorLoja(true);
    setFiltrosLojasSelecionadas(lojasSelecionadas);
  };

  function filtrarPorLoja(loja) {
    LoadingTemp();
    setTipoPeriodo("loja");
    let anos = anosDisponiveisAuxiliar;
    let lojasSelecionadas = [];
    if (filtrosLojasSelecionadas.length === 0) {
      lojasSelecionadas.push(loja);
    } else {
      if (filtrosLojasSelecionadas.indexOf(loja) >= 0) {
        filtrosLojasSelecionadas.forEach((item) => {
          if (item !== loja) {
            lojasSelecionadas.push(item);
          }
        });
      } else {
        filtrosLojasSelecionadas.forEach((item) => {
          lojasSelecionadas.push(item);
        });
        lojasSelecionadas.push(loja);
      }
    }
    setFiltradoPorLoja(true);
    setFiltrosLojasSelecionadas(lojasSelecionadas);
    let lojasDisponiveisAux = [];
    lojasDisponiveis.forEach((item) => {
      lojasDisponiveisAux.push(item);
    });

    if (lojasSelecionadas.length === 0) {
      preencherInputPeriodo();
      setAnosDisponiveis(anos);
      setMesesDisponiveis(false);
    } else {
      setAnosDisponiveis(fAnosPorLoja(lojasSelecionadas));
      setLojasDisponiveis(lojasDisponiveisAux);
    }
  }
  const optionsChartBarVendas = {
    title: {
      display: true,
      padding: 20,
      text: "Valor Desconto de Vendas",
    },
    legend: {
      position: "bottom",
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            padding: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        labels: {
          title: {
            font: {
              weight: "bold",
            },
          },
        },
      },
    },
  };

  const getElementAtEvent = (element) => {
    if (nivelAtivoDoGraficoTotalVendas > 0) return;
    if (!element.length) return;
    LoadingTemp();
    const { _index: index, _xScale: eixoX } = element[0];
    var nomeLoja = eixoX.ticks[index];
    let codLoja = converterNomeLoja(nomeLoja);
    preencherDadosVendasPorMes(codLoja);
    setTipoPeriodo("mes");
  };
  const filtrarBases = (codBase) => {
    setAnosDisponiveisAuxiliar(false);
    setDadosDisponiveis(false);
    setFiltrosAnosSelecionados([]);
    setMesesDisponiveis(false);

    setBase(codBase);
    setBasesSelecionadas([codBase]);
  };
  function iniciarFiltroHoje() {
    var dataHoje = new Date();
    var [, hoje] = converterDataFormatoPadrao(dataHoje);

    setPeriodoInputInicio(hoje);
    setPeriodoInputFim(hoje);

    setPeriodoInputInicioAux(hoje);
    setPeriodoInputFimAux(hoje);
  }
  return (
    <>
      {PermissionsPage("descontGlobal") && (
        <>
          <>
            <Box ml={2}>
              <Title title="Desconto Global" />
            </Box>

            <Stack gap={2} direction={"row"} mx={2}>
              <Box component={Paper} variant="outlined" px={1} pb={1}>
                <FiltroPeriodo
                  periodoInputInicioAux={periodoInputInicioAux}
                  periodoInputFimAux={periodoInputFimAux}
                  setPeriodoInputInicioAux={setPeriodoInputInicioAux}
                  setPeriodoInputFimAux={setPeriodoInputFimAux}
                />
                <Stack gap={1}>
                  <ButtonFilter
                    type="button"
                    onClick={() => iniciarFiltroPorPeriodo()}
                    disabled={isLoading}
                  >
                    Filtrar
                  </ButtonFilter>
                  <ButtonToday
                    type="button"
                    onClick={() => iniciarFiltroHoje()}
                    disabled={isLoading}
                  >
                    Hoje
                  </ButtonToday>
                </Stack>
              </Box>

              <Box width={"100%"}>
                <Box pb={1}>
                  <MultiSelectFilterBases
                    basesDisponiveis={basesDisponiveis}
                    basesSelecionadas={basesSelecionadas}
                    onChange={filtrarBases}
                    disabled={isLoading}
                  />
                  <Typography variant="caption" component={"span"}>
                    *Atenção: Quando nenhuma base estiver selecionada, todas as
                    bases serão retornadas.*
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    textAlign={"initial"}
                    fontWeight={"bold"}
                    component={"span"}
                  >
                    Lojas
                  </Typography>
                  <Select
                    options={lojasDisponiveis.map((item) => ({
                      label: converterLoja(item),
                      value: item,
                    }))}
                    isMulti
                    onChange={iniciarFiltroPorLoja}
                    placeholder="Selecione uma loja"
                    styles={colourStyles}
                    isDisabled={isLoading}
                  />
                  <Typography variant="caption" component={"span"}>
                    *Atenção: Quando nenhuma loja estiver selecionada, todas as
                    lojas serão retornadas.*
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </>

          {isLoading ? (
            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"90vh"}
            >
              <LoadingTable isLoading={isLoading} />
            </Box>
          ) : (
            <>
              <>
                {dadosDisponiveis && (
                  <>
                    <Box
                      m={2}
                      p={1}
                      component={Paper}
                      variant="outlined"
                      style={{ overflow: "auto" }}
                    >
                      <Bar
                        data={dadosGraficoAgrupado}
                        options={optionsChartBarVendas}
                        getElementAtEvent={getElementAtEvent}
                      />
                    </Box>
                  </>
                )}
              </>
              {dadosDisponiveis && exibeTabela ? (
                <>
                  <TituloTabela>VALOR DESCONTO DE VENDAS</TituloTabela>
                  <Box mx={2} variant="outlined" m={2}>
                    <TabelaNext
                      dadosParaTabela={dadosParaTabela}
                      colunas={colunasDescontoGlobal(
                        tipoPeriodo,
                        anosDisponiveis,
                        filtrosAnosSelecionados
                      )}
                    />
                    {/* <LegendaTabela /> */}
                  </Box>
                </>
              ) : (
                <></>
              )}
              {dadosDisponiveis && exibeTabelaGlobal ? (
                <>
                  <>
                    <TituloTabela>DESCONTO GLOBAL ANO ATUAL</TituloTabela>
                    <Box mx={2} variant="outlined" m={2}>
                      <TabelaNext
                        dadosParaTabela={dadosParaTabelaGlobalAnoAtual}
                        colunas={colunasTabelasGlobaisNext()}
                      />
                    </Box>
                    <TituloTabela>DESCONTO GLOBAL ANO ANTERIOR</TituloTabela>
                    <Box mx={2} variant="outlined" m={2}>
                      <TabelaNext
                        dadosParaTabela={dadosParaTabelaGlobalAnoAnterior}
                        colunas={colunasTabelasGlobaisNext()}
                      />
                    </Box>
                  </>
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default DescontoGlobal;
