import React, { useEffect, useRef, useState } from "react";

import { Container } from "react-bootstrap";
import "chartjs-plugin-datalabels";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css";
import Title from "../../components/title";
import { debounce as throttleDebounce } from "throttle-debounce";

import { arrayBases } from "../../util/loja";
import toast from "react-hot-toast";
import SearchIcon from "@mui/icons-material/Search";

import { PermissionsPage } from "../../util/permissionsPage";
import { useUserData } from "../../hooks/useUserData";
import { Box, IconButton, Paper, Stack, Typography } from "@mui/material";
import { LoadingTable } from "../../components/Loading/LoadingTable";
import { SelectBases } from "../../components/SelectBases/SelectBases";
import { api } from "../../services/api.v2";
import DataTable from "react-data-table-component";

import { colunasEstoqueContasPagar } from "../../column/estoque-contas-pagar/estoque-contas-pagar";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../components/SearchComponent/SearchComponent";
import ErrorToastHandler from "../../util/ErrorToastHandler";
import { SkeletonEstoqueContasPagarResumo } from "../../components/componentsContasPaga/SkeletonComponets/SkeletonEstoqueContasPagarResumo";
import { ResumeContasPagar } from "../../components/componentsContasPaga/ResumeContasPagar/ResumeContasPagar";
import { ButtonFilter } from "../../components/DatePikerFilter/styles";
import { FiMoreVertical } from "react-icons/fi";
import { ListMenu } from "../../components/componentsContasPaga/ListMenu/ListMenu";

function EstoqueContasPagar() {
  const [nivelAcesso, setNivelAcesso] = useState([]);
  const { userData } = useUserData();
  const accessLevel = userData?.nivelAcesso;
  const acessBase = userData?.baseAcesso;
  const [estoque, setEstoque] = useState({
    items: [],
    summary: {},
  });
  const inputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isLoading, setIsLoading] = useState(true);

  const [basesDisponiveis, setBasesDisponiveis] = useState([]);
  const [base, setBase] = useState();
  const defaultParamsConfig = {
    base: "",
    stores: nivelAcesso,
    searchTerm: "",
  };
  const [paramsConfig, setParamsConfig] = useState(defaultParamsConfig);

  useEffect(() => {
    if (nivelAcesso.length === 0) {
      buscarNivelAcesso();
    }
  }, [userData]);

  useEffect(() => {
    if (nivelAcesso.length > 0) {
      preencherDadosEstoquePorGrupo();
    }
  }, [paramsConfig]);

  const handleSearch = (ev) => {
    setParamsConfig((old) => ({
      ...old,
      searchTerm: ev,
    }));
  };

  const debounceFunc = throttleDebounce(500, (value) => {
    handleSearch(value);
  });
  async function preencherDadosEstoquePorGrupo() {
    setIsLoading(true);

    //const toastId = toast.loading(`Buscando dados da base ${base}`);
    const toastId = toast.loading(`Buscando dados...`);
    try {
      const response = await api.get("/inventory/supplier-accounts-payable", {
        params: {
          codBase: paramsConfig.base,
          stores: paramsConfig.stores,
          searchTerm: paramsConfig.searchTerm,
        },
      });
      setEstoque(response.data);

      toast.success(`Dados da base ${base} recebidos`, {
        id: toastId,
      });
    } catch (error) {
      const errorToastHandler = new ErrorToastHandler(error, toastId);
      errorToastHandler.showErrorToast();
    } finally {
      setIsLoading(false);
    }
  }

  const buscarNivelAcesso = async () => {
    if (!!accessLevel) {
      setNivelAcesso(accessLevel);
      setBasesDisponiveis(arrayBases(acessBase));
      setParamsConfig({
        base: acessBase[0],
        stores: accessLevel,
        searchTerm: "",
      });
      if (acessBase.length > 0) {
        setBase(acessBase[0]);
      }
    } else {
      setIsLoading(false);
    }
  };
  const onChangeParamsConfig = (value, name) => {
    setParamsConfig((old) => ({ ...old, [name]: value }));
  };
  const handleCleanFilter = () => {
    inputRef.current.value = "";
    setParamsConfig((old) => ({
      ...defaultParamsConfig,
      base: old.base,
    }));
  };
  const columns = colunasEstoqueContasPagar;

  return (
    <>
      {PermissionsPage("estoqueContasPagar") ? (
        <>
          <Box ml={2}>
            <Title title="ESTOQUE POR FORNECEDOR E CONTAS A PAGAR" />
          </Box>

          <Stack
            gap={2}
            width={"100%"}
            justifyContent={"space-between"}
            px={2}
            pb={2}
          >
            <Box>
              <SelectBases
                basesDisponiveis={basesDisponiveis}
                onChange={onChangeParamsConfig}
                paramsConfig={paramsConfig}
                isLoading={isLoading}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={["column", "row"]}
              justifyContent={"space-between"}
            >
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  sx={{
                    borderRadius: "4px",
                    borderColor: "hsl(0,0%,76%) !important",
                    border: "1px solid",
                    marginLeft: "0px",
                    mb: [1, 0],
                  }}
                  disabled={isLoading}
                  placeholder="Pesquisar  "
                  inputProps={{
                    ref: inputRef,
                    "aria-label": "search",
                  }}
                  onChange={(e) => {
                    debounceFunc(e.target.value);
                  }}
                />
              </Search>
              <ButtonFilter
                style={{
                  width: "fit-content",
                  fontSize: ".8rem",
                  fontWeight: "500",
                }}
                onClick={handleCleanFilter}
                disabled={isLoading}
              >
                LIMPAR FILTROS
              </ButtonFilter>
            </Box>
          </Stack>

          <Stack>
            <Box width={"100%"} variant="outlined">
              <Typography mx={2} variant="h6">
                Resumo
              </Typography>

              {isLoading ? (
                <SkeletonEstoqueContasPagarResumo />
              ) : (
                <ResumeContasPagar estoque={estoque} />
              )}
            </Box>
          </Stack>
          <>
            {isLoading ? (
              <Box width={"100%"}>
                <LoadingTable isLoading={isLoading} />
              </Box>
            ) : (
              <>
                <Stack px={2} justifyContent={"flex-end"} direction={"row"}>
                  <IconButton
                    aria-controls={openMenu ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? "true" : undefined}
                    onClick={handleClick}
                  >
                    <FiMoreVertical />
                  </IconButton>
                </Stack>
                <Box
                  component={Paper}
                  variant="outlined"
                  mx={2}
                  my={2}
                  elevation={1}
                >
                  <DataTable
                    data={estoque.items}
                    pagination
                    columns={columns}
                  />
                </Box>
                <ListMenu
                  onClose={handleClose}
                  open={openMenu}
                  anchorEl={anchorEl}
                  paramsConfig={paramsConfig}
                />
              </>
            )}
          </>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default EstoqueContasPagar;
